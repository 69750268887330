import { Typography } from "@mui/material";

export default function SurveyTitleHeader({ title }) {
    return (
        <Typography
            sx={{
                marginBottom: '35px',
                borderBottom: '1px solid #CCCCCC',
                width: '100%',
                paddingBottom: '1%',
                fontWeight: 'bold',
            }}
        >
            {title}
        </Typography>
    );
}