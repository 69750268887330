import React from 'react';
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Stack } from '@mui/material';
import { Info } from "@mui/icons-material";
import dayjs from 'dayjs';
import styled from 'styled-components';
import { SurveySessionStatusEnum } from '../../utility/enums';

const StyledTableCell = styled(TableCell)`
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
  text-align: center;
`;

const SurveyTemplateResults = ({ surveySessionData }) => {
	return (
		<TableContainer>
			<Divider />
			<Table
				size='small'
				aria-label="customized table">
				<TableHead>
					<TableRow >
						<StyledTableCell>
							<Stack justifyContent="center" direction="row" gap={1}>
								Session Status
								<Tooltip
									title={
										<Typography variant="caption" lineHeight="1.25em">
											<p><strong>Upcoming- </strong>The survey session has not started. </p>
											<p><strong>Active-  </strong>The survey session has started and surveys have been sent.  </p>
											<p><strong>Success-  </strong>100% of surveys in the session have been completed. </p>
											<p><strong>Failure-  </strong> Less than 100% of surveys in the session have been completed. </p>
										</Typography>
									}
								>
									<Info sx={{ length: '20px', width: '20px' }} />
								</Tooltip>
							</Stack>
						</StyledTableCell>
						<StyledTableCell>Send Date</StyledTableCell>
						<StyledTableCell>Sent</StyledTableCell>
						<StyledTableCell>Responses</StyledTableCell>
						<StyledTableCell>Engagement</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{surveySessionData.map(surveySession => {
						const isUpcoming = surveySession.sessionStatusId === SurveySessionStatusEnum.UPCOMING;
						return (
							<TableRow key={`sentSurvey-${surveySession.sentDate}`}>
								<StyledTableCell>{surveySession.sessionStatus}</StyledTableCell>
								<StyledTableCell>
									{dayjs(surveySession.sentDate).format('MM/DD/YYYY')}
								</StyledTableCell>
								<StyledTableCell>{isUpcoming ? null : surveySession.sentCount}</StyledTableCell>
								<StyledTableCell>{isUpcoming ? null : surveySession.completedCount}</StyledTableCell>
								<StyledTableCell>{isUpcoming ? null : `${surveySession.engagementPercentage}%`}</StyledTableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default SurveyTemplateResults;