import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDeleteCampaignMutation, useGetAllCampaignsQuery } from './campaignManagementSlice';
import { useGetSchoolsQuery } from "../../app/slices/schoolsSlice";
import { CampaignManagementDataGrid } from './CampaignManagementDataGrid';
import CampaignManagementFilters from './CampaignManagementFilters';
import ConfirmationModal from '../Common/ConfirmationModal';
import ContentWrapper from '../Common/ContentWrapper';
import { RoleEnum } from '../../utility/enums';
import { useGetSessionQuery } from '../../app/slices/sessionSlice';

const writeRolePermissions = [RoleEnum.DISTRICT_ADMIN, RoleEnum.INTEGRATOR];

const defaultFilters = {
    schoolsFilter: [],
    datePicker: {
        start: null,
        end: null,
    },
    hideCompleted: true,
    roles: ''
};

export default function CampaignManagementContainer() {
    const [deletingCampaign, setDeletingCampaign] = useState(null);
    const [filters, setFilters] = useState({ ...defaultFilters });
    const [searchParams] = useSearchParams();

    const [deleteCampaign] = useDeleteCampaignMutation();

    const { data: session, isLoading: isSessionLoading } = useGetSessionQuery();
    const { data: campaigns = [], isLoading } = useGetAllCampaignsQuery();

    const hasWriteAccess = session?.roles.some(r => writeRolePermissions.includes(r.role.id));

    const inboundCampaignId = useMemo(() => searchParams.get('campaignId'), [searchParams]);

    const filteredCampaigns = useMemo(() => {
        const dateInRange = (date, start, end) =>
            date.getTime() >= start.getTime() && date.getTime() <= end.getTime();

        const campaignDateMatches = (startDate, endDate, datePicker) => {
            if (!datePicker?.start && !datePicker?.end) return true;
            if (datePicker?.start && !datePicker?.end) return startDate.getTime() >= datePicker.start.getTime();
            if (!datePicker?.start && datePicker?.end) return endDate.getTime() <= datePicker.end.getTime();
            if (datePicker?.start && datePicker?.end) return dateInRange(startDate, datePicker.start, datePicker.end) && dateInRange(endDate, datePicker.start, datePicker.end);
        };

        return campaigns?.filter(c => {
            const startDate = new Date(c.startDate);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(c.endDate);
            endDate.setHours(0, 0, 0, 0);
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (filters.hideCompleted && endDate < currentDate) return false;
            if (filters.schoolsFilter?.length > 0 && !filters.schoolsFilter.some(x => c.schools.some(y => y.id === x.id))) return false;

            return campaignDateMatches(startDate, endDate, filters.datePicker);
        });
    }, [campaigns, filters]);

    const { data: schools = [] } = useGetSchoolsQuery();
    const mappedSchools = useMemo(() => schools?.map(x => ({ id: x.id, label: x.name })) ?? [], [schools]);

    function onDeleteCampaign(id) {
        deleteCampaign(id)
            .then((response) => {
                const isDeleting = response?.data?.isDeleting;

                if (isDeleting === null)
                    toast.error(`Failed to delete campaign`);

                setDeletingCampaign(null);
                toast.success('Successfully deleted campaign');
            })
            .catch((error) => {
                toast.error(error);
                setDeletingCampaign(null);
            });
    };

    return (
        <>
            <ContentWrapper title="Campaign Management">
                <CampaignManagementFilters
                    filters={filters}
                    setFilters={setFilters}
                    schools={mappedSchools}
                    filteredCampaigns={filteredCampaigns}
                />
                <CampaignManagementDataGrid
                    campaigns={filteredCampaigns}
                    inboundCampaignId={inboundCampaignId}
                    isLoading={isLoading}
                    isSessionLoading={isSessionLoading}
                    setDelete={setDeletingCampaign}
                    hasWriteAccess={hasWriteAccess}
                />
            </ContentWrapper>
            <ConfirmationModal
                isOpen={!!deletingCampaign}
                close={() => setDeletingCampaign(null)}
                onConfirm={() => onDeleteCampaign(deletingCampaign.id)}
                title="Delete Campaign?"
                message="Please confirm you'd like to delete the selected campaign. This is not reversible once deleted."
                saveBtnText="Delete"
            />
        </>
    )
};