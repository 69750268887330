import React from 'react';
import { Dialog, DialogContent, Stack, Typography, DialogTitle } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import DialogActionButtons from './Buttons/DialogActionButtons';

function ConfirmationModal({ isOpen, close, onConfirm, title, message, saveBtnText = 'Confirm', cancelBtnText = 'Cancel', hideIcon = false }) {
    return (
        <Dialog
            open={isOpen}
            onClose={close}
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle sx={{ mt: 2, pb: 1 }}>
                <Stack sx={{ gap: '10px', alignItems: 'center' }}>
                    {!hideIcon &&
                        <ErrorOutline
                            fontSize='large'
                            color='error'
                        />
                    }
                    <Typography variant="h6" align='center'>
                        {title}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ pb: 1 }}>
                <Typography variant="body1" align='center'>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActionButtons
                fullWidth
                closeText={cancelBtnText}
                submitText={saveBtnText}
                onSubmit={onConfirm}
                onClose={close}
            />
        </Dialog>
    )
}

export default ConfirmationModal;