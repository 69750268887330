import { useMemo, useState, useEffect } from 'react';
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Checkbox, Skeleton, Typography, InputAdornment, TextField } from '@mui/material';
import { Error, ExpandMore, Info, Search } from '@mui/icons-material';
import styled from 'styled-components';

const AccordionBorderBox = styled(Box)({
    maxHeight: '400px',
    overflowY: 'auto',
    borderLeft: '1px solid #9F9F9F',
    borderRight: '1px solid #9F9F9F',
    borderTop: '1px solid #9F9F9F',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottom: '1px solid #9F9F9F',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
});

const DisabledPillarStyle = {
    backgroundColor: '#f0f0f0',
    color: '#a0a0a0',
};

export default function PillarsWithBenefitsAccordion({ pillarBenefits, isLoading, selectedPillars, setSelectedPillars, selectedBenefits, setSelectedBenefits, showSelectCampaignMessage, limitSelectionToSinglePillar }) {
    const [pillarBenefitSearch, setPillarBenefitSearch] = useState('');
    const [expandedSections, setExpandedSections] = useState([]);

    useEffect(() => {
        const initialExpandedSections =
            pillarBenefits?.filter(pillar =>
                selectedPillars.includes(pillar.id) ||
                pillar.benefits.some(benefit => selectedBenefits.includes(benefit.id))
            ).map(pillar => pillar.id) || [];

        setExpandedSections(initialExpandedSections);
    }, [pillarBenefits, selectedPillars, selectedBenefits]);

    const filteredPillarBenefits = useMemo(() => {
        return pillarBenefits
            ? pillarBenefits.filter(pillar => {
                return (
                    pillar.name.toLowerCase().includes(pillarBenefitSearch.toLowerCase()) ||
                    pillar.benefits.some(benefit =>
                        benefit.name.toLowerCase().includes(pillarBenefitSearch.toLowerCase())
                    )
                );
            })
            : [];
    }, [pillarBenefits, pillarBenefitSearch]);

    const handleSelectPillar = pillarId => {
        const isSelected = selectedPillars.includes(pillarId);
        let updatedSelectedPillars = [...selectedPillars];
        let updatedSelectedBenefits = [...selectedBenefits];

        const pillar = pillarBenefits.find(pillar => pillar.id === pillarId);

        if (pillar) {
            if (isSelected) {
                // Deselect the pillar and its benefits
                updatedSelectedPillars = updatedSelectedPillars.filter(id => id !== pillarId);
                updatedSelectedBenefits = updatedSelectedBenefits.filter(
                    (benefitId) => !pillar.benefits.some(benefit => benefit.id === benefitId)
                );
            } else {
                if (limitSelectionToSinglePillar) {
                    // Limit selection to a single pillar
                    updatedSelectedPillars = [pillarId];
                    updatedSelectedBenefits = pillar.benefits.map(benefit => benefit.id);
                } else {
                    // Allow multiple pillars to be selected
                    updatedSelectedPillars.push(pillarId);
                    updatedSelectedBenefits.push(
                        ...pillar.benefits.map(benefit => benefit.id)
                    );
                }
            }
            setSelectedPillars(updatedSelectedPillars);
            setSelectedBenefits(updatedSelectedBenefits);
        }
    };

    const isPillarSelected = pillarId => selectedPillars.includes(pillarId);

    const isPillarIndeterminate = pillarId => {
        const pillar = pillarBenefits.find(p => p.id === pillarId);
        if (!pillar) return false;

        const benefitIds = pillar.benefits.map(b => b.id);
        const hasSelectedBenefits = benefitIds.some(id => selectedBenefits.includes(id));
        const hasUnselectedBenefits = benefitIds.some(id => !selectedBenefits.includes(id));

        return hasSelectedBenefits && hasUnselectedBenefits;
    };

    const isBenefitSelected = benefitId => selectedBenefits.includes(benefitId);

    const handleSelectBenefit = benefitId => {
        const isSelected = selectedBenefits.includes(benefitId);

        const pillar = pillarBenefits.find(pillar =>
            pillar.benefits.some(benefit => benefit.id === benefitId)
        );

        if (pillar) {
            let updatedSelectedPillars = [...selectedPillars];
            let updatedSelectedBenefits = [...selectedBenefits];

            if (isSelected) {
                // Deselect the benefit
                updatedSelectedBenefits = updatedSelectedBenefits.filter(id => id !== benefitId);

                // If no benefits are selected under this pillar, deselect the pillar
                const associatedBenefits = pillar.benefits.map(benefit => benefit.id);
                const areAnyBenefitsSelected = associatedBenefits.some(id => updatedSelectedBenefits.includes(id));

                if (!areAnyBenefitsSelected) {
                    updatedSelectedPillars = updatedSelectedPillars.filter(id => id !== pillar.id);
                }
            } else {
                if (limitSelectionToSinglePillar) {
                    // Allow selection only if no pillar is selected or if this pillar is already selected
                    if (
                        selectedPillars.length === 0 ||
                        selectedPillars.includes(pillar.id)
                    ) {
                        if (!updatedSelectedPillars.includes(pillar.id)) {
                            updatedSelectedPillars.push(pillar.id);
                        }
                        updatedSelectedBenefits.push(benefitId);
                    } else {
                        // Do not allow selecting benefits from other pillars
                        return;
                    }
                } else {
                    if (!updatedSelectedPillars.includes(pillar.id)) {
                        updatedSelectedPillars.push(pillar.id);
                    }
                    updatedSelectedBenefits.push(benefitId);
                }
            }

            setSelectedPillars(updatedSelectedPillars);
            setSelectedBenefits(updatedSelectedBenefits);
        }
    };

    const handleAccordionChange = pillarId => {
        setExpandedSections(prev =>
            prev.includes(pillarId)
                ? prev.filter(id => id !== pillarId)
                : [...prev, pillarId]
        );
    };

    const SkeletonAccordion = () =>
        Array.from({ length: 3 }).map((_, i) => (
            <Accordion disableGutters key={i}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls='pillar-content'
                    id='pillar-header'
                >
                    <Box display='flex' alignItems='center'>
                        <Checkbox disabled sx={{ color: '#0180ff' }} />
                        <Skeleton variant='text' width={200} />
                    </Box>
                </AccordionSummary>
            </Accordion>
        ));

    return (
        <Box>
            <TextField
                label="Search"
                variant='outlined'
                size='small'
                fullWidth
                value={pillarBenefitSearch}
                onChange={(e) => setPillarBenefitSearch(e.target.value)}
                sx={{ backgroundColor: '#ffffff', mb: 2 }}
                disabled={showSelectCampaignMessage}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search color='gray' />
                        </InputAdornment>
                    ),
                }}
            />
            <AccordionBorderBox>
                {isLoading ? (
                    <SkeletonAccordion />
                ) : filteredPillarBenefits.length > 0 ? (
                    filteredPillarBenefits.map(pillar => {
                        const pillarSelected = isPillarSelected(pillar.id);
                        const pillarIndeterminate = isPillarIndeterminate(pillar.id);
                        const pillarDisabled =
                            limitSelectionToSinglePillar &&
                            !pillarSelected &&
                            selectedPillars.length > 0;

                        return (
                            <Accordion
                                disableGutters
                                key={pillar.id}
                                expanded={expandedSections.includes(pillar.id)}
                                onChange={() => handleAccordionChange(pillar.id)}
                                sx={pillarDisabled ? DisabledPillarStyle : {}}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls='pillar-content'
                                    id='pillar-header'
                                >
                                    <Box display='flex' alignItems='center'>
                                        <Checkbox
                                            checked={pillarSelected}
                                            indeterminate={pillarIndeterminate}
                                            onChange={() => handleSelectPillar(pillar.id)}
                                            disabled={pillarDisabled}
                                            sx={{ color: '#0180ff' }}
                                        />
                                        <Typography>{pillar.name}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {pillar.benefits.map(benefit => {
                                        const benefitSelected = isBenefitSelected(benefit.id);
                                        const benefitDisabled =
                                            limitSelectionToSinglePillar &&
                                            !pillarSelected &&
                                            selectedPillars.length > 0;

                                        return (
                                            <Button
                                                key={benefit.id}
                                                fullWidth
                                                sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                                onClick={() => handleSelectBenefit(benefit.id)}
                                                disabled={benefitDisabled}
                                            >
                                                <Checkbox
                                                    checked={benefitSelected}
                                                    onChange={() => handleSelectBenefit(benefit.id)}
                                                    disableRipple
                                                    disabled={benefitDisabled}
                                                    sx={{ color: '#0180ff' }}
                                                />
                                                <Typography>{benefit.name}</Typography>
                                            </Button>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            cursor: 'default',
                            px: 2,
                            py: 2.5,
                            backgroundColor: 'white',
                        }}
                    >
                        {showSelectCampaignMessage ? (
                            <>
                                <Info sx={{ ml: 1 }} />
                                <Typography>Select a Campaign to get started.</Typography>
                            </>
                        ) : (
                            <>
                                <Error color='warning' sx={{ ml: 1 }} />
                                <Typography>No pillars or benefits found</Typography>
                            </>
                        )}
                    </Box>
                )}
            </AccordionBorderBox>
        </Box>
    );
}