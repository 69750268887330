import { TextField, Autocomplete, CircularProgress } from "@mui/material";
import { useGetUserDistrictsQuery } from "./integratorSlice";

export default function DistrictDropdown ({ currentDistrictId, selectedDistrict, setSelectedDistrict, userId }) {
    const { data: districts, isFetching } = useGetUserDistrictsQuery(userId);

    const filteredDistricts = districts ? districts.filter(district => district.id !== currentDistrictId) : [];

    const onDistrictChange = (_e, newValue) => {
        setSelectedDistrict(newValue);
    };

    return (
        <Autocomplete
            id='district-select'
            options={filteredDistricts}
            sx={{
                width: 300,
                '& .MuiInputBase-root': {
                    backgroundColor: 'white'
                }
            }}
            value={selectedDistrict}
            noOptionsText='No districts found'
            getOptionLabel={(option) => option ? `${option.publicIdentifier} - ${option.name}` : ""}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={onDistrictChange}
            fullWidth
            size='small'
            renderInput={(params) => (
                <TextField
                    {...params}
                    label='Select district'
                    variant='outlined'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {isFetching ? <CircularProgress sx={{ color: (theme) => theme.palette.grey[600] }} size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}