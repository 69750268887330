import { Card, Typography } from "@mui/material";
import WizardButtonGroup from "../../../Common/Buttons/WizardButtonGroup";
import CampaignDetails from "../../CampaignManagementCommon/CampaignDetails";

export default function CampaignWizardStepFour({ details, districtScopedGoals, districtCampaigns, districtSchools, pillarBenefits, gotoPrevStep, handleStepComplete, isSaving }) {
    const saveHandler = () => {
        handleStepComplete();
    };

    // Map districtScopedGoals to include pillar, benefit, basisCampaign, and schools
    const districtScopedGoalWithData = districtScopedGoals?.map(goal => {
        const pillar = pillarBenefits.find(pillar => pillar.id === goal.pillarId);
        const benefit = pillar?.benefits.find(benefit => benefit.id === goal.benefitId);
        const basisCampaign = districtCampaigns.find(campaign => campaign.id === goal.basisCampaignId);

        const schoolsMap = {};

        goal.schoolBuildingIds.forEach(schoolBuildingId => {
            // Find the school that contains this school building
            const schoolData = districtSchools.find(s =>
                s.schoolBuildings.some(b => b.id === schoolBuildingId)
            );
            if (schoolData) {
                // Find the specific school building
                const building = schoolData.schoolBuildings.find(b => b.id === schoolBuildingId);
                if (building) {
                    if (schoolsMap[schoolData.id]) {
                        // Add building if not already in school's schoolBuildings
                        if (!schoolsMap[schoolData.id].schoolBuildings.some(b => b.id === building.id)) {
                            schoolsMap[schoolData.id].schoolBuildings.push(building);
                        }
                    } else {
                        // Add school with current building
                        schoolsMap[schoolData.id] = {
                            id: schoolData.id,
                            name: schoolData.name,
                            schoolBuildings: [building],
                        };
                    }
                }
            }
        });

        // Convert schoolsMap to schools array
        const schools = Object.values(schoolsMap);

        return { ...goal, pillar, benefit, basisCampaign, schools };
    });

    const campaign = {
        name: details.name,
        startDate: details.startDate,
        endDate: details.endDate,
        coordinators: details.coordinators,
        objective: details.objective,
        campaignBenefits: districtScopedGoalWithData
    };

    return (
        <Card
            sx={{
                flexBasis: '75%',
                maxWidth: '640px',
                padding: '25px',
                mb: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <Typography sx={{ mb: 2 }}>
                <>
                    <span style={{ fontWeight: 'bold' }}>Review Campaign Details. </span>
                    If you need to make changes, use the stepper at the top of the screen to navigate to specific steps and edit any details as needed.
                </>
            </Typography>
            <CampaignDetails campaign={campaign} isReview />
            <WizardButtonGroup
                fullWidth
                backText="Go Back"
                backClick={gotoPrevStep}
                forwardClick={saveHandler}
                forwardText="Save & Complete"
                isLoading={isSaving}
            />
        </Card>
    );
}