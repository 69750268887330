export const SurveyStatusEnum = {
    PENDING: 1,
    COMPLETE: 2,
    EXPIRED: 3
}

export const ApprovalStatusEnum = {
    PENDING: 1,
    APPROVED: 2,
    DECLINED: 3
}

export const NotificationTypeEnum = {
    APPROVAL_REQUEST: 1,
    CAMPAIGN: 2,
    SURVEY_TEMPLATE: 4,
    SURVEY: 3,
    UNDEFINED: 5
}

export const ScorecardTrendEnum = {
    NO_BASELINE: { id: 1, name: 'No Baseline' },
    IMPROVEMENT: { id: 2, name: 'Improvement' },
    REGRESSION: { id: 3, name: 'Regression' },
    FLAT: { id: 4, name: 'Flat' }
}

export const TrendEnum = {
    UP: { id: 1, name: 'Up' },
    DOWN: { id: 2, name: 'Down' }
}

export const SystemStatusEnum = {
    ACTIVE: 1,
    INACTIVE: 2,
    REMOVED: 3,
    LOCKED: 4
}

export const RoleEnum = {
    DISTRICT_ADMIN: 1,
    SCHOOL_ADMIN: 2,
    FACULTY: 3,
    PARENT: 4,
    STUDENT: 5,
    INTEGRATOR: 6
}

export const StepsEnum = {
    ONE: 0,
    TWO: 1,
    THREE: 2,
    FOUR: 3,
    FIVE: 4
}

export const RecurrenceEnum = {
    WEEKLY: 3,
    BI_WEEKLY: 4,
    MONTHLY: 5
};

export const MonthlyRecurrenceEnum = {
    ON_A_DAY: 1,
    ON_A_FIRST_WEEKDAY: 2
}

export const SurveyQuestionTypeEnum = {
    YES_OR_NO: 1,
    MULTIPLE_CHOICE: 2,
    CHECKBOX_CHOICES: 3,
    SHORT_ANSWER: 4
}

export const SurveySessionStatusEnum = {
    UPCOMING: 1,
    ACTIVE: 2,
    CANCELLED: 3,
    SUCCESS: 4,
    FAILURE: 5
}

export const SurveyTemplateStatusEnum = {
    UPCOMING: 1,
    ACTIVE: 2,
    COMPLETED: 3,
    CANCELLED: 4
}

export const SurveyQuestionScoringTypeEnum = {
    IN_ORDER: 1,
    INVERTED: 2,
    NOT_SCORED: 3
}