import { api } from '../api';


export const userAgreementApi = api.injectEndpoints({
    endpoints: (builder) => ({
        updateUserAgreementAcceptance: builder.mutation({
            query: ({ userId}) => ({
                url: `user/updateUserAgreementAcceptance`,
                method: 'PUT',
                body: JSON.stringify(userId)
            }),
        }),
    }),
});


export const {
    useUpdateUserAgreementAcceptanceMutation
} = userAgreementApi;