import React, { useEffect, useState } from "react";
import { Box, Button, Card, FormHelperText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import WizardButtonGroup from "../../../Common/Buttons/WizardButtonGroup";
import ConfirmationModal from "../../../Common/ConfirmationModal";
import CampaignBenefitGoal from "../CampaignCommon/CampaignBenefitGoal";

/* mock data functions */
const seedableRandom = (seed) => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
};

const generateMockScoreArray = (seed, length) => {
    return Array.from({ length: length }, (_, i) => {
        const index = Math.floor(seedableRandom(seed + i) * 19);
        return (index + 1) * 5;
    });
};

const generateMockGrowthArray = (seed, length) => {
    return Array.from({ length: length }, (_, i) => {
        const index = Math.floor(seedableRandom(seed + i) * 39);
        return (index - 19) * 5;
    });
};

const generateMockDateRangeArray = (seed, length) => {
    const dateRanges = Array.from({ length: length }, (_, i) => {
        let startDate = new Date();
        startDate.setMonth(startDate.getMonth() + Math.floor(seedableRandom(seed + i) * 12));
        startDate.setDate(Math.floor(seedableRandom(seed + i + 1) * 28) + 1);

        let endDate = new Date(startDate);
        endDate.setMonth(endDate.getMonth() + 4);

        let startDateFormat = `${startDate.getMonth() + 1}/${startDate.getDate()}/${startDate.getFullYear().toString().substr(-2)}`;
        let endDateFormat = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear().toString().substr(-2)}`;

        return `${startDateFormat} - ${endDateFormat}`;
    });

    return dateRanges;
};
/* end mock data functions */

export default function CampaignWizardStepThree({ benefitGoals, setBenefitGoals, districtCampaigns, districtSchools, pillarBenefits = [], campaignName, campaignId, campaignStartDate, gotoPrevStep, handleStepComplete }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [districtCampaignsData, setDistrictCampaignsData] = useState([]);
    const [goalErrors, setGoalErrors] = useState({});
    const [error, setError] = useState('');
    const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);

    useEffect(() => {
        // Generate mock data
        const scores = generateMockScoreArray(10, districtCampaigns.length);
        const growth = generateMockGrowthArray(11, districtCampaigns.length);
        const dateRanges = generateMockDateRangeArray(12, districtCampaigns.length);

        // Combine campaigns with mock data
        const districtCampaignsWithScores = districtCampaigns.map((basisCampaign, i) => ({
            ...basisCampaign,
            score: scores[i],
            growth: growth[i],
            dateRange: dateRanges[i],
        }));

        let filteredCampaigns = districtCampaignsWithScores;

        // Filter out the current campaign if it exists
        if (campaignId != null) {
            filteredCampaigns = districtCampaignsWithScores.filter(
                (campaign) => campaign.id !== campaignId
            );
        }

        setDistrictCampaignsData(filteredCampaigns);
    }, [districtCampaigns, campaignId]);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (benefit, pillar) => {
        const newGoal = {
            basisCampaignId: null,
            schoolBuildingIds: [],
            benefitId: benefit.id,
            pillarId: pillar.id,
            target: null,
            notes: '',
        };
        setBenefitGoals(prevGoals => [...prevGoals, newGoal]);
        setGoalErrors(prevErrors => ({ ...prevErrors, [prevErrors.length]: { target: null, school: null } }));
        setError('');
        handleCloseMenu();
    };

    const onUpdateGoal = (index, updatedGoal) => {
        setBenefitGoals(prevGoals => prevGoals.map((goal, i) => (i === index ? updatedGoal : goal)));
    };

    const onCopyGoal = (index) => {
        const copiedGoal =  { ...benefitGoals[index] };
        copiedGoal.schoolBuildingIds = [];
        setBenefitGoals(prevGoals => [...prevGoals, copiedGoal]);
    };

    const onRemoveGoal = (index) => {
        setGoalErrors(prevErrors => {
            delete prevErrors[index];
            return prevErrors;
        });

        setBenefitGoals(prevGoals => prevGoals.filter((_, i) => i !== index));
    };

    const validateGoals = () => {
        const errors = {};

        benefitGoals.forEach((goal, index) => {
            errors[index] = { target: null, school: null };

            if (!goal.target) {
                errors[index].target = "Target value required";
            }
            if (!goal.schoolBuildingIds || goal.schoolBuildingIds.length === 0) {
                errors[index].school = "Scope benefit goal to at least one school";
            }
        });

        setGoalErrors(errors);
        return !Object.values(errors).some(error => error.target || error.school);
    };

    const handleValidation = () => {
        if (!benefitGoals.length) {
            setError("Please add at least one goal benefit");
            return;
        }

        // Validate goal forms
        if (!validateGoals()) {
            return;
        }

        // Validate at least one of each previously selected benefit has been used in goals
        const benefitIds = pillarBenefits.map(pillar => pillar.benefits.map(benefit => benefit.id)).flat();
        const selectedBenefitIds = benefitGoals.map(goal => goal.benefitId);
        if (benefitIds.some(id => !selectedBenefitIds.includes(id))) {
            setIsOverrideModalOpen(true);
            return;
        }

        handleStepComplete(benefitGoals);
    };

    const handleConfirmOverride = () => {
        setIsOverrideModalOpen(false);
        handleStepComplete(benefitGoals);
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    padding: '0% 3% 0% 3%',
                    maxWidth: '820px',
                }}
            >
                <Card
                    sx={{
                        flexBasis: '100%',
                        padding: '25px',
                        mb: '25px',
                        backgroundColor: (theme) => theme.palette.grey[100]
                    }}
                >
                    <Stack spacing={2}>
                        <Typography sx={{ pb: 1 }}>
                            <span>
                                <span style={{ fontWeight: 'bold' }}>Scope Campaign Benefit Goals. </span>
                                Add benefit goals and scope them to school buildings.
                            </span>
                        </Typography>
                        {benefitGoals.map((goal, index) => (
                            <CampaignBenefitGoal
                                key={`${goal.benefitId}-${index}`}
                                benefitGoals = {benefitGoals}
                                goal={goal}
                                index={index}
                                campaignName={campaignName}
                                campaignStartDate ={campaignStartDate}
                                error={goalErrors?.[index]}
                                onUpdateGoal={onUpdateGoal}
                                onCopyGoal={onCopyGoal}
                                onRemoveGoal={onRemoveGoal}
                                goalErrors={goalErrors}
                                setGoalErrors={setGoalErrors}
                                pillarBenefits={pillarBenefits}
                                districtCampaigns={districtCampaignsData}
                                districtSchools={districtSchools}
                            />
                        ))}
                        <FormHelperText error >
                            {error}
                        </FormHelperText>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant='text'
                                color='secondary'
                                startIcon={<Add color='secondary' />}
                                onClick={handleOpenMenu}
                            >
                                Add Benefit Goal
                            </Button>
                            <Menu
                                id='question-type-menu'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                slotProps={{
                                    paper: {
                                        style: {
                                            maxHeight: 120 * 4.5,
                                            width: '28ch',
                                        },
                                    },
                                }}
                            >
                                {pillarBenefits.map(pb => (
                                    <Box key={pb.id} >
                                        <Typography variant='body1' sx={{ mt: 1.25, mb: .75, mx: 2 }}>
                                            {pb.name}
                                        </Typography>
                                        {pb.benefits.map(benefit => (
                                            <MenuItem key={benefit.id} onClick={() => handleMenuItemClick(benefit, pb)}>
                                                <Typography variant='body2' sx={{ ml: 1 }}>
                                                    {benefit.name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Box>
                                ))}
                            </Menu>
                        </Box>
                    </Stack>
                </Card>
                <WizardButtonGroup fullWidth backClick={gotoPrevStep} forwardClick={handleValidation} />
            </Box>
            <ConfirmationModal
                isOpen={isOverrideModalOpen}
                close={() => setIsOverrideModalOpen(false)}
                onConfirm={handleConfirmOverride}
                title="Campaign Benefits Unscoped"
                message="Campaign Benefits that are not assigned will not be included in the campaign. Are you sure you want to continue?"
                saveBtnText="Confirm"
                cancelBtnText="Cancel"
            />
        </>
    );
}