import { useEffect, useState } from 'react';
import { Box, Button, FormHelperText, InputLabel, Paper } from '@mui/material';
import { useSwitchIntegratorDistrictMutation } from './integratorSlice';
import { useGetSessionQuery } from '../../app/slices/sessionSlice';
import DistrictDropdown from './DistrictDropdown';
import ConfirmationModal from '../Common/ConfirmationModal';
import { toast } from 'react-toastify';

export default function SwitchDistrictFormCard() {
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [helperMessage, setHelperMessage] = useState(null);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);

    const [switchDistrict, { isLoading }] = useSwitchIntegratorDistrictMutation();
    const { data: session, refetch } = useGetSessionQuery();

    useEffect(() => {
        if (session) {
            setHelperMessage('Currently operating in ' + session.schoolDistrict?.name);
        }
    }, [session]);

    const onSwitchDistrict = async () => {
        if (!selectedDistrict) {
            return;
        }

        if (session.schoolDistrict && selectedDistrict.id === session.schoolDistrict.id) {
            setHelperMessage('Already in this district');
            return;
        }

        setConfirmationOpen(true);
    };

    const onConfirmSwitch = async () => {
        try {
            await switchDistrict(selectedDistrict.id).unwrap();
            refetch();
            toast.success(`Successfully switched districts to ${selectedDistrict.name}`);
        } catch (error) {
            toast.error('Error switching districts')
        }
        setConfirmationOpen(false);
    };

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '15px',
                width: '350px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <InputLabel sx={{ mb: 1.3 }}>Switch School District</InputLabel>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '15px'
                }}
            >
                <DistrictDropdown
                    currentDistrictId={session?.schoolDistrictId}
                    selectedDistrict={selectedDistrict}
                    setSelectedDistrict={setSelectedDistrict}
                    userId={session?.id}
                />
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={onSwitchDistrict}
                    disabled={isLoading}
                >
                    Switch
                </Button>
            </Box>
            <FormHelperText error={helperMessage !== null} sx={{ mb: 1, mt: .5 }}>
                {helperMessage}
            </FormHelperText>
            <ConfirmationModal
                isOpen={isConfirmationOpen}
                close={() => setConfirmationOpen(false)}
                onConfirm={onConfirmSwitch}
                message={`Are you sure you would like to switch to ${selectedDistrict ? selectedDistrict.name : 'this district'}?`}
                saveBtnText="Yes, switch districts"
            />
        </Paper>
    );
}