import { api } from '../../app/api';
const TAG = 'Surveys';

export const surveysApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getSurveys: builder.query({
            query: () => 'survey',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        getPendingSurveys: builder.query({
            query: () => 'survey/pending',
            providesTags: () => [{ type: TAG }]
        }),
        getSurveyEngagement: builder.query({
            query: () => 'survey/engagement',
            providesTags: () => [{ type: TAG }]
        }),
        postSurveyCompletion: builder.mutation({
            query: (request) => ({
                url: `survey/post-survey-completion`,
                method: 'POST',
                body: request
            }),
            invalidatesTags: [{ type: TAG }]
        }),
        getSurveyStatus: builder.mutation({
            query: ({ id }) => ({
                url: `survey/survey-status/${id}`,
                method: 'GET'
            })
        }),
    })
})

export const {
    useGetSurveysQuery,
    useGetPendingSurveysQuery,
    useGetSurveyEngagementQuery,
    usePostSurveyCompletionMutation,
    useGetSurveyStatusMutation,
} = surveysApi;