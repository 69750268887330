import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { StepsEnum } from '../../../utility/enums';
import { useAddCampaignMutation, useGetCampaignForWizardMutation, useGetCampaignsForDistrictUserQuery, useUpdateCampaignMutation } from '../campaignManagementSlice';
import { useGetDistrictSchoolsQuery } from './campaignWizardSlice';
import ContentWrapper from '../../Common/ContentWrapper';
import CampaignWizardStepOne from './Steps/CampaignWizardStepOne';
import CampaignWizardStepTwo from './Steps/CampaignWizardStepTwo';
import CampaignWizardStepThree from './Steps/CampaignWizardStepThree';
import CampaignWizardStepFour from './Steps/CampaignWizardStepFour';
import { useGetCampaignPillarsWithBenefitsQuery } from '../../../app/slices/pillarsWithBenefitsSlice';
import StepsIndicator from '../../Common/StepIndicator/StepsIndicator';

const stepsArray = [
    { id: StepsEnum.ONE, description: 'Campaign Details' },
    { id: StepsEnum.TWO, description: 'Benefits' },
    { id: StepsEnum.THREE, description: 'District Scope' },
    { id: StepsEnum.FOUR, description: 'Review' },
];

export default function CampaignWizard() {
    const [currentStep, setCurrentStep] = useState(StepsEnum.ONE);
    const [isLoading, setIsLoading] = useState(true);
    const [editId, setEditId] = useState(null);

    const [stepOneDetails, setStepOneDetails] = useState({
        name: '',
        campaignId: '',
        startDate: null,
        endDate: null,
        coordinators: [],
        objective: '',
    });

    const [stepTwoPillars, setStepTwoPillars] = useState([]);
    const [stepTwoBenefits, setStepTwoBenefits] = useState([]);
    const [stepTwoPillarBenefits, setStepTwoPillarBenefits] = useState([]);

    const [stepThreeBenefitGoals, setStepThreeBenefitGoals] = useState([]);

    const navigate = useNavigate();
    const { id, copiedTemplateId } = useParams();

    const { data: districtCampaigns = [] } = useGetCampaignsForDistrictUserQuery();
    const { data: districtSchools = [] } = useGetDistrictSchoolsQuery();
    const { data: campaignPillarsBenefitsData, isLoading: isLoadingPillarBenefits } = useGetCampaignPillarsWithBenefitsQuery(id);
    const [getCampaignDetailsQuery] = useGetCampaignForWizardMutation();
    const [addCampaign, { isLoading: isAdding }] = useAddCampaignMutation();
    const [updateCampaign, { isLoading: isUpdating }] = useUpdateCampaignMutation();

    useEffect(() => {
        if (copiedTemplateId || id !== 'new') {
            setEditId(parseInt(id));
            setIsLoading(true);
            getCampaignDetailsQuery(copiedTemplateId || id)
                .unwrap()
                .then(res => {
                    // Set step one data to state
                    const details = {
                        name: res.name,
                        startDate: dayjs(res.startDate),
                        endDate: dayjs(res.endDate),
                        coordinators: res.coordinators,
                        objective: res.objective,
                    };

                    if (copiedTemplateId) {
                        details.name = `Copy of ${res.name}`;
                        details.startDate = null;
                        details.endDate = null;
                        details.coordinators = [];

                        toast.success('Campaign copied successfully');
                    }

                    setStepOneDetails(details);

                    // Set step two data to state
                    setStepTwoPillars(res.pillarIds);
                    setStepTwoBenefits(res.benefitIds);

                    // Set step three data to state
                    setStepThreeBenefitGoals(res.campaignBenefits);
                })
                .catch(err => {
                    console.error(err);
                    toast.error(err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [id, copiedTemplateId, getCampaignDetailsQuery]);

    useEffect(() => {
        if (!isLoadingPillarBenefits && campaignPillarsBenefitsData) {
            setStepTwoPillarBenefits(campaignPillarsBenefitsData);
        }
        
    }, [campaignPillarsBenefitsData, isLoadingPillarBenefits]);
    
    const cancel = () => {
        navigate('/campaign-management');
    };

    const handleStepOneComplete = () => setCurrentStep(StepsEnum.TWO);

    const handleStepTwoComplete = () => setCurrentStep(StepsEnum.THREE);

    const handleStepThreeComplete = () => setCurrentStep(StepsEnum.FOUR);

    // Save
    const handleStepFourComplete = () => {
        const dto = {
            name: stepOneDetails.name,
            objective: stepOneDetails.objective,
            coordinatorIds: stepOneDetails.coordinators.map(c => c.id),
            startDate: stepOneDetails.startDate.toISOString(),
            endDate: stepOneDetails.endDate.toISOString(),
            campaignToBenefitRequests: stepThreeBenefitGoals,
        };

        if (!editId) {
            addCampaign(dto)
                .unwrap()
                .then(res => {
                    if (res?.message) {
                        toast.error(`Error: ${res.message}`);
                        return;
                    }
                    toast.success('Campaign created successfully');
                    navigate('/campaign-management');
                })
                .catch(err => {
                    console.error(err);
                    toast.error("Error creating campaign");
                });
        }
        else {
            dto.id = editId;
            updateCampaign(dto)
                .unwrap()
                .then(res => {
                    if (res?.message) {
                        toast.error(`Error: ${res.message}`);
                        return;
                    }
                    toast.success('Campaign updated successfully');
                    navigate('/campaign-management');
                })
                .catch(err => {
                    console.error(err);
                    toast.error("Error updating campaign");
                });
        }
    };

    const checkLastSavedStep = () => {
        const savedSteps = [
            !!stepOneDetails.name && !!stepOneDetails.startDate && !!stepOneDetails.endDate,
            stepTwoPillars.length > 0 && stepTwoBenefits.length > 0,
            stepThreeBenefitGoals.length > 0, stepThreeBenefitGoals.length > 0,
        ];

        for (let i = savedSteps.length - 1; i >= 0; i--) {
            if (savedSteps[i]) {
                return i; 
            }
        }
        return 0; 
    };

    const gotoPrevStep = (step) => {
        const lastSavedStep = checkLastSavedStep();

        if (step <= lastSavedStep) {
            setCurrentStep(step);
        } 
    };

    let view = null;

    switch (currentStep) {
        case StepsEnum.ONE:
            view = <CampaignWizardStepOne
                details={stepOneDetails}
                setDetails={setStepOneDetails}
                districtCampaigns={districtCampaigns}
                handleStepComplete={handleStepOneComplete}
                cancel={cancel}
                isEdit={editId}
            />;
            break;
        case StepsEnum.TWO:
            view = <CampaignWizardStepTwo
                pillars={stepTwoPillars}
                setPillars={setStepTwoPillars}
                benefits={stepTwoBenefits}
                setBenefits={setStepTwoBenefits}
                setPillarBenefits={setStepTwoPillarBenefits}
                isEdit={editId}
                gotoPrevStep={() => gotoPrevStep(StepsEnum.ONE)}
                handleStepComplete={handleStepTwoComplete}
            />;
            break;
        case StepsEnum.THREE:
            view = <CampaignWizardStepThree
                benefitGoals={stepThreeBenefitGoals}
                setBenefitGoals={setStepThreeBenefitGoals}
                districtCampaigns={districtCampaigns}
                districtSchools={districtSchools}
                pillarBenefits={stepTwoPillarBenefits}
                campaignName={stepOneDetails.name}
                campaignStartDate={stepOneDetails.startDate}
                campaignId={editId}
                gotoPrevStep={() => gotoPrevStep(StepsEnum.TWO)}
                handleStepComplete={handleStepThreeComplete}
            />;
            break;
        case StepsEnum.FOUR:
            view = <CampaignWizardStepFour
                details={stepOneDetails}
                districtScopedGoals={stepThreeBenefitGoals}
                districtCampaigns={districtCampaigns}
                districtSchools={districtSchools}
                pillarBenefits={stepTwoPillarBenefits}
                gotoPrevStep={() => gotoPrevStep(StepsEnum.THREE)}
                handleStepComplete={handleStepFourComplete}
                isSaving={isAdding || isUpdating}
            />;
            break;
        default:
            view = <div>Invalid step</div>;
            break;
    }

    return (
        <ContentWrapper title="Campaign Management">
            <StepsIndicator
                steps={stepsArray}
                currentStep={currentStep}
                changeStep={(s) => gotoPrevStep(s)}
                lastSavedStep={checkLastSavedStep()}
            />
            <Box
                sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px', width: '100%' }}
            >
                {!isLoading && view}
            </Box>
        </ContentWrapper>
    );
}