import { useMemo, useState } from "react";
import { Card, Typography } from "@mui/material";
import WizardButtonGroup from "../../Common/Buttons/WizardButtonGroup";
import SurveyTemplateDetails from "../SurveyTemplateCommon/SurveyTemplateDetails";
import SurveyReview from "../../Common/Surveys/SurveyReview"
import { useGetCampaignsForDistrictUserQuery } from "../../CampaignManagement/campaignManagementSlice";
import { useGetCampaignPillarsWithBenefitsQuery } from "../../../app/slices/pillarsWithBenefitsSlice";
import { useGetCampaignSchoolsQuery } from "./surveyWizardSlice";
import { useGetSampleSizesQuery } from "../../../app/slices/sampleSizeSlice";
import { useGetRotationsQuery } from "../../../app/slices/rotationSlice";
import { useGetRolesQuery } from "../../../app/slices/rolesSlice";

export default function StepFive({ details: dto, prevStep, nextStep, isSaving }) {
    const [isSurveyReviewOpen, setIsSurveyReviewOpen] = useState(false);

    const { data: districtCampaigns = [], isLoading: isLoadingCampaigns } = useGetCampaignsForDistrictUserQuery();
    const { data: pillarBenefits, isLoading: isLoadingPillars } = useGetCampaignPillarsWithBenefitsQuery(dto.campaignId);
    const { data: campaignSchools, isLoading: loadingSchools } = useGetCampaignSchoolsQuery(dto.campaignId);
    const { data: sampleSizes, isLoading: loadingSampleSizes } = useGetSampleSizesQuery();
    const { data: rotations, isLoading: loadingRotations } = useGetRotationsQuery();
    const { data: roleOptions, isLoading: loadingRoles } = useGetRolesQuery();

    const details = useMemo(() => {
        return {
            title: dto.title,
            campaign: districtCampaigns?.find(campaign => campaign.id === dto.campaignId),
            pillars: pillarBenefits?.filter(pillar => dto.pillarIds.includes(pillar.id))?.map(pillar => ({
                ...pillar,
                benefits: pillar.benefits.filter(benefit => dto.benefitIds.includes(benefit.id))
            })),
            cronSchedule: dto.cronSchedule,
            endDate: dto.endDate,
            startDate: dto.startDate,
            minutesToComplete: dto.minutesToComplete,
            sampleSize: sampleSizes?.find(size => size.id === dto.sampleSizeId)?.value,
            rotation: rotations?.find(rotation => rotation.id === dto.rotationId)?.value,
            roles: roleOptions?.filter(role => dto.roleIds.includes(role.id)),
            schools: campaignSchools?.map(school => ({
                ...school,
                schoolBuildings: school.schoolBuildings.filter(building => dto.schoolBuildingIds.includes(building.id))
            }))
        };
    }, [dto, districtCampaigns, pillarBenefits, sampleSizes, rotations, roleOptions, campaignSchools]);

    const loading = isLoadingCampaigns || isLoadingPillars || loadingSchools || loadingSampleSizes || loadingRotations || loadingRoles;

    return (
        <>
            <Card
                sx={{
                    flexBasis: '75%',
                    maxWidth: '640px',
                    padding: '25px',
                    mb: '25px',
                    backgroundColor: (theme) => theme.palette.grey[100]
                }}
            >
                <Typography sx={{ mb: 2 }}>
                    <>
                        <span style={{ fontWeight: 'bold' }}>Review Survey Template Details. </span>
                        If you need to make changes, use the stepper at the top of the screen to navigate to specific steps and edit any details as needed.
                    </>
                </Typography>
                {!loading &&
                    <SurveyTemplateDetails
                        details={details}
                        isReview
                        openSurveyPreview={() => setIsSurveyReviewOpen(true)}
                    />
                }
                <WizardButtonGroup
                    fullWidth
                    backClick={prevStep}
                    forwardClick={nextStep}
                    forwardText="Save & Complete"
                    isLoading={isSaving}
                />
            </Card>
            <SurveyReview open={isSurveyReviewOpen} onClose={() => setIsSurveyReviewOpen(false)} surveyName={dto.title} surveyQuestions={dto.surveyQuestions} />
        </>
    );
};