import Typography from '@mui/material/Typography';

const DatagridToolbarTypography = ({ children, color }) => {
    return (
        <Typography color={color} variant='body1' sx={{ fontWeight: 'medium', fontSize: '13px' }}>
            {children}
        </Typography>
    );
};

export default DatagridToolbarTypography;