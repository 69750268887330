import { useState } from "react";
import { Box, Chip, Dialog, DialogContent, DialogTitle, IconButton, useTheme } from "@mui/material";
import { AccessTime, CancelRounded, CheckCircleOutline, Close, PlayCircleOutline } from "@mui/icons-material";
import SurveyTemplateDetails from "./SurveyTemplateCommon/SurveyTemplateDetails";
import { SurveyTemplateStatusEnum } from "../../utility/enums";
import SurveyCompletionModal from "../Common/Surveys/SurveyCompletionModal";

export default function SurveyTemplateDetailsModal({ isOpen, close, surveyTemplate }) {
    const [isSurveyPreviewOpen, setIsSurveyPreviewOpen] = useState(false);

    const theme = useTheme();

    const statusMap = {
        [SurveyTemplateStatusEnum.UPCOMING]: {
            icon: <AccessTime />,
            label: "Upcoming",
            color: 'secondary'
        },
        [SurveyTemplateStatusEnum.ACTIVE]: {
            icon: <PlayCircleOutline />,
            label: "Active",
            color: 'secondary'
        },
        [SurveyTemplateStatusEnum.COMPLETED]: {
            icon: <CheckCircleOutline />,
            label: "Completed",
            color: 'success'
        },
        [SurveyTemplateStatusEnum.CANCELLED]: {
            icon: <CancelRounded />,
            label: "Cancelled",
            color: 'error'
        }
    };

    const status = statusMap[surveyTemplate.surveyTemplateStatusId];

    const StatusChip = () => {
        const color = status.color === 'error' 
        ? theme.palette[status.color].contrastText
        : theme.palette[status.color].dark;

        return (
            <Chip
                icon={status.icon}
                label={status.label}
                color={status.color}
                sx={{
                    alignSelf: 'flex-end',
                    color: color,
                    backgroundColor: theme.palette[status.color].lighter
                }}
            />
        );
    };

    return (
        <>
            <Dialog variant='outlined' open={isOpen} onClose={() => close()} fullWidth maxWidth='md'>
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 5 }}>
                        <Box>
                            Survey Template Details
                        </Box>
                        <StatusChip />
                    </Box>
                    <IconButton
                        aria-label='close'
                        onClick={() => { close(); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <SurveyTemplateDetails
                        details={surveyTemplate}
                        openSurveyPreview={() => setIsSurveyPreviewOpen(true)}
                    />
                </DialogContent>
            </Dialog>
            <SurveyCompletionModal isPreview open={isSurveyPreviewOpen} onClose={() => setIsSurveyPreviewOpen(false)} surveyTemplateId={surveyTemplate.id} surveyName={surveyTemplate.title} />
        </>
    )
}
