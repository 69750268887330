import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Common/ConfirmationModal';
import ContentWrapper from '../Common/ContentWrapper';
import { RoleEnum } from '../../utility/enums';
import { useGetSessionQuery } from '../../app/slices/sessionSlice';
import { useGetSurveyTemplateStatusQuery } from '../../app/slices/surveyTemplateStatusSlice';
import { useGetAllSurveyTemplatesQuery, useDeleteSurveyTemplateMutation, useCancelSurveyTemplateMutation } from './surveyTemplateManagementSlice';
import { SurveyManagementDataGrid } from './SurveyManagementDataGrid';

const writeRolePermissions = [RoleEnum.DISTRICT_ADMIN, RoleEnum.INTEGRATOR];

export default function SurveyManagementContainer() {
    const [deletingSurvey, setDeletingSurvey] = useState(null);
    const [cancellingSurvey, setCancellingSurvey] = useState(null);

    const { data: surveys = [], isLoading } = useGetAllSurveyTemplatesQuery();
    const [deleteSurveyTemplate] = useDeleteSurveyTemplateMutation();
    const [cancelSurveyTemplate] = useCancelSurveyTemplateMutation();
    const { data: surveyTemplateStatuses = [] } = useGetSurveyTemplateStatusQuery();

    const { data: session, isLoading: isSessionLoading } = useGetSessionQuery();
    const [searchParams] = useSearchParams();
    const inboundSurveyTemplateId = useMemo(() => searchParams.get('surveyTemplateId'), [searchParams]);

    const hasWriteAccess = session?.roles.some(r => writeRolePermissions.includes(r.role.id));

    function onDeleteSurvey(id) {
        deleteSurveyTemplate(id)
            .then((response) => {
                const isDeleting = response?.data?.isDeleting;

                if (isDeleting === null)
                    toast.error(`Failed to delete survey`);

                setDeletingSurvey(null);
                toast.success('Successfully deleted survey');
            })
            .catch((error) => {
                toast.error(error);
                setDeletingSurvey(null);
            });
    };

    function onCancelSurvey(id) {
        cancelSurveyTemplate(id)
            .then((response) => {
                const isCancelling = response?.data?.isCancelling;

                if (isCancelling === null)
                    toast.error(`Failed to cancel survey`);

                setCancellingSurvey(null);
                toast.success('Successfully cancelled survey');
            })
            .catch((error) => {
                toast.error(error);
                setCancellingSurvey(null);
            });

    };

    return (
        <>
            <ContentWrapper title="Survey Template Management">
                <SurveyManagementDataGrid
                    statusOptions={surveyTemplateStatuses}
                    surveys={surveys}
                    inboundSurveyTemplateId={inboundSurveyTemplateId}
                    isLoading={isLoading}
                    isSessionLoading={isSessionLoading}
                    setDelete={setDeletingSurvey}
                    setCancel={setCancellingSurvey}
                    hasWriteAccess={hasWriteAccess}
                />
            </ContentWrapper>
            <ConfirmationModal
                isOpen={!!deletingSurvey}
                close={() => setDeletingSurvey(null)}
                onConfirm={() => onDeleteSurvey(deletingSurvey.id)}
                title={`Delete ${deletingSurvey && deletingSurvey.name}`}
                message="Please confirm you'd like to delete the selected survey template. This is not reversible once deleted."
                saveBtnText="Delete"
            />
            <ConfirmationModal
                isOpen={!!cancellingSurvey}
                close={() => setCancellingSurvey(null)}
                onConfirm={() => onCancelSurvey(cancellingSurvey.id)}
                title={`Cancel ${cancellingSurvey && cancellingSurvey.name}`}
                message="Please confirm you'd like to cancel the selected survey template."
                saveBtnText="Cancel Survey Template"
                cancelBtnText="Do Not Cancel Survey Template"
            />
        </>
    )
}