import { Button, Tooltip } from '@mui/material';
import UploadIcon from '../../Icons/UploadIcon';
import DatagridToolbarTypography from './ButtonTypography/DatagridToolbarTypography';

const ImportButton = ({ onClick }) => {
    return (
        <Tooltip title='Import spreadsheet'>
            <Button
                variant='text'
                color='primary'
                startIcon={<UploadIcon />}
                onClick={onClick}
            >
                <DatagridToolbarTypography>
                    Import
                </DatagridToolbarTypography>
            </Button>
        </Tooltip>
    );
};

export default ImportButton;