// BenefitFormModal.js
import { Dialog, DialogTitle, DialogContent, TextField, Button, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import DialogActionButtons from '../../../Common/Buttons/DialogActionButtons';

const BenefitFormModal = ({ isOpen, onClose, benefitForm, handleBenefitFormChange, handleBenefitUpdate, isEditingBenefit, formErrors }) => {
   return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
         <DialogTitle>
            {benefitForm?.id ? 'Edit Benefit' : 'Add Benefit'}
            <Button
               aria-label="close"
               onClick={onClose}
               sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
            >
               <Close />
            </Button>
         </DialogTitle>
         <DialogContent>
            <Stack spacing={3} sx={{ pt: 1 }}>
               <TextField
                  id="benefit-name"
                  label="Benefit Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={benefitForm.name}
                  error={!!formErrors.benefitName}
                  helperText={formErrors.benefitName}
                  onChange={(e) => handleBenefitFormChange('name', e.target.value.slice(0, 100))}
                  sx={{ mb: 2 }}
               />
               <TextField
                  id="benefit-description"
                  label="Benefit Description"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline
                  rows={6}
                  value={benefitForm.description || ""}
                  onChange={(e) => handleBenefitFormChange('description', e.target.value.slice(0, 200))}
               />
            </Stack>
         </DialogContent>
         <DialogActionButtons
            fullWidth
            closeText="Cancel"
            onClose={onClose}
            submitText={isEditingBenefit ? 'Update' : 'Add'}
            onSubmit={handleBenefitUpdate}
         />
      </Dialog>
   );
};

export default BenefitFormModal;