import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Box, Chip, Stack, Dialog, DialogContent, DialogTitle, IconButton, TextField, Autocomplete, Skeleton } from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { useAddUserMutation, useUpdateUserMutation } from './userManagementSlice';
import { useGetRolesQuery } from '../../../app/slices/rolesSlice';
import { REGEX } from '../../../utility/regex';
import { useGetSystemStatusesQuery } from '../../../app/slices/systemStatusSlice';
import { RoleEnum } from '../../../utility/enums';
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import DialogActionButtons from '../../Common/Buttons/DialogActionButtons';
import GroupedSchoolBuildingAutocomplete from '../../Common/FormElements/GroupedSchoolBuildingAutocomplete';

function UserFormModal({ isOpen, user, close, schools }) {
    const [userSchoolBuildingIds, setUserSchoolBuildingIds] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [confirmEmailAddress, setConfirmEmailAddress] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [studentIds, setStudentIds] = useState([]);
    const [studentId, setStudentId] = useState('');
    const [systemStatus, setSystemStatus] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [filteredRoles, setFilteredRoles] = useState([]);

    const [addUser, { isLoading: isAdding }] = useAddUserMutation();
    const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
    const { data: roles = [] } = useGetRolesQuery();
    const { data: systemStatuses = [] } = useGetSystemStatusesQuery();
    const { data: session } = useGetSessionQuery();

    useEffect(() => {
        if (session?.roles?.some(x => x.roleId === RoleEnum.INTEGRATOR)) {
            setFilteredRoles(roles.filter(x => x.id !== RoleEnum.DISTRICT_ADMIN));
        }
        else {
            setFilteredRoles(roles);
        }
    }, [session, roles]);

    useEffect(() => {
        if (!user || !user.id) return;

        const schoolBuildingIds = user.schools.flatMap(school =>
            school.schoolBuildings?.map(building => building.id) || []
        );

        setUserSchoolBuildingIds(schoolBuildingIds);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setSystemStatus(user.systemStatusName);
        setEmailAddress(user.emailAddress);
        setConfirmEmailAddress(user.emailAddress);
        setUserRoles(user.roles?.map(x => x.name));
        setStudentIds(user.studentIds.map(x => x.value));
    }, [user]);

    function reset() {
        setUserSchoolBuildingIds([]);
        setFirstName('');
        setLastName('');
        setEmailAddress('');
        setSystemStatus('');
        setConfirmEmailAddress('');
        setStudentIds([]);
        setUserRoles([]);
        setFormErrors({});
    }

    function isFormValid() {
        const errors = {};

        errors['first-name'] = !firstName?.trim() ? 'Field is required' : null;
        errors['last-name'] = !lastName?.trim() ? 'Field is required' : null;
        errors['email-address'] = !emailAddress?.trim() ? 'Field is required' : null;
        errors['confirm-email-address'] = !confirmEmailAddress?.trim() ? 'Field is required' : null;
        errors.roles = !userRoles?.length ? 'Field is required' : null;

        if (user?.id)
            errors['system-status'] = !systemStatus?.trim() ? 'Field is required' : null;
        if (!errors['email-address'])
            errors['email-address'] = !emailAddress.match(REGEX.EMAIL) ? 'Email must be properly formatted' : null;

        if (!errors['email-address'])
            errors['email-address'] = emailAddress !== confirmEmailAddress ? 'Emails must match' : null;

        if (!errors['confirm-email-address'])
            errors['confirm-email-address'] = !confirmEmailAddress.match(REGEX.EMAIL) ? 'Email must be properly formatted' : null;

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    }

    function validate(e) {
        setFormErrors(prev => ({
            ...prev,
            [e.target.id]: !e.target.value.trim() ? 'Field is required' : null
        }));
    }

    function addStudentId() {
        if (studentId.trim() === '') {
            return;
        }
        else if (studentIds.includes(studentId)) {
            setFormErrors({ ...formErrors, 'student-id': 'Student ID already entered' });
            return;
        }
        setStudentIds([...studentIds, studentId]);
        setStudentId('');
    }

    function removeStudentId(value) {
        const filtered = studentIds.filter(x => x !== value);

        setStudentIds(filtered);
    }

    function handleClose() {
        reset();
        close();
    }

    function submit() {
        if (!isFormValid())
            return;

        const dto = {
            schoolBuildingIds: userSchoolBuildingIds,
            firstName,
            lastName,
            emailAddress,
            schoolDistrictId: session.schoolDistrictId,
            roleIds: roles.filter(x => userRoles.includes(x.value)).map(x => x.id),
            studentIds,
        }

        if (user?.id) {
            dto.id = user.id;
            dto.schoolDistrictId = user.schoolDistrictId;
            dto.systemStatusId = systemStatuses.find(x => x.value === systemStatus).id

            updateUser(dto)
                .unwrap()
                .then((response) => {
                    if (response?.error)
                        throw new Error('An error occurred when updating user');

                    if (response?.message) {
                        setFormErrors({ ...formErrors, 'email-address': response.message })
                        return;
                    }
                    toast.success('Successfully updated user');
                    reset();
                    close();
                })
                .catch(error => {
                    console.log(error);
                    toast.error("An error occurred when updating user");
                });
        } else {
            addUser(dto)
                .unwrap()
                .then((response) => {
                    if (response?.error)
                        throw new Error('An error occurred when adding user');

                    if (response?.message) {
                        toast.error(`Error: ${response.message}`);
                        setFormErrors({ ...formErrors, 'email-address': response.message })
                        setFormErrors({ ...formErrors, 'confirm-email-address': response.message })
                        return;
                    }
                    toast.success('Successfully added user');
                    reset();
                    close();
                })
                .catch(error => {
                    console.log(error);
                    toast.error("An error occurred when adding user");
                });
        }
    }

    return (
        <Dialog open={isOpen} onClose={close} fullWidth maxWidth='sm'>
            <DialogTitle>
                {user === -1 ? "Add User" : user?.id ? "Edit User" : <Skeleton variant='text' width={100} sx={{ visibility: 'hidden' }} />}
                <IconButton
                    aria-label='close'
                    onClick={() => { close(); reset(); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: '.3em !important' }}>
                <Stack spacing={3}>
                    <GroupedSchoolBuildingAutocomplete
                        id='user-school-building'
                        fullWidth
                        schools={schools}
                        schoolBuildingIds={userSchoolBuildingIds}
                        setSchoolBuildingIds={setUserSchoolBuildingIds}
                        tagLimit={3}
                    />
                    <Box sx={{ display: 'inline-flex', gap: '20px' }}>
                        <TextField
                            id='user-first-name'
                            label='First Name'
                            variant='outlined'
                            size='small'
                            type='text'
                            required
                            fullWidth
                            value={firstName}
                            onBlur={validate}
                            error={!!formErrors['first-name']}
                            helperText={formErrors['first-name']}
                            onChange={e => setFirstName(e.target.value)}
                        />
                        <TextField
                            id='user-last-name'
                            label='Last Name'
                            variant='outlined'
                            size='small'
                            type='text'
                            required
                            fullWidth
                            value={lastName}
                            onBlur={validate}
                            error={!!formErrors['last-name']}
                            helperText={formErrors['last-name']}
                            onChange={e => setLastName(e.target.value)}
                        />
                    </Box>
                    <TextField
                        id='user-email-address'
                        label='Email Address'
                        variant='outlined'
                        size='small'
                        type='text'
                        required
                        value={emailAddress}
                        onBlur={validate}
                        error={!!formErrors['email-address']}
                        helperText={formErrors['email-address']}
                        onChange={e => setEmailAddress(e.target.value)}
                    />
                    <TextField
                        id='user-confirm-email-address'
                        label='Confirm Email Address'
                        variant='outlined'
                        size='small'
                        type='text'
                        required
                        value={confirmEmailAddress}
                        onBlur={validate}
                        error={!!formErrors['confirm-email-address']}
                        helperText={formErrors['confirm-email-address']}
                        onChange={e => setConfirmEmailAddress(e.target.value)}
                    />
                    <Box sx={{ display: 'inline-flex', gap: '20px' }}>
                        {user?.id &&
                            <Autocomplete
                                id='user-system-status'
                                size='small'
                                fullWidth
                                disableClearable
                                options={systemStatuses.map(x => x.value)}
                                value={systemStatus || null}
                                onChange={(_e, v) => setSystemStatus(v)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label='Status'
                                        required
                                        onBlur={validate}
                                        error={!!formErrors['system-status']}
                                        helperText={formErrors['system-status']}
                                    />}
                            />
                        }
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            disableClearable
                            id="user-roles-autocomplete"
                            size="small"
                            fullWidth
                            options={filteredRoles.map((r) => r.value)}
                            value={userRoles}
                            onChange={(_e, v) => setUserRoles(v)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Role(s)"
                                    required
                                    onBlur={validate}
                                    error={!!formErrors['roles']}
                                    helperText={formErrors['roles']}
                                />
                            )}
                            disabled={userRoles.includes('Integrator')}
                        />
                    </Box>
                    <Box>
                        <TextField
                            id='add-user-student-id'
                            label='Add Student ID'
                            variant='outlined'
                            size='small'
                            type='text'
                            value={studentId}
                            error={!!formErrors['student-id']}
                            helperText={formErrors['student-id']}
                            onBlur={formErrors['student-id'] ? () => setFormErrors({ ...formErrors, 'student-id': null }) : null}
                            onChange={e => setStudentId(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {addStudentId()}
                            }}
                        />
                        <Button
                            sx={{ paddingTop: '10px', marginLeft: '10px' }}
                            variant='text'
                            type='button'
                            startIcon={<Add />}
                            onClick={addStudentId}
                        >
                            Add Student ID
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                        <Box>Student IDs:</Box>
                        {studentIds.map(x => (
                            <Chip
                                label={x}
                                key={`add-user-sid-${x}`}
                                type='chip'
                                onDelete={() => removeStudentId(x)}
                            />
                        ))}
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActionButtons
                fullWidth
                closeText='Cancel'
                onClose={handleClose}
                submitText='Save'
                onSubmit={submit}
                isLoading={isUpdating || isAdding}
            />
        </Dialog>
    )
}

export default UserFormModal;