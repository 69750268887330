import { api } from "../api";

export const surveyTemplateStatusApi = api.injectEndpoints({
    tagTypes: ['SurveyTemplateStatus'],
    endpoints: builder => ({
        getSurveyTemplateStatus: builder.query({
            query: () => '/lookup/survey-template-statuses',
            providesTags: (result) =>
                result
                    ? result.map(({ id }) => ({ type: 'SurveyTemplateStatus', id }))
                    : ['SurveyTemplateStatus'],
        })
    })
})

export const {
    useGetSurveyTemplateStatusQuery
} = surveyTemplateStatusApi