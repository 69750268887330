import { Tooltip } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Delete, Edit, Lock, ExpandMore, ExpandLess } from "@mui/icons-material";
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import { CustomDataGridToolbar } from "../../Common/DataGrid/CustomDataGridToolbar";
import { LockOpenIcon } from "../../Icons";
import BenefitDataTable from "./BenefitDataTable";
import { useMemo } from "react";

function PillarManagementDataGrid({ pillars, isLoading, setEdit, setDelete, setLock }) {
    const { data: session = {} } = useGetSessionQuery();

    const columns = useMemo(() => {
        return [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                headerAlign: 'center',
                align: 'left',
                minWidth: 175,
                getActions: ({ row }) => {
                    const isLocked = row.lockedByUserId;
                    const cnEdit = isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : 'action-blue' : 'action-blue';
                    const cnDelete = isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : 'action-red' : 'action-red';
                    const cnLock = isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : 'action-purple' : 'action-purple';
                    const isDisabled = isLocked ? row.lockedByUserId !== session?.id : false;

                    return [
                        <Tooltip title="Edit Pillar" key='edit-pillar'>
                            <GridActionsCellItem
                                icon={<Edit className={cnEdit} sx={{ fontSize: 24 }} />}
                                onClick={() => setEdit(row)}
                                label='Edit'
                                disabled={isDisabled}
                            />
                        </Tooltip>,
                        <Tooltip title="Delete Pillar" key='edit-pillar'>
                            <GridActionsCellItem
                                icon={<Delete className={cnDelete} sx={{ fontSize: 24 }} />}
                                onClick={() => setDelete(row)}
                                label='Delete'
                                disabled={isDisabled}
                            />
                        </Tooltip>,
                        <Tooltip title={isLocked ? 'Unlock Pillar' : 'Lock Pillar'} key='lock-pillar'>
                            <GridActionsCellItem
                                icon={isLocked ? <Lock className={cnLock} sx={{ fontSize: 24 }} /> : <LockOpenIcon className='action-purple-svg' sx={{ fontSize: 24 }} />}
                                onClick={() => setLock({ id: row.id, isLocked: isLocked })}
                                label='Lock'
                                disabled={isDisabled}
                            />
                        </Tooltip>
                    ];
                }
            },
            {
                field: 'name',
                headerName: 'Pillar Name',
                flex: .66,
                editable: false,
            },
            {
                field: 'description',
                headerName: 'Pillar Description',
                flex: 1,
                editable: false,
            },
            {
                field: 'systemStatusName',
                headerName: 'Status',
                flex: 0.25,
                editable: false,
            }
        ];
    }, [setDelete, setEdit, setLock, session]);

    return (
        <DataGridPro
            getDetailPanelContent={({ row }) => {
                if (!row.benefits || row.benefits.length === 0) return null;
                else return <BenefitDataTable benefits={row.benefits} />
            }}
            getDetailPanelHeight={() => 'auto'}
            autoHeight
            slots={{
                detailPanelExpandIcon: ExpandMore,
                detailPanelCollapseIcon: ExpandLess,
                toolbar: () =>
                    <CustomDataGridToolbar
                        hasWriteAccess
                        handleCreateNew={() => setEdit(-1)}
                    />
            }}
            rows={pillars}
            columns={columns}
            initialState={{
                pagination: { paginationModel: { pageSize: 25, } },
                sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
            }}
            loading={isLoading}
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 !== 0 ? 'grid-odd' : null}
            pageSizeOptions={[25, 50, 100]}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No pillars' }}
        />
    );
}

export default PillarManagementDataGrid;
