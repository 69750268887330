import { Box, CardContent, Grid, Skeleton } from "@mui/material";
import BorderCard from "../Containers/BorderCard";

export default function SurveyCardSkeleton() {
    return (
        <BorderCard>
            <CardContent>
                <Grid container direction='column'>
                    <Grid item>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', mr: 2 }}>
                                <Skeleton variant='text' width={360} height={37} sx={{ mr: 2 }} />
                            </Box>
                            <Skeleton variant='rounded' width={96} height={32} sx={{ mt: 1, borderRadius: '20px' }} />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Skeleton variant='text' width={160} height={27} />
                        <Skeleton variant='text' width={220} height={27} />
                    </Grid>
                </Grid>
            </CardContent>
        </BorderCard>
    );
}