import { Box } from "@mui/material";
import styled from "styled-components";

const StyledBox = styled(Box)(({ backgroundColor }) => ({
    padding: '10px',
    maxHeight: '540px',
    overflowY: 'auto',
    border: '1px solid #9F9F9F',
    borderRadius: '5px',
    backgroundColor: backgroundColor || '#FAFAFA',
}));

export default function InfoBorderBox({ children, backgroundColor, ...props }) {
    return (
        <StyledBox backgroundColor={backgroundColor} {...props}>
            {children}
        </StyledBox>
    );
};