import { api } from '../api';
const TAG = 'NotificationSettings';

export const notificationSettingsApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getNotificationSettings: builder.query({
            query: () => 'notificationSetting',
            providesTags: () => [{ type: TAG }]
        }),
        putNotificationSettings: builder.mutation({
            query: (request) => ({
                url: 'notificationSetting',
                method: 'PUT',
                body: JSON.stringify(request)
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetNotificationSettingsQuery,
    usePutNotificationSettingsMutation
} = notificationSettingsApi;