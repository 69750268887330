import { useEffect, useMemo, useState } from 'react';
import { Box, Pagination, Stack, Typography } from '@mui/material';
import { SurveyStatusEnum, SystemStatusEnum } from '../../utility/enums';
import SurveyCard from '../Common/Surveys/SurveyCard';
import SurveyFilters from './SurveyFilters';
import ConfirmationModal from '../Common/ConfirmationModal';
import SurveyCompletionModal from '../Common/Surveys/SurveyCompletionModal';
import SurveyCardSkeleton from '../Common/Skeletons/SurveyCardSkeleton';
import { toast } from 'react-toastify';
import { useGetSurveyStatusMutation } from './surveysSlice';

const defaultFilters = {
    hideCompleted: true,
    searchTerm: '',
};

const itemsPerPage = 5;

export default function SurveyList({ surveys, isLoading , inboundSurveyId }) {
    const [filters, setFilters] = useState({ ...defaultFilters });
    const [page, setPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [isSurveyOpen, setIsSurveyOpen] = useState(false);

    const [getSurveyStatus] = useGetSurveyStatusMutation();

    const filteredSurveys = useMemo(() => {
        const searchTermIncludes = (value) =>
            value?.toUpperCase().includes(filters.searchTerm.toUpperCase());

        return surveys?.filter(s => {
            if (filters.hideCompleted && s.surveyStatusId === SurveyStatusEnum.COMPLETE) return false;
            if (filters.searchTerm && !searchTermIncludes(s.name)) return false;
            return true;
        });
    }, [surveys, filters]);

    const paginatedSurveys = useMemo(() => {
        return filteredSurveys.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    }, [filteredSurveys, page]);

    useEffect(() => {
        const totalPages = Math.ceil(filteredSurveys.length / itemsPerPage);
        if (page > totalPages) {
            setPage(1);
        }
    }, [filteredSurveys, page]);

    useEffect(() => {
        if (!inboundSurveyId)
            return;

        getSurveyStatus({ id: inboundSurveyId })
            .then(response => {
                const d = response.data;

                if (d.systemStatusId !== SystemStatusEnum.ACTIVE) {
                    toast.error('The selected survey has been deleted and is no longer available.');
                    return;
                }

                if (d.isExpired) {
                    toast.error('The selected survey has expired.');
                    return;
                }

                const s = surveys.find(x => x.id === Number(inboundSurveyId));
                
                if (s) {
                    openModal(s);
                }
            })
            .catch(e => {
                console.log(e);
                toast.error('The selected survey has been deleted and is no longer available.');
            })
    }, [inboundSurveyId]);

    const openModal = (survey) => {
        setSelectedSurvey(survey);
        setIsModalOpen(true);
    };

    const launchSurvey = () => {
        setIsModalOpen(false);
        setIsSurveyOpen(true);
    };

    return (
        <>
            <Stack spacing={2} sx={{ pt: 1, mr: 2 }}>
                <SurveyFilters filters={filters} setFilters={setFilters} />
                {!isLoading ? (
                    paginatedSurveys.length > 0 ? (
                        paginatedSurveys.map(survey => (
                            <SurveyCard key={survey.id} survey={survey} hasButton openModal={openModal} />
                        ))
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 4 }}>
                            <Typography variant='body2'>No {filters['hideCompleted'] && "pending"} surveys</Typography>
                        </Box>
                    )
                ) : (
                    Array.from({ length: 1 }).map((_, i) => (
                        <SurveyCardSkeleton key={i} />
                    ))
                )}
                {filteredSurveys.length > 0 && (
                    <Pagination
                        shape='rounded'
                        count={Math.ceil(filteredSurveys.length / itemsPerPage)}
                        page={page}
                        onChange={(_e, value) => setPage(value)}
                        sx={{ alignSelf: 'center' }}
                    />
                )}
            </Stack>
            {selectedSurvey && (
                <ConfirmationModal
                    isOpen={isModalOpen}
                    close={() => setIsModalOpen(false)}
                    onConfirm={launchSurvey}
                    hideIcon
                    title={`Would you like to begin ${selectedSurvey.name}?`}
                    message={`This survey should take you approximately ${selectedSurvey.minutesToComplete} minutes to complete. If you are unable to finish, your data will be lost.`}
                    saveBtnText="Confirm"
                    cancelBtnText="Cancel"
                />
            )}
            <SurveyCompletionModal open={isSurveyOpen} onClose={() => setIsSurveyOpen(false)} survey={selectedSurvey} />
        </>
    )
}