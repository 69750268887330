import { Box, LinearProgress, Typography } from '@mui/material';
import { decimalToPercentage, normalizePercentages, returnTrendColorObject } from './scorecardUtils';
import styled from 'styled-components';
import { ScorecardTrendEnum } from '../../utility/enums';

const ProgressBarBox = styled(Box)`
    display: flex;
    flex-direction: column;
    `;

const ProgressBarLabelBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    `;

export default function ScorecardProgressBar({ target, base, score, growth, trend }) {
    return (
        <ProgressBarBox>
            {growth !== undefined &&
                <Typography
                    variant='caption'
                    align='center'
                >
                    {trend === ScorecardTrendEnum.REGRESSION ? '-' : '+'}
                    {decimalToPercentage(growth)}%
                </Typography>
            }
            <LinearProgress
                variant='determinate'
                color={returnTrendColorObject(trend).themeColor}
                value={normalizePercentages(base ?? 0, target, score)}
            />
            <ProgressBarLabelBox>
                <Typography
                    variant='caption'
                >
                    {trend === ScorecardTrendEnum.NO_BASELINE ? 'B' : decimalToPercentage(base) + '%'}
                </Typography>
                <Typography
                    variant='caption'
                >
                    {decimalToPercentage(target)}%
                </Typography>
            </ProgressBarLabelBox>
        </ProgressBarBox>
    );
}