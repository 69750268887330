import React from 'react';
import { Box, Tooltip, Typography, Table, TableContainer, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import { Info } from '@mui/icons-material';
import ScorecardTableBenefitRow from './ScorecardTableBenefitRow';

const columns = [
  {
    id: 'growthPercentage',
    label: 'Growth',
    tooltipTitle: 'Growth Metric - The percentage of scoring growth based on the previous Growth campaign.'
  },
  {
    id: 'scorePercentage',
    label: 'Score',
    tooltipTitle: 'Accountability Score - Current Pillar/Benefit scoring for the active Growth campaign.'
  },
  {
    id: 'baseGrowthPercentage',
    label: 'Growth Progress',
    tooltipTitle: 'Growth Progress - Visual showing scoring progress based on the Growth Campaign\'s defined goals.'
  },
];

export default function ScorecardTable({ pillar, district }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant='subtitle1' component='h3' sx={{ fontWeight: 'bold' }}>
                {district.name}
              </Typography>
            </TableCell>
            {columns.map(column => (
              <TableCell key={column.id}>
                <Box sx={{ display: 'flex', gap: '5px' }}>
                  <Typography variant='subtitle2' component='span'>
                    {column.label}
                  </Typography>
                  <Tooltip arrow title={column.tooltipTitle}>
                    <Box>
                      <Info />
                    </Box>
                  </Tooltip>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant='caption' component='p'>
                Pillar
              </Typography>
            </TableCell>
            <TableCell colSpan={4}>
              <Typography variant='subtitle2' component='p'>
                {pillar.name}
              </Typography>
            </TableCell>
          </TableRow>
          {pillar.schools.map(school => (
            <React.Fragment key={school.id}>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography variant='subtitle2' component='h4'>
                    {school.name}
                  </Typography>
                </TableCell>
              </TableRow>
              {school.buildings.map(building => (
                <React.Fragment key={building.id}>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant='subtitle2' component='h4' color={'secondary'}>
                        {building.name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {building.benefits.map((benefit, index) => (
                    <ScorecardTableBenefitRow key={`pillar-${pillar.id}-school-${school.id}-building-${building.id}-benefit-${benefit.id}-campaign-${benefit.campaignId}`} benefit={benefit} buildingName={building.name} stripe={index % 2 !== 0} />
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}