import { api } from "../api";
const TAG = 'GradeLevels';

export const gradeLevelsApi = api.injectEndpoints({
    tagTypes: ['GradeLevels'],
    endpoints: builder => ({
        getGradeLevels: builder.query({
            query: () => '/lookup/grade-levels',
            providesTags: [TAG]
        })
    })
})

export const {
    useGetGradeLevelsQuery
} = gradeLevelsApi