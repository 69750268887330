import { useState } from 'react';
import { Box, Stack, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close, Download } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useBulkImportUsersMutation } from '../UserManagement/userManagementSlice';
import { API_BASE_URL } from '../../../utility/url';
import { useBulkImportSchoolsMutation } from '../SchoolManagement/schoolManagementSlice';
import { capitalizeFirstLetter } from '../../../utility/string';

const templateLinks = {
    user: {
        csv: `${API_BASE_URL}/templates/user_data.csv`,
        excel: `${API_BASE_URL}/templates/user_data.xlsx`
    },
    school: {
        csv: `${API_BASE_URL}/templates/school_data.csv`,
        excel: `${API_BASE_URL}/templates/school_data.xlsx`
    }
};

function BulkImportDialog({ isOpen, close, type }) {
    const [file, setFile] = useState(null);
    const [apiError, setApiError] = useState('');
    const [apiErrors, setApiErrors] = useState([]);

    const [bulkImportUsers, { isLoading: isImportingUsers }] = useBulkImportUsersMutation();
    const [bulkImportSchools, { isLoading: isImportingSchools }] = useBulkImportSchoolsMutation();

    const isLoading = isImportingUsers || isImportingSchools;

    function reset() {
        setFile(null);
        setApiError('');
        setApiErrors([]);
    }

    function handleSubmit() {
        if (!file) {
            setApiError('Please select a file to import');
            return;
        } else if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.csv')) {
            setApiError('Invalid file type. Please select a .xlsx or .csv file.');
            return;
        }

        const formData = new FormData();
        formData.append('request', file);

        const handleResponse = (response) => {
            if (response?.error) {
                throw new Error("An error occurred during the bulk import");
            }

            if (response?.messages) {
                setApiError('');
                setApiErrors(response?.messages);
                return false;
            }

            if (response?.message) {
                setApiErrors([]);
                setApiError(response?.message);
                return false;
            }

            toast.success(`Successfully imported ${type}s`);
            return true;
        };

        const handleError = (e) => {
            setApiError(e.message);
        };

        const post = type === 'user' ? bulkImportUsers : bulkImportSchools;

        post(formData)
            .unwrap()
            .then(response => {
                if (handleResponse(response)) {
                    close();
                    reset();
                }
            })
            .catch(handleError);
    }

    return (
        <Dialog open={isOpen} onClose={() => { close(); reset(); }} fullWidth maxWidth='sm'>
            <DialogTitle>
                <Box>
                    Bulk {capitalizeFirstLetter(type)} Import
                </Box>
                <IconButton
                    aria-label='close'
                    onClick={() => { close(); reset(); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Stack spacing={2}>
                    <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                        <Button
                            variant='text'
                            color='secondary'
                            size='small'
                            startIcon={<Download />}
                            href={templateLinks[type].csv}
                            download
                        >
                            CSV Template
                        </Button>
                        <Button
                            variant='text'
                            color='primary'
                            size='small'
                            startIcon={<Download />}
                            href={templateLinks[type].excel}
                            download
                        >
                            Excel Template
                        </Button>
                    </Box>
                    <TextField
                        id='file-upload'
                        variant='outlined'
                        inputProps={{ accept: '.xlsx,.csv' }}
                        type='file'
                        disabled={isLoading}
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </Stack>
                <Box sx={{ color: 'red' }}>{apiError}</Box>
                <Box sx={{ color: 'red' }}>{apiErrors.length ? apiErrors.map(x => <Box key={x}>{x}</Box>) : null}</Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', p: 3, gap: 2 }}>
                <Button onClick={() => { close(); reset(); }} disabled={isLoading} variant='contained' fullWidth>Cancel</Button>
                <LoadingButton onClick={handleSubmit} loading={isLoading} variant='contained' color='secondary' fullWidth>Submit</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default BulkImportDialog;