import { Stack } from "@mui/material";
import OverallEngagementWidget from "./Widgets/OverallEngagementWidget";
import ActiveEngagementWidget from "./Widgets/ActiveEngagementWidget";
import { useGetSurveyEngagementQuery } from "./surveysSlice";

export default function SurveyWidgets() {
    const { data: surveyEngagement, isLoading } = useGetSurveyEngagementQuery();

    return (
        <Stack spacing={2} sx={{ pt: 1 }}>
            <OverallEngagementWidget surveyEngagement={surveyEngagement} isLoading={isLoading} />
            <ActiveEngagementWidget surveyEngagement={surveyEngagement} isLoading={isLoading} />
        </Stack>
    )
}