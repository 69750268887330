import { useLocation, Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "../../utility";
import { useGetSessionQuery } from '../../app/slices/sessionSlice';
import Unauthorized from "./Unauthorized";

function RequireAuth({ children, allowedRoles }) {
    const location = useLocation();
    const { data: session, isError: isAuthError, isLoading } = useGetSessionQuery();

    // check for authenticated session
    if (!isLoggedIn() || isAuthError)
        return <Navigate to="/login" state={{ from: location, isSessionStale: true }} replace />;

    // check for roles
    if (allowedRoles && allowedRoles.length > 0) {
        if (isLoading)
            return <Outlet />;

        if (!session?.roles.some(r => allowedRoles.includes(r.role.id)))
            return <Unauthorized />;

        return <Outlet />;
    }

    return children;
}

export default RequireAuth;