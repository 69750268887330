import { useState, useEffect } from 'react';
import { Box, TextField, Typography, IconButton, Slider, Autocomplete, Divider, Grid, Stack, Tooltip, FormHelperText, Breadcrumbs } from '@mui/material';
import { Delete, ContentCopy, Info } from '@mui/icons-material';
import dayjs from 'dayjs';
import { calculateGrowth } from '../../../../utility/math';
import GroupedSchoolBuildingAutocomplete from '../../../Common/FormElements/GroupedSchoolBuildingAutocomplete';
import BorderCard from '../../../Common/Containers/BorderCard';

const CampaignBenefitGoal = ({ goal, benefitGoals, index, campaignName, onUpdateGoal, onCopyGoal, onRemoveGoal, goalErrors, setGoalErrors, pillarBenefits, districtCampaigns, districtSchools, campaignStartDate }) => {
    const [basisCampaign, setBasisCampaign] = useState(null);
    const [availableCampaigns, setAvailableCampaigns] = useState([])
    const [availableSchools, setAvailableSchools] = useState(districtSchools)
    const [growth, setGrowth] = useState(null);
    const [target, setTarget] = useState(goal.target);
    const [tempTarget, setTempTarget] = useState(50);
    const [noteError, setNoteError] = useState(null);

    const benefitName = (() => {
        let benefitName = '';
        pillarBenefits.forEach(pillar => {
            const benefit = pillar.benefits.find(benefit => benefit.id === goal.benefitId);
            if (benefit) {
                benefitName = benefit.name;
            }
        });
        return benefitName;
    })();

    const pillarName = pillarBenefits?.find(pillar => pillar.id === goal.pillarId)?.name || '';

    // Update basisCampaign when goal.basisCampaignId changes
    useEffect(() => {
        const updatedBasisCampaign =
            districtCampaigns?.find(campaign => campaign.id === goal.basisCampaignId) || null;
        setBasisCampaign(updatedBasisCampaign);

        // Update tempTarget and growth
        if (updatedBasisCampaign?.score) {
            setTempTarget(updatedBasisCampaign.score);
            if (target) {
                setGrowth(calculateGrowth(target, updatedBasisCampaign.score));
            }
        } else {
            setTempTarget(50);
            setGrowth(null);
        }
    }, [districtCampaigns, goal.basisCampaignId, target]);

    // Recalculate growth when target or basisCampaign.score changes
    useEffect(() => {
        if (basisCampaign?.score && target) {
            setGrowth(calculateGrowth(target, basisCampaign.score));
        } else {
            setGrowth(null);
        }
    }, [target, basisCampaign]);

    // limit campaign selection based pillars and benefits selected and the end date of the campaigns.
    useEffect(() => {
        setAvailableCampaigns(districtCampaigns.filter(dc => {
            const pillarFound = dc.pillarIds && dc.pillarIds.indexOf(goal.pillarId) > -1;
            const benefitFound = dc.benefitIds && dc.benefitIds.indexOf(goal.benefitId) > -1;
            const validStartDate = dayjs(dc.endDate).isBefore(dayjs(campaignStartDate), "day")
            return pillarFound && benefitFound && validStartDate;
        }))
    }, [districtCampaigns, goal, campaignStartDate])

    //limit the allowed school selections based on the combination of campaign, pillar, and benefit.
    useEffect(() => {
        let disallowedSchools = [];
        benefitGoals.forEach((testGoal, testIndex) => {
            const isCurrentGoal = testIndex === index;
            const benefitMatch = testGoal.benefitId === goal.benefitId;
            const pillarMatch = testGoal.pillarId === goal.pillarId;
            const campaignMatch = testGoal.basisCampaignId === goal.basisCampaignId;

            if (!isCurrentGoal && campaignMatch && benefitMatch && pillarMatch) {
                disallowedSchools = [...disallowedSchools, ...testGoal.schoolBuildingIds];
            }
        });

        let allowedSchools = JSON.parse(JSON.stringify(districtSchools));
        allowedSchools.forEach(school => {
            for (let i = school.schoolBuildings.length - 1; i >= 0; i--) {
                if (disallowedSchools.includes(school.schoolBuildings[i].id)) {
                    school.schoolBuildings.splice(i, 1);
                }
            }
        });
        setAvailableSchools(allowedSchools);
    }, [benefitGoals, goal, districtSchools, index])

    const handleBasisCampaignChange = campaign => {
        if (!campaign) {
            onUpdateGoal(index, { ...goal, basisCampaignId: null });
            return;
        }
        onUpdateGoal(index, { ...goal, basisCampaignId: campaign.id });
    };

    const handleTargetChange = newValue => {
        setTarget(newValue);
        setTempTarget(null);
    };

    const handleTargetChangeCommitted = newValue => {
        onUpdateGoal(index, { ...goal, target: newValue });
        setGoalErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (newErrors[index]) {
                newErrors[index].target = null;
            }
            return newErrors;
        });
    };

    const handleBlurValidation = (newValues, field) => {
        if (newValues && goalErrors[index] && goalErrors[index][field]) {
            setGoalErrors(prevErrors => {
                const newErrors = { ...prevErrors };
                newErrors[index][field] = null;
                return newErrors;
            });
        }
    };

    const handleSchoolChange = newIds => {
        onUpdateGoal(index, { ...goal, schoolBuildingIds: newIds });
        setGoalErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (newErrors[index]) {
                newErrors[index].school = null;
            }
            return newErrors;
        });
    };

    const handleNotesBlur = newValue => {
        onUpdateGoal(index, { ...goal, notes: newValue });
    };

    const handleNotesChange = newValue => {
        if (newValue.length === 100) {
            setNoteError('Notes are limited to 100 characters');
            return;
        } else if (newValue.length > 100) {
            const limitedString = newValue.slice(0, 99);
            setNoteError('Notes are limited to 100 characters');
            onUpdateGoal(index, { ...goal, notes: limitedString });
            return;
        } else {
            onUpdateGoal(index, { ...goal, notes: newValue });
            setNoteError(null);
        }
    };

    return (
        <BorderCard>
            <Box sx={{ pl: 2, py: 1 }}>
                <Breadcrumbs separator=" / ">
                    <Typography variant='caption'>{campaignName}</Typography>
                    <Typography variant='caption'>{pillarName}</Typography>
                    <Typography variant='caption' color='black'>
                        {benefitName}
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ px: 3, pt: 1, pb: 3 }}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            mt: 2,
                            gap: '10px',
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '50%' }}>
                            <Autocomplete
                                fullWidth
                                size='small'
                                options={availableCampaigns || []}
                                getOptionLabel={option => option.name || ''}
                                value={basisCampaign || null}
                                onChange={(_e, newValue) => handleBasisCampaignChange(newValue)}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={params => <TextField {...params} label="Basis Campaign" />}
                                sx={{
                                    width: '100%',
                                    '& .MuiInputBase-root': {
                                        backgroundColor: 'white',
                                    },
                                }}
                            />
                            <Tooltip
                                sx={{ mr: 4 }}
                                title="Previous Campaigns that included this benefit. Only campaigns that have ended prior to the new campaign start date can be selected as a basis campaign."
                                arrow
                                placement='top'
                            >
                                <Info />
                            </Tooltip>
                        </Box>
                        <Box sx={{ width: '50%' }}>
                            <GroupedSchoolBuildingAutocomplete
                                required
                                fullWidth
                                schools={availableSchools}
                                schoolBuildingIds={goal.schoolBuildingIds}
                                setSchoolBuildingIds={handleSchoolChange}
                                allowSchoolGroupSelect
                                onBlur={() => handleBlurValidation(goal.schoolBuildingIds, 'school')}
                                tagLimit={1}
                            />
                            <FormHelperText error>
                                {goalErrors[index] && goalErrors[index].school}
                            </FormHelperText>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1} sx={{ mr: 7.5 }}>
                        {basisCampaign?.score && (
                            <>
                                <Typography variant='body2'>
                                    <Tooltip
                                        title="Measure of score for most recent completion of benefit"
                                        arrow
                                        placement='left'
                                    >
                                        <span>Score: {basisCampaign.score}%</span>
                                    </Tooltip>
                                </Typography>
                                <Typography variant='body2'>
                                    <Tooltip
                                        title="Measure of growth for most recent completion of benefit"
                                        arrow
                                        placement='left'
                                    >
                                        <span>
                                            Growth:{' '}
                                            <span style={{ color: basisCampaign.growth >= 0 ? 'green' : 'red' }}>
                                                {basisCampaign.growth}%
                                            </span>
                                        </span>
                                    </Tooltip>
                                </Typography>
                                <Typography variant='body2'>
                                    <Tooltip
                                        title="Duration of most recent completion of benefit"
                                        arrow
                                        placement='left'
                                    >
                                        <span>Duration: {basisCampaign.dateRange}</span>
                                    </Tooltip>
                                </Typography>
                            </>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <Stack spacing={1}>
                        <Typography variant='body2'>
                            <Tooltip
                                title="Target percentage for new benefit"
                                arrow
                                placement='right'
                            >
                                <span>Target: {target}% *</span>
                            </Tooltip>
                        </Typography>
                        <Slider
                            id={`target-slider-${goal.benefitId}`}
                            color='primary'
                            step={5}
                            marks
                            min={5}
                            max={100}
                            value={target ?? tempTarget ?? 0}
                            onChange={(_e, newValue) => handleTargetChange(newValue)}
                            onChangeCommitted={(_e, newValue) => handleTargetChangeCommitted(newValue)}
                        />
                        {goalErrors[index]?.target && (
                            <FormHelperText id={`target-error-${index}`} error>
                                {goalErrors[index].target}
                            </FormHelperText>
                        )}
                        {growth !== null && (
                            <Typography variant='body2'>
                                <Tooltip
                                    title="Target growth for new benefit"
                                    arrow
                                    placement='right'
                                >
                                    <span>
                                        Growth:{' '}
                                        <span style={{ color: growth >= 0 ? 'green' : 'red' }}>
                                            {growth}%
                                        </span>
                                    </span>
                                </Tooltip>
                            </Typography>
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            label="Notes"
                            multiline
                            rows={3}
                            value={goal.notes || ""}
                            onChange={e => handleNotesChange(e.target.value)}
                            onBlur={e => handleNotesBlur(e.target.value)}
                            variant='outlined'
                            fullWidth
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                },
                            }}
                            error={!!noteError}
                            helperText={noteError}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <Box
                sx={{ py: 1.5, px: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}
            >
                <IconButton onClick={() => onCopyGoal(index)}>
                    <ContentCopy color='secondary' />
                </IconButton>
                <IconButton onClick={() => onRemoveGoal(index)}>
                    <Delete color='error' />
                </IconButton>
            </Box>
        </BorderCard>
    );
};

export default CampaignBenefitGoal;