import { Button, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function VisibilityToggleButton({ onFilterChange, filters, text, property }) {
    return (
        <Button
            size='large'
            disableElevation
            variant={'outlined'}
            fullWidth
            onClick={() => onFilterChange(property, !filters[property])}
            sx={{
                borderColor: theme => theme.palette.grey[500],
                justifyContent: 'space-between',
                height: '100%',
            }}
            endIcon={!filters[property] ?
                <Visibility sx={{ color: 'grey' }} />
                :
                <VisibilityOff sx={{ color: 'grey' }} />}
        >
            <Typography
                variant='body2'
                sx={{
                    whiteSpace: 'nowrap', color: (theme) => theme.palette.grey[600],
                    ml: '-8px',
                }}
            >
                {text || 'Removed'}
            </Typography>
        </Button>
    );
}