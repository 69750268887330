import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import { Box, Toolbar, Typography, IconButton, Tooltip } from '@mui/material';
import { AccountCircle, Logout } from '@mui/icons-material';
import { useLogoutMutation } from '../../../app/slices/authenticationSlice';
import { logout } from '../../../app/api';
import ManageProfileDialog from '../../ManageProfile/ManageProfileDialog';

export default function ExternalTopbar({ user }) {
    const [isManageProfileOpen, setIsManageProfileOpen] = useState(false);

    const [logoutPost] = useLogoutMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            navigate('/login');
            dispatch(logout());
            logoutPost();
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: '#444444',
                    height: { xs: 55, sm: 65 },
                }}
            >
                <Toolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                        >
                            PillarXP
                        </Typography>

                    </Box>
                    <Box sx={{
                        ml: 'auto',
                        display: 'flex'
                    }}>
                        <Tooltip title='View/Edit Profile'>
                            <IconButton
                                sx={{
                                    color: '#ffffff'
                                }}
                                onClick={() => setIsManageProfileOpen(true)}
                            >
                                <AccountCircle />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Logout of Pillar XP'>
                            <IconButton
                                sx={{
                                    color: '#ffffff'
                                }}
                                onClick={handleLogout}
                            >
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <ManageProfileDialog open={isManageProfileOpen} onClose={() => setIsManageProfileOpen(false)} user={user} />
        </>
    );
}