import { api } from "../../../app/api";

const TAG = 'SurveyWizard';

export const surveyWizardApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: builder => ({
        getCampaignSchools: builder.query({
            query: (campaignId) => `/school/campaign-school-buildings/${campaignId}`,
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
    })
})

export const {
    useGetCampaignSchoolsQuery
} = surveyWizardApi;