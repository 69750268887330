import { useState } from 'react';
import { toast } from "react-toastify";
import { Card, Stack, TextField, Typography, CircularProgress, InputAdornment } from '@mui/material';
import { useLazyIsTitleInUseQuery } from './surveyTemplateWizardSlice';
import WizardButtonGroup from '../../Common/Buttons/WizardButtonGroup';

export default function StepOne({ title, setTitle, isEdit, originalTitle, nextStep, cancel, isLoading }) {
    const [formErrors, setFormErrors] = useState({});

    const [isTitleInUse] = useLazyIsTitleInUseQuery();

    const reset = () => {
        setTitle('');
        setFormErrors({});
    };

    const handleCancel = () => {
        reset();
        cancel();
    };

    const isFormValid = () => {
        const errors = {};

        errors['title'] = !title ? "Field is required" : null;

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    };

    const validate = (e) => {
        setFormErrors(prev => ({
            ...prev,
            [e.target.id]: !e.target.value.trim() ? "Field is required" : null
        }));
    };

    const toNextStep = async () => {
        if (!isFormValid())
            return;

        if (title !== originalTitle) {
            try {
                const errors = { ...formErrors };
                const response = await isTitleInUse(title).unwrap();

                if (response?.error)
                    throw new Error("An error occurred when checking for duplicate titles.");

                if (response.isDuplicate) {
                    errors['title'] = "This name is already in use.";
                    setFormErrors(errors);
                    return;
                }
            } catch (error) {
                toast.error(error);
                return;
            }
        }
        nextStep();
    };

    return (
        <Card
            sx={{
                flexBasis: '100%',
                maxWidth: '340px',
                padding: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <Stack spacing={3}>
                <Typography>
                    <span style={{ fontWeight: 'bold' }}>Let&apos;s Get Started. </span>
                    Please name your survey template. Templates are used to send individual surveys to users.
                </Typography>
                <TextField
                    id='survey-template-name'
                    label="Name"
                    sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: 'white'
                        }
                    }}
                    variant='outlined'
                    type='text'
                    size='small'
                    fullWidth
                    required
                    value={title}
                    onBlur={validate}
                    error={!!formErrors['title']}
                    helperText={formErrors['title']}
                    onChange={e => setTitle(e.target.value)}
                    InputProps={{
                        endAdornment: isEdit && isLoading && !title ? (
                            <InputAdornment position='end'>
                                <CircularProgress size={20} />
                            </InputAdornment>
                        ) : null,
                    }}
                />
                <WizardButtonGroup
                    fullWidth
                    backText="Cancel"
                    backClick={handleCancel}
                    forwardClick={toNextStep}
                />
            </Stack>
        </Card>
    );
}