import { useState } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { SurveyQuestionTypeEnum } from '../../../../utility/enums';

export default function QuestionTypeForm({ questionTypeId, ordinal, surveyQuestionOptions, formErrors, setFormErrors, onOptionsChange }) {
    const [option, setOption] = useState('');

    const handleAddOption = () => {
        if (!option || !option.trim()) {
            setFormErrors(prev => ({ ...prev, 'optionsArray': 'Option text is required' }));
        } else {
            const newOptions = [...surveyQuestionOptions, { title: option.trim(), ordinal: surveyQuestionOptions.length + 1 }];
            onOptionsChange(newOptions);
            setOption('');
            setFormErrors(prev => ({ ...prev, 'optionsArray': null }));
        }
    };

    const handleOptionBlur = () => {
        if (option.trim()) {
            setFormErrors(prev => ({ ...prev, 'optionsArray': null }));
        }
    };

    const handleDeleteOption = (index) => {
        const newOptions = surveyQuestionOptions.filter((_, i) => i !== index);
        onOptionsChange(newOptions);
    };

    switch (questionTypeId) {
        case SurveyQuestionTypeEnum.SHORT_ANSWER:
            return (
                <Box sx={{ pr: '55px' }}>
                    <TextField
                        id={`${ordinal}-disabled-text-field`}
                        type="text"
                        size="small"
                        fullWidth
                        disabled
                        value={''}
                    />
                </Box>
            );
        case SurveyQuestionTypeEnum.YES_OR_NO:
            return (
                <RadioGroup name="radio-buttons-group" row>
                    <FormControlLabel disabled value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel disabled value="0" control={<Radio />} label="No" />
                </RadioGroup>
            );
        case SurveyQuestionTypeEnum.CHECKBOX_CHOICES:
        case SurveyQuestionTypeEnum.MULTIPLE_CHOICE:
            return (
                <>
                    <RadioGroup name="radio-buttons-group">
                        {surveyQuestionOptions.map((uo, i) => (
                            <Box
                                key={`${ordinal}-option-entry-${uo.title}-${i}-${uo?.id ?? 0}`}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <FormControlLabel
                                    disabled
                                    value={i}
                                    control={questionTypeId === SurveyQuestionTypeEnum.MULTIPLE_CHOICE ? <Radio /> : <Checkbox />}
                                    label={uo.title}
                                />
                                <IconButton size='small' color='error' onClick={() => handleDeleteOption(i)}>
                                    <Close />
                                </IconButton>
                            </Box>
                        ))}
                    </RadioGroup>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '15px' }}>
                        <TextField
                            id={`${ordinal}-option-input`}
                            label="Add option"
                            variant='outlined'
                            type='text'
                            size='small'
                            fullWidth
                            required
                            value={option}
                            onBlur={handleOptionBlur}
                            error={!!formErrors?.['optionsArray']}
                            helperText={formErrors?.['optionsArray']}
                            onChange={e => setOption(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddOption();
                                }
                            }}
                        />
                        <IconButton
                            color='secondary'
                            onClick={handleAddOption}
                        >
                            <Add />
                        </IconButton>
                    </Box>
                </>
            );
        default:
            return null;
    }
}