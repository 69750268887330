import { api } from '../../app/api';
const TAG = 'IntegratorSettings';

export const integratorSettingsApi = api.injectEndpoints({
    tagTypes: [TAG, 'DistrictSpecific'],
    endpoints: (builder) => ({
        getUserDistricts: builder.query({
            query: (userId) => `user/${userId}/districts`,
            providesTags: () => [{ type: TAG }]
        }),
        switchIntegratorDistrict: builder.mutation({
            query: (districtId) => ({
                url: `switch-district/${districtId}`,
                method: 'POST',
                body: {}
            }),
            invalidatesTags: [{ type: 'DistrictSpecific' }],
        }),
    }),
})

export const {
    useSwitchIntegratorDistrictMutation,
    useGetUserDistrictsQuery
} = integratorSettingsApi;