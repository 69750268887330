import { useEffect, useState } from 'react';
import { Box, Card, CardContent, LinearProgress, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import ScorecardProgressBar from './ScorecardProgressBar';
import { decimalToPercentage } from './scorecardUtils';
import styled from 'styled-components';

const ProgressBox = styled(Box)`
    margin-top: .5rem;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: column;
`;

export default function GrowthCampaignCard({ campaign }) {

    const [trend, setTrend] = useState('');
    const [endDateFormatted, setEndDateFormatted] = useState('');
    const [daysRemaining, setDaysRemaining] = useState(0);
    const [timelinePercentage, setTimelinePercentage] = useState(0);
    
    useEffect(() => {
        calcTimelinePercentage(campaign.startDate, campaign.endDate);
        determineTrend(campaign);
        const endDate = dayjs(campaign.endDate);
        const currentDate = dayjs();
        const remainingDays = endDate.diff(currentDate, 'day');
        setDaysRemaining(remainingDays);
        setEndDateFormatted(endDate.format('MM/DD/YY'));
    }, [campaign]);
    
    const determineTrend = (campaign) => {
        if (campaign.growthPercentage > 0) {
            setTrend('up');
        } else if (campaign.growthPercentage < 0) {
            setTrend('down');
        } else {
            setTrend('flat');
        }
    };
    
    const calcTimelinePercentage = (start, end) => {
        const startDate = dayjs(start);
        const endDate = dayjs(end);
        const currentDate = dayjs();
        const totalDays = endDate.diff(startDate, 'day');
        const daysPassed = currentDate.diff(startDate, 'day');
        const percentage = (daysPassed / totalDays) * 100;
        setTimelinePercentage(Math.min(percentage, 100));
    };
    
    const daysRemainingString = () => {
        if (daysRemaining <= 0) {
            return `Complete`;
        } else if (daysRemaining === 1) {
            return `${daysRemaining} day remaining`;
        } else {
            return `${daysRemaining} days remaining`;
        }
    };

    return (
        <Card variant='outlined'>
            <CardContent>
                <Box>
                    <Typography variant='subtitle2' component='h4' color={'secondary'} sx={{ mb: 2 }}>
                        {campaign.name}
                    </Typography>
                </Box>
                <Box>
                    <Tooltip
                        title={daysRemaining <= 0 ? `${campaign.name} was completed on ${endDateFormatted}` : `There is currently ${daysRemainingString()} in the ${campaign.name} campaign.`}
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -12],
                                        },
                                    },
                                ],
                            },
                        }}>
                        <ProgressBox>
                            <Typography variant='caption' component='h5' sx={{ fontWeight: 'bold' }}>
                                Timeline
                            </Typography>
                            <LinearProgress
                                variant='determinate'
                                color='secondary'
                                value={timelinePercentage}
                            />
                            <Typography variant='caption' component='p' sx={{ alignSelf: 'flex-end' }}>
                                {daysRemaining <= 0 ? `Campaign completed - ${endDateFormatted}` : daysRemainingString()}
                            </Typography>
                        </ProgressBox>
                    </Tooltip>
                    <Tooltip
                        title={`The ${campaign.name} campaign is currently on track to reach its ${decimalToPercentage(campaign.targetGrowthPercentage)}% scoring goal.`}
                        arrow
                        slotProps={{
                            popper: {
                                modifiers: [
                                    {
                                        name: 'offset',
                                        options: {
                                            offset: [0, -12],
                                        },
                                    },
                                ],
                            },
                        }}>
                        <ProgressBox>
                            <Typography variant='caption' component='h5' sx={{ fontWeight: 'bold' }}>
                                Growth Progression
                            </Typography>
                            <ScorecardProgressBar
                                target={campaign.targetGrowthPercentage}
                                base={campaign.baseGrowthPercentage}
                                score={campaign.growthPercentage}
                                growth={campaign.growthPercentage}
                                trend={trend}
                            />
                        </ProgressBox>
                    </Tooltip>
                    <Box>
                        <Typography variant='caption' component='h5' sx={{ fontWeight: 'bold' }}>
                            Objective:
                        </Typography>
                        <Typography variant='caption' component='p'>
                            {campaign.objective}
                        </Typography>
                    </Box>
                </Box>
            </CardContent >
        </Card >
    );
}