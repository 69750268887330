import { Typography, Dialog, DialogContent, Stack, Divider, Link, Skeleton, DialogTitle, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import PillarXpLogo from '../../images/PillarXP-Logo-Alt.svg';
import { useGetSystemContactByDistrictIdQuery } from '../../app/slices/systemContactSlice';
import styled from 'styled-components';

const systemDefault = 'Please contact your System Admin';

const DialogImage = styled.img`
  margin: 1em; 
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ContactSchoolDistrictModal({ open, onClose, districtId }) {

    const { data, isLoading, isError } = useGetSystemContactByDistrictIdQuery(districtId, {
        skip: !open // Only fetch when the modal is open
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
            <DialogTitle>
                <DialogImage src={PillarXpLogo} alt='logo' />
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ pb: 5 }}>
                <Stack spacing={2}>
                    {!isError && data ?
                        <>
                            <Box>
                                <Typography variant="body1" align='center' sx={{ textDecoration: 'underline', pt: .5, pb: .5 }}>
                                    School District
                                </Typography>
                                <Typography variant='body1' align='center'>
                                    {isLoading ? <Skeleton /> : data.schoolDistrictName}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1" align='center' sx={{ textDecoration: 'underline', pb: .5 }}>
                                    Primary Contact
                                </Typography>
                                <Typography variant='body1' align='center'>
                                    {isLoading ? <Skeleton /> : `${data.firstName} ${data.lastName}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    align="center"
                                    sx={{ textDecoration: 'underline', pb: 0.5 }}
                                >
                                    Address
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {isLoading ? (
                                        <>
                                            <Skeleton />
                                            <Skeleton />
                                        </>
                                    ) : (
                                        <>
                                            {data.address1}
                                            <br />
                                            {`${data.city}, ${data.state} ${data.zipCode}`}
                                        </>
                                    )}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body1" align='center' sx={{ textDecoration: 'underline', pb: .5 }}>
                                    Contact Email
                                </Typography>
                                <Link
                                    href={`mailto:${data.emailAddress}`}
                                    variant='body1'
                                    underline='hover'
                                    color={'secondary'}
                                    sx={{ display: 'block', textAlign: 'center' }}
                                >
                                    {isLoading ? <Skeleton /> : data.emailAddress}
                                </Link>
                            </Box>
                            <Box>
                                <Typography variant="body1" align='center' sx={{ textDecoration: 'underline', pb: .5 }}>
                                    Main Phone Number
                                </Typography>
                                <Typography variant='body1' align='center'>
                                    {isLoading ? <Skeleton /> : data.phoneNumber}
                                </Typography>
                            </Box>
                        </>
                        :
                        <Typography variant='body1' align='center'>
                            {systemDefault}
                        </Typography>
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    )
}