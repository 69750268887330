import { useState, useEffect, useMemo } from "react";
import { Autocomplete, Box, Card, FormHelperText, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from "dayjs";
import { RecurrenceEnum, MonthlyRecurrenceEnum } from "../../../../utility/enums";
import { weekdays } from "../../../../utility/calendar"
import { dayOfMonthOptions, generateScheduleStatement, monthlyOptions, parseCronValue, recurrenceOptions } from "../../../../utility/cron";

export default function SchedulingForm({ campaignStartDate, campaignEndDate, form, setForm, formErrors, setFormErrors }) {
    const [showDateWarning, setShowDateWarning] = useState(false);

    const daysBorderColor = formErrors['selectedWeekdays'] ? 'crimson' : '#9F9F9F';

    useEffect(() => {
        if (form.cronSchedule) {
            const { recurrencePattern, monthType, dayOfMonth, weekDays } = parseCronValue(form.cronSchedule);

            setForm(prevState => ({
                ...prevState,
                recurrencePattern: recurrencePattern || prevState.recurrencePattern,
                monthType: monthType || prevState.monthType,
                dayOfMonth: dayOfMonth || prevState.dayOfMonth,
                selectedWeekdays: weekDays || prevState.selectedWeekdays,
            }));
        }
    }, [form.cronSchedule, setForm]);

    const memoizedScheduleStatement = useMemo(() => {
        return generateScheduleStatement(
            form.cronSchedule,
            form.recurrencePattern,
            form.selectedWeekdays,
            form.startDate,
            form.endDate
        );
    }, [form.cronSchedule, form.recurrencePattern, form.selectedWeekdays, form.startDate, form.endDate]);

    const handleRecurrenceChange = (newValue) => {
        let updatedForm = { ...form, recurrencePattern: newValue || null };

        if (newValue) {
            let cronValue = form.cronSchedule;
            let dayValues = "";

            if (newValue.id === RecurrenceEnum.MONTHLY) {
                if (!form.monthType) {
                    cronValue = "0 0 * * *";
                } else {
                    dayValues = form.selectedWeekdays.sort().map(x => `${x}#1`).join(",");
                    cronValue = `0 0 * * ${dayValues || "*"}`;
                }
            } else if (newValue.id === RecurrenceEnum.BI_WEEKLY) {
                // Note that "B" is not a valid cron value. But is needed to denote bi-weekly.
                dayValues = form.selectedWeekdays.sort().map(x => x + "B").join(",");
                cronValue = `0 0 * * ${dayValues || "*"}`;
            } else if (newValue.id === RecurrenceEnum.WEEKLY) {
                dayValues = form.selectedWeekdays.sort().join(",");
                cronValue = `0 0 * * ${dayValues || "*"}`;
            }

            updatedForm = {
                ...updatedForm,
                cronSchedule: cronValue
            };
        } else {
            // If form field value is cleared, we'll also clear the cronSchedule
            updatedForm = {
                ...updatedForm,
                cronSchedule: null
            };
        }

        setForm(updatedForm);
        setFormErrors(prevErrors => ({
            ...prevErrors,
            recurrencePattern: null,
            monthType: null,
            cronSchedule: null
        }));
    };

    const handleWeekDayChange = (newValues) => {
        let updatedForm = { ...form, selectedWeekdays: newValues };

        if (newValues && newValues.length > 0) {
            let dayValues = "";
            if (form.recurrencePattern.id === RecurrenceEnum.MONTHLY) {
                dayValues = newValues.sort().map(x => `${x}#1`).join(",");
            } else if (form.recurrencePattern.id === RecurrenceEnum.BI_WEEKLY) {
                // Note that "B" is not a valid cron value. But is needed to denote bi-weekly.
                dayValues = newValues.sort().map(x => x + "B").join(",");
            } else if (form.recurrencePattern.id === RecurrenceEnum.WEEKLY) {
                dayValues = newValues.sort().join(",");
            }
            updatedForm.cronSchedule = `0 0 * * ${dayValues || "*"}`;
        } else {
            updatedForm.cronSchedule = "";
        }

        setForm(updatedForm);
        setFormErrors(prevErrors => ({ ...prevErrors, selectedWeekdays: null }));
    };

    const handleMonthTypeChange = (newValue) => {
        let updatedForm = { ...form, monthType: newValue || null };

        if (newValue) {
            let cronValue;
            //set cronValue
            if (newValue.id === MonthlyRecurrenceEnum.ON_A_FIRST_WEEKDAY) {
                const dayValues = form.selectedWeekdays.sort().map(x => `${x}#1`).join(",");
                cronValue = `0 0 * * ${dayValues || "*"}`;
            } else {
                cronValue = `0 0 ${form.dayOfMonth?.value || "*"} * *`;
            }
            updatedForm.cronSchedule = cronValue;
        } else {
            updatedForm.cronSchedule = "";
        }

        setForm(updatedForm);
        setFormErrors(prevErrors => ({
            ...prevErrors,
            monthType: null,
            dayOfMonth: null,
            selectedWeekdays: null
        }));
    };

    const handleDayOfMonthChange = (newValue) => {
        const updatedForm = {
            ...form,
            dayOfMonth: newValue,
            cronSchedule: `0 0 ${newValue?.value || "*"} * *`
        };

        setForm(updatedForm);
        setShowDateWarning(newValue && newValue.value > 28);
        setFormErrors(prevErrors => ({ ...prevErrors, dayOfMonth: null }));
    };

    const handleBlurValidation = (newValues, field) => {
        if (newValues && formErrors[field]) {
            setFormErrors({ ...formErrors, [field]: null });
        }
    };

    return (
        <Card
            sx={{
                flexBasis: '75%',
                padding: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <Typography sx={{ mb: 3 }}>
                <span>
                    <span style={{ fontWeight: 'bold' }}>Enter Scheduling Information. </span>
                    The information entered will determine the schedule that surveys are automatically sent to recipients.
                </span>
            </Typography>
            <Stack spacing={2}>
                <Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Autocomplete
                            id='add-recurrence-pattern'
                            size='small'
                            fullWidth
                            options={recurrenceOptions || []}
                            getOptionLabel={option => option.value}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            value={form.recurrencePattern || null}
                            onChange={(_e, newValue) => handleRecurrenceChange(newValue)}
                            onBlur={() => handleBlurValidation(form.recurrencePattern, 'recurrencePattern')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required={true}
                                    variant='outlined'
                                    label="Recurrence"
                                    error={!!formErrors['recurrencePattern']}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                }
                            }}
                        />
                        <Tooltip
                            title="Select the recurrence frequency of the survey distribution."
                        >
                            <Info sx={{ length: '20px', width: '20px', alignSelf: 'center' }} />
                        </Tooltip>
                    </Box>
                    <FormHelperText error={!!formErrors['recurrencePattern']}>
                        {formErrors['recurrencePattern']}
                    </FormHelperText>
                </Box>
                {form.recurrencePattern?.id === RecurrenceEnum.MONTHLY && (
                    <Box sx={{ pr: '30px' }}>
                        <Autocomplete
                            id='select-month-type'
                            size='small'
                            fullWidth
                            options={monthlyOptions || []}
                            getOptionLabel={option => option.value}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            value={form.monthType || null}
                            onChange={(_e, newValue) => handleMonthTypeChange(newValue)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    required={true}
                                    label='Scheduled Recurrence'
                                    error={!!formErrors['monthType']}
                                    helperText={formErrors['monthType']}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: 'white',
                                        }
                                    }}
                                />
                            }
                        />
                    </Box>
                )}
                {form.recurrencePattern?.id === RecurrenceEnum.MONTHLY &&
                    form.monthType?.id === MonthlyRecurrenceEnum.ON_A_DAY && (
                        <Box sx={{ pr: '30px' }}>
                            <Autocomplete
                                id='select-day-of-month'
                                size='small'
                                fullWidth
                                options={dayOfMonthOptions || []}
                                getOptionLabel={option => option?.value}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                value={form.dayOfMonth || null}
                                onChange={(_e, newValue) => handleDayOfMonthChange(newValue)}
                                onBlur={() => handleBlurValidation(form.dayOfMonth, 'dayOfMonth')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        required={true}
                                        label="Day of Month"
                                        error={!!formErrors['dayOfMonth']}
                                        helperText={formErrors['dayOfMonth']}
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                backgroundColor: 'white',
                                            }
                                        }}
                                    />
                                }
                            />
                            {showDateWarning &&
                                <FormHelperText sx={{ color: (theme) => theme.palette.secondary.dark }} >
                                    Months with less than {form.dayOfMonth.value} days will be sent on the last day of the month.
                                </FormHelperText>
                            }
                        </Box>
                    )}
                {((form.recurrencePattern?.id === RecurrenceEnum.MONTHLY &&
                    form.monthType?.id === MonthlyRecurrenceEnum.ON_A_FIRST_WEEKDAY) ||
                    [RecurrenceEnum.WEEKLY, RecurrenceEnum.BI_WEEKLY].includes(form.recurrencePattern?.id)) && (
                        <Box sx={{ pr: '30px' }}>
                            <ToggleButtonGroup
                                value={form.selectedWeekdays}
                                onChange={(_e, newValue) => {
                                    handleWeekDayChange(newValue);
                                }}
                                size='small'
                                color='success'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: 'white',
                                }}
                            >
                                {weekdays.map((weekday) => (
                                    <ToggleButton
                                        key={weekday.id}
                                        value={weekday.id}
                                        sx={{
                                            flexGrow: 1,
                                            border: '1px solid',
                                            borderColor: daysBorderColor
                                        }}
                                    >
                                        {weekday.value.slice(0, 3)}
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                            <FormHelperText error={!!formErrors['selectedWeekdays']}>
                                {formErrors['selectedWeekdays']}
                            </FormHelperText>
                        </Box>
                    )}
                <Typography variant="caption">
                    Campaign Dates: {dayjs(campaignStartDate).format('MM/DD/YYYY')} to {dayjs(campaignEndDate).format('MM/DD/YYYY')}
                </Typography>
                <Box sx={{ pr: '30px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            calendars={2}
                            localeText={{ start: 'Start Date', end: 'End Date' }}
                            minDate={(dayjs(campaignStartDate) > dayjs() ? dayjs(campaignStartDate) : dayjs())}
                            maxDate={dayjs(campaignEndDate)}
                            value={[form.startDate, form.endDate]}
                            onChange={(dateRange) => {
                                setForm({ ...form, startDate: dateRange[0], endDate: dateRange[1] })
                                setFormErrors({ ...formErrors, startDate: null, endDate: null, dateRange: null });
                            }}
                            slotProps={{
                                textField: {
                                    required: true,
                                    error: !!formErrors['dateRange'],
                                    helperText: "",
                                    size: 'small',
                                },
                            }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white'
                                }
                            }}
                        />
                    </LocalizationProvider>
                    {formErrors['dateRange'] &&
                        <Box>
                            <FormHelperText error>
                                {formErrors['dateRange']}
                            </FormHelperText>
                        </Box>
                    }
                </Box>
                {!formErrors['dateRange'] && memoizedScheduleStatement &&
                    <Box>
                        <FormHelperText error={formErrors['dateRange']} sx={{ mt: -1 }}>
                            {memoizedScheduleStatement}.
                        </FormHelperText>
                    </Box>
                }
            </Stack>
        </Card>
    );
}