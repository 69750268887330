import { api } from "../api";

const TAG = "SurveyQuestionScoringTypes";
export const surveyQuestionScoringTypesApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: builder => ({
        getSurveyQuestionScoringTypes: builder.query({
            query: () => '/lookup/survey-question-scoring-types',
            providesTags: [TAG]
        })
    })
})

export const {
    useGetSurveyQuestionScoringTypesQuery
} = surveyQuestionScoringTypesApi