import { TextField, Grid, Autocomplete, Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Label from '../Common/FormElements/Label';
import VisibilityToggleButton from '../Common/FormElements/VisibilityToggleButton';
import dayjs from 'dayjs';

export default function CampaignManagementFilters({ filters, setFilters, schools }) {

    const onFilterChange = (name, value) => {
        const f = { ...filters };
        f[name] = value;
        setFilters(f);
    };

    const onDateChange = (prop, date) => {
        const f = { ...filters };
        f.datePicker = {
            ...f.datePicker,
            [prop]: date === null || dayjs.isDayjs(date) ? date?.toDate() : null
        };
        setFilters(f);
    };

    const setCleared = (prop) => {
        onFilterChange('datePicker', { ...filters.datePicker, [prop]: null });
    };

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        id="filter-schools"
                        multiple
                        limitTags={1}
                        size='small'
                        options={schools}
                        onChange={(_, v) => onFilterChange("schoolsFilter", v)}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={<Label text='School' />}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={<Label text='Start Date' />}
                            value={filters.datePicker?.start ? dayjs(filters.datePicker.start) : null}
                            onChange={date => onDateChange('start', date)}
                            slotProps={{ field: { size: 'small', clearable: true, onClear: () => setCleared('start') } }}
                            sx={{ width: '100%' }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={<Label text='End Date' />}
                            value={filters.datePicker?.end ? dayjs(filters.datePicker.end) : null}
                            onChange={date => onDateChange('end', date)}
                            slotProps={{ field: { size: 'small', clearable: true, onClear: () => setCleared('end') } }}
                            sx={{ width: '100%' }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <VisibilityToggleButton text={'Completed'} onFilterChange={onFilterChange} filters={filters} property='hideCompleted' />
                </Grid>
                <Grid item xs={12} sm={6} md={3} />
            </Grid>
        </Box>
    );
}