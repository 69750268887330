import { api } from '../api';
const TAG = 'Notification';

export const notificationApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: () => 'notification',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        getUnreadNotifications: builder.query({
            query: () => 'notification/unread',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        putToggleRead: builder.mutation({
            query: (id) => ({
                url: `notification/toggle-read/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetNotificationsQuery,
    useGetUnreadNotificationsQuery,
    usePutToggleReadMutation
} = notificationApi;