import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import DatagridToolbarTypography from './ButtonTypography/DatagridToolbarTypography';

const CreateNewButton = ({ onClick }) => {
    return (
        <Button
            variant='outlined'
            color='secondary'
            startIcon={<Add />}
            onClick={onClick}
        >
            <DatagridToolbarTypography color='action-light-blue'>
                Create New
            </DatagridToolbarTypography>
        </Button>
    );
};

export default CreateNewButton;