import React from 'react';
import { Grid, Box } from '@mui/material';
import { useGetSystemStatusesQuery } from '../../../app/slices/systemStatusSlice';
import { SystemStatusEnum } from '../../../utility/enums';
import Dropdown from '../../Common/FormElements/Dropdown';
import Label from '../../Common/FormElements/Label';
import ShowRemovedSwitchButton from '../../Common/FormElements/ShowRemovedSwitchButton';
import GroupedSchoolBuildingAutocomplete from '../../Common/FormElements/GroupedSchoolBuildingAutocomplete';

function UserManagementFilters({ filters, setFilters, schools }) {
    const { data: systemStatuses = [] } = useGetSystemStatusesQuery();

    const onFilterChange = (name, value) => {
        const f = { ...filters };

        if (name === 'status') {
            if (value && value.id === SystemStatusEnum.REMOVED) {
                f['hideRemoved'] = false;
            } else {
                f['hideRemoved'] = true;
            }
        }

        f[name] = value;

        setFilters(f);
    };

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={6} md={3} lg={3} xl={3}>
                    <GroupedSchoolBuildingAutocomplete
                        label={<Label text='School(s)' />}
                        schools={schools}
                        schoolBuildingIds={filters.schoolBuildingIds || []}
                        setSchoolBuildingIds={ids => onFilterChange('schoolBuildingIds', ids)}
                        fullWidth
                        allowSchoolGroupSelect
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={3} xl={3}>
                    <Dropdown
                        label={<Label text='Status' />}
                        menuItems={systemStatuses}
                        value={filters.statusId}
                        onChange={v => onFilterChange('status', v)}
                    />
                </Grid>
                <Grid item xs={6} md={3} lg={3} xl={3}>
                    <ShowRemovedSwitchButton onFilterChange={onFilterChange} filters={filters} property='hideRemoved' />
                </Grid>
            </Grid>
        </Box>
    );
}

export default UserManagementFilters;