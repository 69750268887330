import { useState } from 'react';
import { toast } from "react-toastify";
import ConfirmationModal from '../../Common/ConfirmationModal';
import PillarFormModal from './PillarForm/PillarFormModal';
import { useDeletePillarMutation, useGetPillarsQuery, useLockPillarMutation } from './pillarManagementSlice';
import PillarManagementDataGrid from './PillarManagementDataGrid';

function PillarManagementContainer() {
    const [deletingPillar, setDeletingPillar] = useState(null);
    const [editingPillar, setEditingPillar] = useState(null);
    const [lockingPillar, setLockingPillar] = useState(null);

    const { data: pillars = [], isLoading } = useGetPillarsQuery();

    const [lockPillar] = useLockPillarMutation();
    const [deletePillar] = useDeletePillarMutation();

    function onDeletePillar(id) {
        deletePillar(id)
            .unwrap()
            .then(() => {
                setDeletingPillar(null);
                toast.success('Successfully deleted pillar');
            })
            .catch((error) => {
                toast.error(error);
                setDeletingPillar(null);
            });
    }

    function onLockPillar(id) {
        lockPillar(id)
            .unwrap()
            .then(response => {
                const isLocked = response?.isLocked;

                if (isLocked === null)
                    toast.error(`Failed to unlock/lock pillar`);

                setLockingPillar(null);
                toast.success(`Successfully ${isLocked ? 'locked' : 'unlocked'} pillar`);
            })
            .catch(error => {
                toast.error(error);
                setLockingPillar(null);
            })
    }

    return (
        <>
            <PillarManagementDataGrid
                pillars={pillars}
                isLoading={isLoading}
                setDelete={setDeletingPillar}
                setEdit={setEditingPillar}
                setLock={setLockingPillar}
            />
            <PillarFormModal
                isOpen={!!editingPillar}
                pillar={editingPillar}
                close={() => setEditingPillar(null)}
            />
            <ConfirmationModal
                isOpen={!!deletingPillar}
                close={() => setDeletingPillar(null)}
                onConfirm={() => onDeletePillar(deletingPillar.id)}
                title="Delete Pillar?"
                message="Please confirm you'd like to delete the selected pillar. This is not reversible once deleted."
                saveBtnText="Delete"
            />
            <ConfirmationModal
                isOpen={!!lockingPillar}
                close={() => setLockingPillar(null)}
                onConfirm={() => onLockPillar(lockingPillar.id)}
                title={lockingPillar?.isLocked ? "Unlock Pillar?" : "Lock Pillar?"}
                message={lockingPillar?.isLocked ? "Please confirm you'd like to unlock the selected pillar." : "Please confirm you'd like to lock the selected pillar."}
                saveBtnText={lockingPillar?.isLocked ? "Unlock" : "Lock"}
            />
        </>
    );
};

export default PillarManagementContainer;