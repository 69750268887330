import { daysOfMonth, getDayNames } from "./calendar";
import { MonthlyRecurrenceEnum, RecurrenceEnum } from "./enums";
import cronstrue from 'cronstrue';
import dayjs from "dayjs";

export const recurrenceOptions = [
    {
        "id": RecurrenceEnum.WEEKLY,
        "value": "Weekly"
    },
    {
        "id": RecurrenceEnum.BI_WEEKLY,
        "value": "Bi-Weekly"
    },
    {
        "id": RecurrenceEnum.MONTHLY,
        "value": "Monthly"
    }
];

export const dayOfMonthOptions = daysOfMonth();

export const monthlyOptions = [
    { "id": MonthlyRecurrenceEnum.ON_A_DAY, "value": "On a day of the month" },
    { "id": MonthlyRecurrenceEnum.ON_A_FIRST_WEEKDAY, "value": "On the first weekday(s) of the month" },
];

/**
 * Parses a cron expression and extracts recurrence pattern, month type, day of month, and weekdays.
 *
 * @param {string} cronValue - The cron expression to parse.
 * @returns {Object} An object containing the parsed objects:
 *                   - recurrencePattern - The recurrence pattern object.
 *                   - monthType - The month type object.
 *                   - dayOfMonth - The day of month object.
 *                   - weekDays - An array of selected weekdays.
 */
export const parseCronValue = (cronValue) => {
    let weekDays = [];
    let recurrencePattern = null;
    let monthType = null;
    let dayOfMonth = null;

    const cronParts = cronValue && cronValue.trim().split(" ");
    if (cronParts && cronParts.length === 5) {
        const weekdayPart = cronParts[4];
        if (weekdayPart !== "*") {
            // set biweekly values
            if (weekdayPart.includes("B")) {
                recurrencePattern = recurrenceOptions.find((x) => x.id === RecurrenceEnum.BI_WEEKLY);
            }
            // set first week of month values
            else if (weekdayPart.includes("#")) {
                recurrencePattern = recurrenceOptions.find((x) => x.id === RecurrenceEnum.MONTHLY);
                monthType = monthlyOptions.find((x) => x.id === MonthlyRecurrenceEnum.ON_A_FIRST_WEEKDAY);
            }
            // set weekly values
            else {
                recurrencePattern = recurrenceOptions.find((x) => x.id === RecurrenceEnum.WEEKLY);
            }
            weekDays = weekdayPart.split(",").map((x) => Number(x.charAt(0)));
        }
        // set day of month values
        const dayOfMonthPart = cronParts[2];
        if (dayOfMonthPart !== "*") {
            recurrencePattern = recurrenceOptions.find((x) => x.id === RecurrenceEnum.MONTHLY);
            monthType = monthlyOptions.find((x) => x.id === MonthlyRecurrenceEnum.ON_A_DAY);

            dayOfMonth = dayOfMonthOptions.find((x) => x.id === Number(dayOfMonthPart));
        }
    }

    return {
        recurrencePattern,
        monthType,
        dayOfMonth,
        weekDays
    };
};

/**
 * Generates a human-readable schedule statement string from a cron expression and form parameters.
 *
 * @param {string} cronSchedule - The cron expression to parse.
 * @param {Object} recurrencePattern - The recurrence pattern object.
 * @param {number[]} selectedWeekdays - An array of selected weekdays.
 * @param {Date} startDate - The start date of the schedule.
 * @param {Date} endDate - The end date of the schedule.
 * @returns {string} A human-readable schedule statement.
 */
export const generateScheduleStatement = (cronSchedule, recurrencePattern, selectedWeekdays, startDate, endDate) => {
    let statement = "";
    if ((cronSchedule?.trim() || "") === "" || cronSchedule?.trim() === "0 0 * * *") {
        return "";
    }

    if (recurrencePattern && recurrencePattern.id === RecurrenceEnum.BI_WEEKLY) {
        statement = `Occurs bi-weekly on ${getDayNames(selectedWeekdays)}`;
    } else if (recurrencePattern && recurrencePattern.id === RecurrenceEnum.WEEKLY) {
        statement = `Occurs weekly on ${getDayNames(selectedWeekdays)}`;
    } else if (cronSchedule && cronSchedule.includes("B")) {
        return "";
    } else {
        statement = cronSchedule ? cronstrue.toString(cronSchedule) : "";
    }

    statement = statement.replace("At 12:00 AM,", "Occurs");

    if (startDate) {
        statement = `${statement}, from ${dayjs(startDate).format("MMMM D, YYYY")}`;
    }
    if (endDate) {
        statement = `${statement} to ${dayjs(endDate).format("MMMM D, YYYY")}`;
    }

    return statement;
};