import React from "react";
import { Box, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Skeleton, Stack, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { SurveyQuestionTypeEnum } from "../../../utility/enums";
import DialogActionButtons from "../Buttons/DialogActionButtons";
import { useGetSurveyQuestionScoringTypesQuery } from "../../../app/slices/surveyQuestionScoringTypesSlice";

export default function SurveyReview({ open, onClose, survey, surveyName, surveyQuestions }) {
    const { data: scoringTypes, isLoading: loadingScoringTypes } = useGetSurveyQuestionScoringTypesQuery();


    const renderOptions = (questionId, surveyQuestionTypeId, options) => {
        switch (surveyQuestionTypeId) {
            case SurveyQuestionTypeEnum.YES_OR_NO:
            case SurveyQuestionTypeEnum.MULTIPLE_CHOICE:
                return (
                    <RadioGroup
                        sx={{ mt: 2 }}
                    >
                        {options.map((uo,index) => (
                            <Box key={`option-entry-${uo.id}`} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Radio color='primary' />}
                                    label={uo.title}
                                    value={uo.id || index}
                                />
                            </Box>
                        ))}
                    </RadioGroup>
                );
            case SurveyQuestionTypeEnum.CHECKBOX_CHOICES:
                return (
                    <FormGroup
                        sx={{ mt: 2 }}
                    >
                        {options.map(uo => (
                            <Box key={`option-entry-${uo.id}`} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Checkbox color='primary' />}
                                    label={uo.title}
                                    value={uo.id}
                                />
                            </Box>
                        ))}
                    </FormGroup>
                );
            case SurveyQuestionTypeEnum.SHORT_ANSWER:
                return (
                    <TextField
                        id='short-answer-input'
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={3}
                        sx={{ mt: 3, mb: 1 }}
                    />
                );
            default:
                return null;
        }
    };




    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='md'
            scroll='paper'
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 5 }}>
                    <Box>
                        {survey?.name || surveyName}
                    </Box>
                </Box>
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                
            {surveyQuestions?.map((q, i) => (
                <Box key={q.id}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormLabel
                            sx={{ color: (theme) => theme.palette.secondary.main, fontSize: '1.1rem' }}
                        >
                            {`${q.ordinal}. ${q.prompt} ${q.isRequired ? "*" : ""}`}
                        </FormLabel>
                        {!loadingScoringTypes && 
                        <Chip
                            label={scoringTypes.find(x => x.id == q.scoringTypeId).value}
                            color='secondary'
                            sx={{
                                alignSelf: 'flex-end',
                                color: 'secondary.dark',
                                backgroundColor: 'secondary.lighter',
                            }}
                        />}
                    </Box>
                    {renderOptions(q.id, q.surveyQuestionTypeId, q.surveyQuestionOptions)}
                    {surveyQuestions.length - 1 !== i ?
                        <Divider sx={{ my: 3 }} />
                        :
                        <Box sx={{ mb: 2 }} />
                    }
                </Box>
                ))}
            </DialogContent>
            <Divider />
            <DialogActionButtons
                submitText="Done"
                closeText={"Close"}
                onClose={onClose}
                isSubmitHidden
                disableLoadingText
            />
        </Dialog >
    )
}