import { api } from '../../app/api';
const TAG = 'Scorecard';

export const scorecardApi = api.injectEndpoints({
    tagTypes: [TAG, 'DistrictSpecific'],
    endpoints: (builder) => ({
        getScorecard: builder.query({
            query: () => `statistics/scorecard`,
            providesTags: () => [{ type: TAG }]
        })
    }),
})

export const {
    useGetScorecardQuery
} = scorecardApi;