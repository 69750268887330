import { Box, Stack, Typography, useTheme } from "@mui/material";
import { AvTimer, CheckCircleOutline, FormatListBulleted } from "@mui/icons-material";
import BorderCard from "../../Common/Containers/BorderCard";
import BasicWidget from "./BasicWidget";
import TrendWidget from "./TrendWidget";

export default function ActiveEngagementWidget({ surveyEngagement, isLoading }) {
    const theme = useTheme();
    return (
        <BorderCard>
            <Box sx={{ m: 2 }}>
                <Typography variant='body1'>
                    Active Campaign Engagement
                </Typography>
                <Stack spacing={2} sx={{ pt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <BasicWidget isLoading={isLoading} color={theme.palette.grey[100]} title='Total Surveys' icon={FormatListBulleted} value={surveyEngagement?.totalCount} />
                        <TrendWidget isLoading={isLoading} value={surveyEngagement?.trendPercentage} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <BasicWidget isLoading={isLoading} color='success.lighter' textColor='success.dark' title="Completed" icon={CheckCircleOutline} value={surveyEngagement?.completedCount} />
                        <BasicWidget isLoading={isLoading} color='secondary.lighter' textColor='secondary.dark' title="Pending" icon={AvTimer} value={surveyEngagement?.pendingCount} />
                    </Box>
                </Stack>
            </Box>
        </BorderCard>
    );
}