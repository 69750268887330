import { api } from "../api";

const TAG = 'SampleSizes';

export const SampleSizesApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: builder => ({
        getSampleSizes: builder.query({
            query: () => '/lookup/sample-sizes',
            providesTags: [TAG]
        })
    })
})

export const {
    useGetSampleSizesQuery
} = SampleSizesApi