import { Box, Button, Switch } from "@mui/material";
import Label from "./Label";

export default function ShowRemovedSwitchButton({ onFilterChange, filters, text, property }) {
    return (
        <Button
            size='large'
            disableElevation
            variant={'outlined'}
            fullWidth
            onClick={() => onFilterChange(property, !filters[property])}
            sx={{
                borderColor: theme => theme.palette.grey[500],
                justifyContent: 'space-between',
                height: '40px',
            }}
            endIcon={<Switch
                size='small'
                checked={!filters[property]}
                color='primary' />}
        >
            <Box sx={{ ml: '-8px' }} >
                <Label text={text || 'Show Removed'} />
            </Box>
        </Button>
    );
}