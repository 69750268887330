import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  border-bottom: 1px dashed lightgrey;
`;

const EllipsisCell = styled(StyledTableCell)`
  max-width: 200px;
  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BenefitDataTable = ({ benefits }) => {
    return (
        <TableContainer>
            <Table size='small' aria-label='benefits table'>
                <TableHead>
                    <TableRow>
                        <StyledTableCell />
                        <StyledTableCell>Benefit Name</StyledTableCell>
                        <StyledTableCell>Benefit Description</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {benefits.map(benefit => (
                        <TableRow key={benefit.id}>
                            <StyledTableCell />
                            <StyledTableCell>{benefit.name}</StyledTableCell>
                            <EllipsisCell>
                                <Tooltip title={benefit.description}>
                                    <div>{benefit.description}</div>
                                </Tooltip>
                            </EllipsisCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BenefitDataTable;