import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { Box, Toolbar, Typography, IconButton, Tooltip, Badge, Skeleton } from '@mui/material';
import { AccountCircle, Logout, Notifications } from '@mui/icons-material';
import { useLogoutMutation } from '../../app/slices/authenticationSlice';
import { logout } from '../../app/api';
import ManageProfileDialog from '../ManageProfile';
import { useGetUnreadNotificationsQuery } from '../../app/slices/notificationSlice';
import NotificationsModal from './Notifications/NotificationsModal';

const drawerWidth = 310;
const appBarHeight = 65;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: '#444444',
    height: appBarHeight,
    ...(!open && {
        paddingLeft: theme.spacing(8)
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
    }),
}));

export default function Topbar({ open, isFetching, user, role, schoolDistrict }) {
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [isManageProfileOpen, setIsManageProfileOpen] = useState(false);

    const { data: notifications = [] } = useGetUnreadNotificationsQuery();
    const [logoutPost] = useLogoutMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            navigate('login');
            dispatch(logout());
            logoutPost();
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {isFetching ? (
                            <>
                                <Skeleton variant="text" width={200} height={30} sx={{ bgcolor: 'grey.600' }} />
                                <Skeleton variant="text" width={100} height={20} sx={{ bgcolor: 'grey.600' }} />
                            </>
                        ) : (
                            <>
                                <Typography
                                    variant="h6"
                                    component="div"
                                >
                                    {`Welcome ${user.firstName} ${user.lastName}`}
                                </Typography>
                                <Typography
                                    variant="string"
                                    component="div"
                                >
                                    {`Role(s): ${role}`}
                                    {role === 'Integrator' && ` (${schoolDistrict})`}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box sx={{
                        ml: 'auto',
                        display: 'flex'
                    }}>
                        <Tooltip title='Notifications'>
                            <Badge
                                badgeContent={notifications.filter(notification => !notification.isRead).length}
                                color='warning'
                                overlap="circular"
                                sx={{
                                    '.MuiBadge-badge': {
                                        right: 8,
                                        top: 8,
                                    },
                                }}
                            >
                                <IconButton
                                    sx={{
                                        color: '#ffffff'
                                    }}
                                    onClick={() => setIsNotificationsOpen(true)}
                                >
                                    <Notifications />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        <Tooltip title='View/Edit Profile'>
                            <IconButton
                                sx={{
                                    color: '#ffffff'
                                }}
                                onClick={() => setIsManageProfileOpen(true)}
                            >
                                <AccountCircle />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Logout of Pillar XP'>
                            <IconButton
                                sx={{
                                    color: '#ffffff'
                                }}
                                onClick={handleLogout}
                            >
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
            <NotificationsModal open={isNotificationsOpen} onClose={() => setIsNotificationsOpen(false)} notifications={notifications} />
            <ManageProfileDialog open={isManageProfileOpen} onClose={() => setIsManageProfileOpen(false)} user={user} />
        </>
    );
}