import { api } from '../../../app/api';
const TAG = 'SchoolManagement';

export const schoolManagementApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getAllSchools: builder.query({
            query: () => 'school',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        bulkImportSchools: builder.mutation({
            query: (file) => ({
                url: 'school/bulk',
                method: 'POST',
                body: file
            }),
            invalidatesTags: [TAG]
        }),
        addSchool: builder.mutation({
            query: (school) => ({
                url: 'school',
                method: 'POST',
                body: JSON.stringify(school)
            }),
            invalidatesTags: [TAG]
        }),
        updateSchool: builder.mutation({
            query: (school) => ({
                url: 'school',
                method: 'PUT',
                body: JSON.stringify(school)
            }),
            invalidatesTags: () => [{ type: TAG }]
        }),
        deleteSchool: builder.mutation({
            query: (id) => ({
                url: `school/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: () => [{ type: TAG }]
        }),
        lockSchool: builder.mutation({
            query: (id) => ({
                url: `school/toggle-lock/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: () => [{ type: TAG }]
        })
    })
})

export const {
    useGetAllSchoolsQuery,
    useAddSchoolMutation,
    useBulkImportSchoolsMutation,
    useUpdateSchoolMutation,
    useDeleteSchoolMutation,
    useLockSchoolMutation
} = schoolManagementApi;