import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, CardContent, TextField, Stack, InputAdornment, IconButton, Checkbox, FormControlLabel, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../../app/slices/authenticationSlice';
import { useUpdateUserAgreementAcceptanceMutation } from '../../app/slices/userAgreementSlice';
import { setSession } from '../../utility';
import { RoleEnum } from '../../utility/enums';
import AuthCard from '../Common/Auth/AuthCard';
import styled from 'styled-components';
import UserAgreement from '../UserAgreement/UserAgreement';
import { useLogoutMutation } from '../../app/slices/authenticationSlice';
import { logout } from '../../app/api';

const LoginButton = styled(LoadingButton)`
&:hover {
    background-color: #026AD3 !important;
}
`;

export default function Login() {
    const [form, setForm] = useState({
        schoolDistrict: '',
        schoolDistrictError: false,
        email: '',
        emailError: false,
        password: '',
        passwordError: false,
        rememberMe: false
    })
    const [showPassword, setShowPassword] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRoles, setUserRoles] = useState(null);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();
    const { isExternal } = useParams();
    const dispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();
    const [updateUserAgreementAcceptance] = useUpdateUserAgreementAcceptanceMutation();
    const [logoutPost] = useLogoutMutation();

    useEffect(() => {
        var credsString = localStorage.getItem('las_saved_credentials');

        if (credsString !== null) {
            var creds = JSON.parse(credsString);
            setForm(prevForm => ({
                ...prevForm,
                schoolDistrict: creds.schoolDistrict,
                rememberMe: creds.rememberMe
            }));
        }
    }, []);

    const navigateToApplication = (userRoles) => {
        if (userRoles.some(role => role === RoleEnum.INTEGRATOR)) {
            isExternal ? navigate('/ext/pending-surveys') : navigate('/scorecards');
        } else {
            isExternal ? navigate('/ext/pending-surveys') : navigate('/')
        }

    }

    const handleUserAgreementAccepted = () => {
        //save user agreement.
        updateUserAgreementAcceptance({ userId: userId })
            .then(response => {
                if (response.error && response.error.status === 500) {
                    toast.error('A problem occurred while accepting the user agreement. You will need to accept it on the next login.');
                }
                if (userRoles.some(role => role === RoleEnum.INTEGRATOR)) {
                    isExternal ? navigate('/ext/pending-surveys') : navigate('/scorecards');
                } else {
                    isExternal ? navigate('/ext/pending-surveys') : navigate('/')
                }
            })
    }

    const handleUserAgreementCancel = () => {
        //logout user
        dispatch(logout());
        logoutPost();
        setIsLoggedIn(false)
    }

    function validateAndLogin(e) {
        e.preventDefault();

        var schoolDistrictError, emailError, passwordError = false;

        // School districts are assumed to be numeric only, so we'll check for that
        if (form.schoolDistrict === '' || (/[^0-9]/.test(form.schoolDistrict))) schoolDistrictError = true;
        if (form.email === '') emailError = true;
        if (form.password === '') passwordError = true;

        if (schoolDistrictError || emailError || passwordError) {
            setForm({ ...form, schoolDistrictError, emailError, passwordError });
            return;
        }

        if (form.rememberMe) {
            localStorage.setItem(
                'las_saved_credentials',
                JSON.stringify({
                    schoolDistrict: form.schoolDistrict,
                    rememberMe: form.rememberMe
                }));
        } else {
            localStorage.removeItem('las_saved_credentials');
        }

        login({ schoolDistrictPublicIdentifier: form.schoolDistrict, emailAddress: form.email, password: form.password })
            .then(response => {
                if (response.error && response.error.status === 401) {
                    toast.error('Invalid email/password. Please try again.');
                } else if (response.error && response.error.status === 500) {
                    toast.error('A problem occurred while logging in. Please contact a system administrator');
                } else if (response.error) {
                    toast.error('An unknown error occurred. Please try again.');
                } else {
                    setIsLoggedIn(true);
                    setUserId(response.data.userId);
                    setUserRoles(response.data.userRoles);
                    setSession();
                    if (response.data.userAgreementAccepted) {
                        navigateToApplication(response.data.userRoles);
                    }
                }
            })
            .catch((e) => console.log(e));
    }

    return (
        <>
            <AuthCard>
                <CardContent>
                    <form onSubmit={e => validateAndLogin(e)}>
                        <Stack
                            spacing={2}
                        >
                            <TextField
                                placeholder='School District'
                                variant='outlined'
                                size='small'
                                value={form.schoolDistrict}
                                onChange={(e) => setForm({ ...form, schoolDistrict: e.currentTarget.value, schoolDistrictError: false })}
                                error={form.schoolDistrictError}
                                helperText={form.schoolDistrictError ? 'Please enter a valid school district' : null}
                                disabled={isLoading}
                            />
                            <TextField
                                placeholder='Email Address'
                                variant='outlined'
                                size='small'
                                type='email'
                                autoComplete='email'
                                value={form.email}
                                onChange={(e) => setForm({ ...form, email: e.currentTarget.value, emailError: false })}
                                error={form.emailError}
                                helperText={form.emailError ? 'Please enter an valid email address' : null}
                                disabled={isLoading}
                            />
                            <TextField
                                placeholder='Password'
                                variant='outlined'
                                size='small'
                                value={form.password}
                                onChange={(e) => setForm({ ...form, password: e.currentTarget.value, passwordError: false })}
                                error={form.passwordError}
                                helperText={form.passwordError ? 'Please enter a valid password' : null}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='current-password'
                                disabled={isLoading}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                disabled={isLoading}
                                                edge='end'>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Box
                                component='div'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <FormControlLabel
                                    control={<Checkbox checked={form.rememberMe} onChange={e => setForm({ ...form, rememberMe: e.target.checked })} />}
                                    label='Remember Me'
                                    disabled={isLoading}
                                />
                                <Link
                                    href={isLoading ? '#' : '/forgot-password'}
                                    color={isLoading ? 'text.disabled' : 'secondary'}
                                    underline={isLoading ? 'none' : 'always'}
                                >
                                    Forgot Password?
                                </Link>
                            </Box>
                            <LoginButton
                                loading={isLoading}
                                variant='contained'
                                type='submit'
                            >
                                Login
                            </LoginButton>
                            {!isExternal && (
                                <Link
                                    href={isLoading ? '#' : '/self-registration'}
                                    color={isLoading ? 'text.disabled' : 'secondary'}
                                    underline={isLoading ? 'none' : 'always'}
                                >
                                    Sign Up for an Account
                                </Link>
                            )}
                        </Stack>
                    </form>
                </CardContent>
            </AuthCard>
            {isLoggedIn &&
                <UserAgreement
                    isOpen={isLoggedIn}
                    accept={handleUserAgreementAccepted}
                    cancel={handleUserAgreementCancel}
                />
            }
        </>
    );
}