import { Box } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CreateNewButton from '../Buttons/CreateNewButton';
import ImportButton from '../Buttons/ImportButton';

export function CustomDataGridToolbar({ hasWriteAccess, onImportClick, handleCreateNew }) {
    return (
        <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, mx: .75, mt: .5 }}>
            <Box sx={{ display: 'flex', gap: 1 }}>
                <GridToolbarQuickFilter sx={{ pr: 1 }} />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                {hasWriteAccess && onImportClick &&
                    <ImportButton onClick={onImportClick} />
                }
            </Box>
            {hasWriteAccess && handleCreateNew &&
                <CreateNewButton
                    onClick={handleCreateNew}
                />
            }
        </GridToolbarContainer>
    );
}