import { useMemo, useCallback } from 'react';
import { Autocomplete, Box, Chip, CircularProgress, createFilterOptions, TextField, Typography } from '@mui/material';

let uniqueIdCounter = 0;

const GroupedSchoolBuildingAutocomplete = ({
    schools,
    fullWidth,
    tagLimit = 1,
    schoolBuildingIds = [],
    setSchoolBuildingIds,
    label = "School(s)",
    loading,
    noOptionsText = "No schools found",
    allowSchoolGroupSelect = true,
    error = false,
    required = false,
    onBlur = () => { }
}) => {
    const uniqueId = useMemo(() => `grouped-school-building-${uniqueIdCounter++}`, []);

    const options = useMemo(() => {
        if (!Array.isArray(schools)) return [];

        return schools.flatMap(school =>
            school.schoolBuildings?.map(building => ({
                id: building.id,
                name: building.name,
                schoolName: school.name
            })) || []
        );
    }, [schools]);

    const selectedOptions = useMemo(() =>
        options.filter(option => schoolBuildingIds.includes(option.id)),
        [options, schoolBuildingIds]
    );

    const handleChange = useCallback((_event, newValue) => {
        const newIds = newValue.map(option => option.id);
        setSchoolBuildingIds(newIds);
    }, [setSchoolBuildingIds]);

    const sortedOptions = useMemo(() =>
        [...options].sort((a, b) => -b.schoolName.localeCompare(a.schoolName)),
        [options]
    );

    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.name} ${option.schoolName}`,
    });

    const getSchoolBuildings = useCallback((schoolName) => {
        return options.filter(option => option.schoolName === schoolName);
    }, [options]);

    const areAllSelected = useCallback((schoolBuildings) => {
        return schoolBuildings.every(building => schoolBuildingIds.includes(building.id));
    }, [schoolBuildingIds]);

    const handleGroupClick = useCallback((schoolName) => {
        const schoolBuildings = getSchoolBuildings(schoolName);
        const allSelected = areAllSelected(schoolBuildings);

        const newIds = allSelected
            ? schoolBuildingIds.filter(id => !schoolBuildings.some(building => building.id === id))
            : [...schoolBuildingIds, ...schoolBuildings.map(building => building.id)];

        setSchoolBuildingIds(newIds);
    }, [getSchoolBuildings, areAllSelected, schoolBuildingIds, setSchoolBuildingIds]);

    return (
        <Autocomplete
            id={uniqueId}
            multiple
            limitTags={tagLimit}
            disableCloseOnSelect
            disableClearable
            size='small'
            options={sortedOptions}
            filterOptions={filterOptions}
            groupBy={(option) => option.schoolName}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={loading}
            loadingText='Loading schools...'
            noOptionsText={noOptionsText}
            value={selectedOptions}
            onChange={handleChange}
            onBlur={onBlur}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label={label}
                    required={required}
                    error={error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        key={option.id}
                        size='small'
                        label={`${option.name} - ${option.schoolName}`}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderGroup={(params) => {
                const schoolBuildings = getSchoolBuildings(params.group);
                const allSelected = areAllSelected(schoolBuildings);
                const someSelected = schoolBuildings.some(building => schoolBuildingIds.includes(building.id));
                return (
                    allowSchoolGroupSelect ? (
                        <Box key={params.key}>
                            <Box
                                onClick={() => handleGroupClick(params.group)}
                                sx={{
                                    cursor: 'pointer',
                                    padding: '8px 8px 8px 12px',
                                    backgroundColor: allSelected ? '#EBEDF0' : someSelected ? '#f5f5f5' : 'white',
                                    ':hover': { backgroundColor: allSelected ? '#E0E4E8' : '#f5f5f5' }
                                }}
                            >
                                {params.group}
                            </Box>
                            <Typography variant='body2'>
                                {params.children}
                            </Typography>
                        </Box>
                    ) : (
                        <Box key={params.key}>
                            <Box
                                sx={{
                                    padding: '8px 8px 8px 12px',
                                }}
                            >
                                {params.group}
                            </Box>
                            <Typography variant='body2'>
                                {params.children}
                            </Typography>
                        </Box>
                    )
                );
            }}
            sx={{
                width: fullWidth ? '100%' : '210px',
                backgroundColor: 'white',
            }}
        />
    );
};

export default GroupedSchoolBuildingAutocomplete;