import { api } from '../api';

export const systemContactApi = api.injectEndpoints({
    tagTypes: ['SystemContact'],
    endpoints: builder => ({
        getSystemContactByDistrictId: builder.query({
            query: (districtId) => `/systemContact/by-district/${districtId}`,
            providesTags: (result) => {
                return [{ type: 'SystemContact', id: result?.id }, { type: 'DistrictSpecific' }]
            }
        })
    })
})

export const {
    useGetSystemContactByDistrictIdQuery
} = systemContactApi