import { LoadingButton } from '@mui/lab';
import { Button, DialogActions } from '@mui/material';

const DialogActionButtons = ({ submitText, onSubmit, closeText, onClose, isSubmitDisabled, isSubmitHidden, isLoading, isLoadingText = 'Saving...', disableLoadingText, fullWidth }) => {
    return (
        <DialogActions
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                paddingX: '25px',
                paddingBottom: '25px',
            }}>
            <Button
                fullWidth={fullWidth}
                variant="contained"
                color="primary"
                onClick={onClose}
                disabled={isLoading}
                sx={{
                    flexBasis: fullWidth ? '100%' : '33%',
                }}
            >
                {closeText}
            </Button>
            {!isSubmitHidden &&
                <LoadingButton
                    disabled={isSubmitDisabled}
                    fullWidth={fullWidth}
                    variant="contained"
                    color="secondary"
                    onClick={onSubmit}
                    loading={isLoading}
                    {...(!disableLoadingText && { loadingIndicator: isLoadingText })}
                    sx={{
                        flexBasis: fullWidth ? '100%' : '33%',
                    }}
                >
                    {submitText}
                </LoadingButton>
            }
        </DialogActions>
    );
}

export default DialogActionButtons;