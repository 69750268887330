import { Box, Typography, Skeleton } from "@mui/material";
import { Gauge, gaugeClasses } from "@mui/x-charts";
import BorderCard from "../../Common/Containers/BorderCard";

const EngagementSkeleton = (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2.75 }}>
        <Skeleton variant='text' width={120} height={25} />
        <Skeleton variant='text' width={140} height={25} />
        <Skeleton variant='text' width={100} height={25} />
    </Box>
);

export default function OverallEngagementWidget({ surveyEngagement, isLoading }) {

    const calculateGaugeValue = () => {
        if (!isLoading && surveyEngagement.totalCount) {
            return Math.round((surveyEngagement.completedCount / surveyEngagement.totalCount) * 100);
        } else {
            return 0;
        }
    };

    return (
        <BorderCard>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ pl: 2, py: 2, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Typography variant='body1'>
                        Overall Engagement
                    </Typography>
                    {isLoading ? EngagementSkeleton : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                            <Typography variant='body2' color='secondary'>
                                {surveyEngagement.pendingCount} Surveys Pending
                            </Typography>
                            <Typography variant='body2' color='success.main'>
                                {surveyEngagement.completedCount} Surveys Completed
                            </Typography>
                            <Typography variant='body2'>
                                {surveyEngagement.totalCount} Total Surveys
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box sx={{ p: .75 }}>
                    <Gauge
                        width={140}
                        height={140}
                        value={calculateGaugeValue()}
                        text={({ value }) => value ? `${value}%` : ""}
                        sx={(theme) => ({
                            [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 35,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                                fill: theme.palette.success.main,
                            },
                        })}
                    />
                </Box>
            </Box>
        </BorderCard>
    );
}