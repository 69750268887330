import { Stack } from "@mui/material";
import SwitchDistrictFormCard from "./SwitchDistrictFormCard";
import ContentWrapper from "../Common/ContentWrapper";

export default function IntegratorSettings() {
    return (
        <ContentWrapper title="Integrator Settings">
            <Stack
                spacing={3}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100vh'
                }}
            >
                <SwitchDistrictFormCard />
            </Stack>
        </ContentWrapper>
    );
}