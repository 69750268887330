import { useMemo } from "react";
import { Autocomplete, Box, Card, Chip, CircularProgress, FormHelperText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useGetCampaignSchoolsQuery } from "../surveyWizardSlice";
import { useGetRolesQuery } from "../../../../app/slices/rolesSlice";
import { useGetRotationsQuery } from "../../../../app/slices/rotationSlice";
import { useGetSampleSizesQuery } from "../../../../app/slices/sampleSizeSlice";
import GroupedSchoolBuildingAutocomplete from '../../../Common/FormElements/GroupedSchoolBuildingAutocomplete';

export default function DistributionForm({ campaignId, form, setForm, formErrors, setFormErrors }) {
    const { data: schools, isLoading: loadingSchools } = useGetCampaignSchoolsQuery(campaignId);
    const { data: roleOptions, isLoading: loadingRoles } = useGetRolesQuery();
    const { data: rotations, isLoading: loadingRotations } = useGetRotationsQuery();
    const { data: sampleSizes, isLoading: loadingSampleSizes } = useGetSampleSizesQuery();

    const memoizedRoleValue = useMemo(() =>
        form.roleIds.map(roleId => roleOptions?.find(role => role.id === roleId) || roleId),
        [form.roleIds, roleOptions]
    );

    const handleMinToCompleteChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length <= 3 && newValue >= 0) {
            setForm({ ...form, minutesToComplete: newValue })
        }
    };

    const handleBlurValidation = (newValues, field) => {
        if (newValues && formErrors[field]) {
            setFormErrors({ ...formErrors, [field]: null });
        }
    };

    const setFormSchoolBuildingIds = (newIds) => setForm({ ...form, schoolBuildingIds: newIds });

    return (
        <Card
            sx={{
                flexBasis: '75%',
                padding: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <Typography sx={{ mb: 3 }}>
                <span style={{ fontWeight: 'bold' }}>Select Distribution Options. </span>
                Users affiliated with the selected options will be eligible to receive the survey.
            </Typography>
            <Stack spacing={2}>
                <Box sx={{ pr: '30px' }}>
                    <GroupedSchoolBuildingAutocomplete
                        fullWidth
                        schools={schools}
                        schoolBuildingIds={form.schoolBuildingIds}
                        setSchoolBuildingIds={setFormSchoolBuildingIds}
                        allowSchoolGroupSelect
                        tagLimit={3}
                        loading={loadingSchools}
                        error={formErrors['schoolBuildings']}
                        onBlur={() => handleBlurValidation(form.schoolBuildingIds, 'schoolBuildings')}
                        required
                    />
                    <FormHelperText error>
                        {formErrors['schoolBuildings']}
                    </FormHelperText>
                </Box>
                <Box sx={{ pr: '30px' }}>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Autocomplete
                            id='add-roles'
                            size='small'
                            fullWidth
                            multiple
                            limitTags={3}
                            disableCloseOnSelect
                            options={roleOptions || []}
                            isOptionEqualToValue={(option, value) => option.id === value.id || option.id === value}
                            getOptionLabel={(option) => {
                                if (option.id) {
                                    return option.value;
                                } else {
                                    const matchingRole = roleOptions?.find(role => role.id === Number(option));
                                    return matchingRole ? matchingRole.value : option;
                                }
                            }}
                            noOptionsText="No roles found"
                            loading={loadingRoles}
                            loadingText="Loading roles..."
                            value={memoizedRoleValue}
                            onChange={(_e, newValue) => {
                                const roleIds = newValue.map(item => item.id);
                                setForm(prevForm => ({ ...prevForm, roleIds: roleIds }));
                            }}
                            onBlur={() => handleBlurValidation(form.roleIds, 'roles')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    required={true}
                                    label="Role(s)"
                                    error={!!formErrors['roles']}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loadingRoles ? <CircularProgress color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        key={option.id}
                                        size="small"
                                        label={option.value}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                }
                            }}
                        />
                    </Box>
                    <FormHelperText error={!!formErrors['roles']}>
                        {formErrors['roles']}
                    </FormHelperText>
                    {formErrors['surveyRecipients'] &&
                        <FormHelperText error={!!formErrors['surveyRecipients']} sx={{ mt: 0 }}>
                            {formErrors['surveyRecipients']}
                        </FormHelperText>
                    }
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <TextField
                            id='add-minutes-to-complete'
                            required={true}
                            label="Mins to Complete"
                            variant='outlined'
                            fullWidth
                            size='small'
                            value={form.minutesToComplete}
                            type='number'
                            onChange={(e) => handleMinToCompleteChange(e)}
                            onBlur={() => handleBlurValidation(form.minutesToComplete, 'minsToComplete')}
                            error={!!formErrors['minsToComplete']}
                            helperText={formErrors['minsToComplete']}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white'
                                }
                            }}
                        />
                        <Tooltip
                            title="Enter the estimated time it will take to complete this survey in minutes."
                        >
                            <Info sx={{ length: '20px', width: '20px', alignSelf: 'center' }} />
                        </Tooltip>
                        <Box width='100%'>
                            <Autocomplete
                                size='small'
                                id='add-sample-size'
                                fullWidth
                                options={sampleSizes || []}
                                getOptionLabel={(option) => option.value}
                                loading={loadingSampleSizes}
                                loadingText={"Loading sample size options..."}
                                value={sampleSizes?.find(option => option.id === form.sampleSizeId) || null}
                                onChange={(_e, newValue) => {
                                    setForm({ ...form, sampleSizeId: newValue ? newValue.id : null });
                                }}
                                onBlur={() => handleBlurValidation(form.sampleSizeId, 'sampleSize')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        variant='outlined'
                                        label="Sample Size"
                                        error={!!formErrors['sampleSize']}
                                    />
                                )}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: 'white',
                                    }
                                }}
                            />
                            <FormHelperText error={!!formErrors['sampleSize']}>
                                {formErrors['sampleSize']}
                            </FormHelperText>
                        </Box>
                        <Tooltip
                            title="Select the percentage of users to receive the survey on each scheduled distribution."
                        >
                            <Info sx={{ length: '20px', width: '20px', alignSelf: 'center' }} />
                        </Tooltip>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Autocomplete
                            fullWidth
                            size='small'
                            id='add-rotation'
                            options={rotations || []}
                            getOptionLabel={(option) => option.value}
                            noOptionsText={"No rotation options found"}
                            loading={loadingRotations}
                            loadingText={"Loading rotation options..."}
                            value={rotations?.find(option => option.id === form.rotationId) || null}
                            onChange={(_e, newValue) => {
                                setForm({ ...form, rotationId: newValue ? newValue.id : null })
                            }}
                            onBlur={() => handleBlurValidation(form.rotationId, 'rotation')}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    required
                                    variant='outlined'
                                    label="Rotation"
                                    error={!!formErrors['rotation']}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loadingRotations ? <CircularProgress color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                }
                            }}
                        />
                        <Tooltip
                            title="Select 'Random' to send surveys to a new, randomly selected group on each scheduled distribution, or 'Continual' to send surveys to the same group on each scheduled distribution."
                        >
                            <Info sx={{ length: '20px', width: '20px', alignSelf: 'center' }} />
                        </Tooltip>
                    </Box>
                    <FormHelperText error={!!formErrors['rotation']}>
                        {formErrors['rotation']}
                    </FormHelperText>
                </Box>
            </Stack>
        </Card>
    );
}