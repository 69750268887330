import dayjs from "dayjs";

export const weekdays = [
    {
        "id": 0,
        "value": "Sunday"
    },
    {
        "id": 1,
        "value": "Monday"
    },
    {
        "id": 2,
        "value": "Tuesday"
    },
    {
        "id": 3,
        "value": "Wednesday"
    },
    {
        "id": 4,
        "value": "Thursday"
    },
    {
        "id": 5,
        "value": "Friday"
    },
    {
        "id": 6,
        "value": "Saturday"
    }
]

export const daysOfMonth = () => {
    let days = []
    for (let i = 1; i < 32; i++) {
        days.push({ "id": i, "value": i.toString() })
    }
    return days;
}

export const getDayNames = (daysArray) => {
    if (!daysArray || daysArray.length === 0) {
        return ("no days")
    }
    const dayNames = daysArray.sort().map(x => dayjs().day(x).format("dddd"));
    if (dayNames.length === 1) {
        return dayNames[0];
    }
    if (dayNames.length === 2) {
        return dayNames.join(" and ");
    }
    const commaDays = dayNames.slice(0, -1).join(", ");
    return `${commaDays} and ${dayNames[dayNames.length - 1]}`

}


