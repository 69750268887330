import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { SurveyQuestionTypeEnum } from '../../../../utility/enums';
import QuestionScoringType from './QuestionScoringType';

export default function QuestionProperties({ handleQuestionTypeSwitchToggle, isRequired, handleRequiredToggle, surveyQuestionTypeId, scoringTypeId, handleScoringTypeChange }) {

    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            width: "100%",
            gap: '25px',
            pr: '15px'
        }}>
            {(surveyQuestionTypeId === SurveyQuestionTypeEnum.MULTIPLE_CHOICE || surveyQuestionTypeId === SurveyQuestionTypeEnum.CHECKBOX_CHOICES) &&
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                color='secondary'
                                checked={surveyQuestionTypeId === SurveyQuestionTypeEnum.CHECKBOX_CHOICES}
                                onChange={handleQuestionTypeSwitchToggle}
                            />
                        }
                        label={<Typography variant='body2'>Allow multiple answer selection</Typography>}
                        labelPlacement='start'
                    />
                </FormGroup>
            }
            <Box sx={{ display: 'flex', gap: '25px', ml: 'auto' }}>
                {surveyQuestionTypeId !== SurveyQuestionTypeEnum.SHORT_ANSWER &&
                    <QuestionScoringType
                        scoringTypeId={scoringTypeId}
                        handleScoringTypeChange={handleScoringTypeChange}
                    />
                }
                <FormGroup >
                    <FormControlLabel
                        control={
                            <Switch
                                color='secondary'
                                checked={isRequired}
                                onChange={handleRequiredToggle}
                            />
                        }
                        label={<Typography variant='body2'>Required</Typography>}
                        labelPlacement='start'
                    />
                </FormGroup>
            </Box>
        </Box>
    );
}