import { Grid, Box } from "@mui/material";
import Dropdown from '../../Common/FormElements/Dropdown';
import Label from "../../Common/FormElements/Label";

function SchoolManagementFilters({ filters, setFilters, schools, codes }) {
    const onFilterChange = (name, value) => {
        const f = { ...filters };

        f[name] = value;

        setFilters(f);
    };

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={6} sm={4} lg={3}>
                    <Dropdown
                        label={<Label text='School' />}
                        menuItems={schools}
                        value={filters.schoolId}
                        onChange={v => onFilterChange('name', v)}
                    />
                </Grid>
                <Grid item xs={6} sm={4} lg={2} paddingLeft="10px">
                    <Dropdown
                        label={<Label text='School Code' />}
                        menuItems={codes}
                        value={filters.statusId}
                        onChange={v => onFilterChange('code', v)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default SchoolManagementFilters;