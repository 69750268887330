import { Cancel, Save } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

export default function EditQuestionActionButtons({ save, discardChanges }) {
    return (
        <Box sx={{ height: '40px' }}>
            <IconButton color='secondary' onClick={save}><Save /></IconButton>
            <IconButton color='error' onClick={discardChanges}><Cancel /></IconButton>
        </Box>
    );
}