import React from 'react';
import './wizard.scss';

export default function StepsIndicator({ steps, currentStep, changeStep, lastSavedStep }) {
    return (
        <ul className="step-control">
            {steps.map((step, index) => {
                const i = index;

                if (i <= lastSavedStep)
                    return <li key={i} className={`completed${i !== currentStep ? ' active' : ''}`} onClick={() => changeStep(step.id)}>{step.description}</li>;
                else
                    return <li key={i}>{step.description}</li>;
            })}
        </ul>
    );
}