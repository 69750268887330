import { Box, Card, CardContent, Stack, Divider, Typography } from '@mui/material';
import PillarXpLogo from '../../images/PillarXP-Logo-Alt.svg';

export default function SelfRegistrationConfirmationMessage() {
    return (
        <Box
            component='div'
            sx={{
                display: 'flex',
                height: '100vh',
                backgroundColor: '#e7e7e7'
            }}
        >
            <Card
                variant='outlined'
                sx={{
                    margin: 'auto',
                    padding: '1em',
                    borderRadius: '1em',
                    border: '1px solid #cccccc',
                    textAlign: 'center',
                    maxWidth: 455
                }}
            >
                <CardContent>
                    <Stack
                        spacing={2}
                    >
                        <img
                            src={PillarXpLogo}
                            alt='logo'
                            style={{ margin: '1em 3em' }}
                        />
                        <Divider
                            sx={{ margin: '1em 0 !important' }}
                        />
                        <Typography
                            paragraph
                        >
                            Thank you for self-registering to create an account. A site administrator must review your request for access. Once reviewed, you will receive an email regarding your status and further instructions.
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    )
}