import React from 'react';
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  border-bottom-color: lightgrey;
`;

const SchoolBuildingDataTable = ({ schoolBuildings }) => {
    return (
        <TableContainer>
            <Divider />
            <Table size='small' aria-label='school buildings table'>
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{ width: '188px ' }} />
                        <StyledTableCell>Building Name</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schoolBuildings.map((building) => (
                        <TableRow key={building.id}>
                            <StyledTableCell sx={{ width: '188px ' }} />
                            <StyledTableCell>{building.name}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SchoolBuildingDataTable;