import { useState } from 'react';
import { Box, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DragIndicator, Edit } from '@mui/icons-material';
import { useSortable } from '@dnd-kit/sortable';
import QuestionActionButtons from './QuestionActionButtons';
import EditQuestionActionButtons from './EditQuestionActionButtons';
import BorderCard from '../../../Common/Containers/BorderCard';
import { SurveyQuestionTypeEnum } from '../../../../utility/enums';
import QuestionTypePreview from './QuestionTypePreview';
import QuestionTypeForm from './QuestionTypeForm';
import QuestionProperties from './QuestionProperties';

export default function QuestionBase({ isDragging, question, defaultPrompt, isEditing, setIsEditing, onCopy, onDelete, onChange, formErrors, setFormErrors }) {
    const [prevPrompt, setPrevPrompt] = useState('');
    const [prevOptions, setPrevOptions] = useState([]);
    const { ordinal, prompt, scoringTypeId, surveyQuestionTypeId, surveyQuestionOptions } = question;

    const { listeners, setNodeRef } = useSortable({
        id: !isDragging ? ordinal : `${ordinal}-dragging`,
    });

    const resetQuestionState = () => {
        setFormErrors({});
        setIsEditing(false);
        setPrevPrompt('');
        setPrevOptions([]);
    };

    const isQuestionValid = () => {
        const errors = {};

        errors['promptField'] = !prompt ? 'Question text is required' : null;
        errors['optionsArray'] = surveyQuestionTypeId !== SurveyQuestionTypeEnum.SHORT_ANSWER && surveyQuestionOptions.length < 1 ? 'At least one option is required' : null;

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    };

    const handlePromptChange = (e) => {
        onChange(ordinal, { prompt: e.target.value });
        setFormErrors(prev => ({ ...prev, 'promptField': null }));
    };

    const handlePromptBlur = () => {
        if (!prompt || !prompt.trim()) {
            setFormErrors(prev => ({ ...prev, 'promptField': 'Question text is required' }));
        }
    };

    const handleOptionsChange = (newOptions) => {
        onChange(ordinal, { surveyQuestionOptions: newOptions });
    };

    const handleQuestionTypeSwitchToggle = () => {
        const newType = surveyQuestionTypeId === SurveyQuestionTypeEnum.CHECKBOX_CHOICES ? SurveyQuestionTypeEnum.MULTIPLE_CHOICE : SurveyQuestionTypeEnum.CHECKBOX_CHOICES;
        onChange(ordinal, { surveyQuestionTypeId: newType });
    };

    const handleDiscardChanges = () => {
        onChange(ordinal, { prompt: prevPrompt, surveyQuestionOptions: prevOptions });
        resetQuestionState();
    };

    const handleSaveQuestion = () => {
        if (!isQuestionValid())
            return;
        resetQuestionState();
    };

    const handleEditQuestion = () => {
        setPrevPrompt(prompt);
        setPrevOptions(surveyQuestionOptions);
        if (prompt === defaultPrompt)
            onChange(ordinal, { prompt: '' });
        setIsEditing(true);
    };

    const handleRequiredToggle = () => {
        onChange(ordinal, { isRequired: !question.isRequired });
    };

    const handleScoringTypeChange = (newValue) => {
        onChange(ordinal, { scoringTypeId: newValue ? newValue.id : '' });
    };

    return (
        <Box ref={setNodeRef} sx={{ pr: '15px' }}>
            <BorderCard sx={{ pl: '15px', pt: '20px', pb: '15px' }}>
                <Stack spacing={3}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: '25px',
                        pr: '15px'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '25px' }}>
                            <Typography sx={{ textAlign: 'left' }}>Q{ordinal}</Typography>
                            <Box
                                {...listeners}
                                sx={{
                                    cursor: 'grab',
                                    color: 'grey.400',
                                    pt: '2px',
                                }}
                            >
                                <DragIndicator />
                            </Box>
                        </Box>
                        {!isEditing ?
                            <>
                                <Stack spacing={2} sx={{ flex: 1 }}>
                                    <Typography align='left' variant='body1'>
                                        {prompt}
                                    </Typography>
                                    <QuestionTypePreview
                                        questionTypeId={surveyQuestionTypeId}
                                        ordinal={ordinal}
                                        surveyQuestionOptions={surveyQuestionOptions}
                                    />
                                </Stack>
                                <IconButton color='secondary' onClick={handleEditQuestion}><Edit /></IconButton>
                            </>
                            :
                            <>
                                <Stack spacing={2} sx={{ flex: 1 }}>
                                    <Box sx={{ pr: '55px' }}>
                                        <TextField
                                            id={`${ordinal}-prompt-input`}
                                            label={defaultPrompt}
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            fullWidth
                                            value={prompt}
                                            onBlur={handlePromptBlur}
                                            error={!!formErrors?.['promptField']}
                                            helperText={formErrors?.['promptField']}
                                            onChange={handlePromptChange}
                                        />
                                    </Box>
                                    <QuestionTypeForm
                                        questionTypeId={surveyQuestionTypeId}
                                        ordinal={ordinal}
                                        surveyQuestionOptions={surveyQuestionOptions}
                                        formErrors={formErrors}
                                        setFormErrors={setFormErrors}
                                        onOptionsChange={handleOptionsChange}
                                    />
                                </Stack>
                                <EditQuestionActionButtons
                                    save={handleSaveQuestion}
                                    discardChanges={handleDiscardChanges}
                                />
                            </>
                        }
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '25px',
                            pr: '15px',
                        }}
                    >
                        <QuestionProperties
                            handleQuestionTypeSwitchToggle={handleQuestionTypeSwitchToggle}
                            isRequired={question.isRequired}
                            handleRequiredToggle={handleRequiredToggle}
                            surveyQuestionTypeId={surveyQuestionTypeId}
                            scoringTypeId={scoringTypeId}
                            handleScoringTypeChange={handleScoringTypeChange}
                        />
                        <Divider orientation='vertical' flexItem />
                        <QuestionActionButtons
                            ordinal={ordinal}
                            onCopy={onCopy}
                            onDelete={onDelete}
                            handleRequiredToggle={handleRequiredToggle}
                        />
                    </Box>
                </Stack>
            </BorderCard>
        </Box>
    );
}