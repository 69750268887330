import React, { useState } from 'react';
import { CardContent, Typography, TextField, Grid, Button } from '@mui/material';
import { useForgotPasswordMutation } from './ForgotPasswordSlice';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import EmailFoundConfirmation from '../EmailFoundConfirmation/EmailFoundConfirmation';
import AuthCard from '../Common/Auth/AuthCard';

const EmailTextField = styled(TextField)`
    width: 100%; 
    margin-bottom: 5% !important;
    margin-top: 5% !important; 
    color: #707070; 
`;

const CancelButton = styled(Button)`
    font-size: 14px !important; 
    text-transform: none !important; 
    width: 100%; 
    background-color: #000000 !important;
`;

const SubmitButton = styled(Button)`
    font-size: 14px !important; 
    text-transform: none !important; 
    width: 100%; 
    background-color: #011E41 !important;
    &:hover {
        background-color: #026AD3 !important;
    }
`;

export default function ForgotPassword() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [form, setForm] = useState({
        email: '',
        emailError: false
    });

    const [forgotPassword] = useForgotPasswordMutation();
    const navigate = useNavigate();

    function SendResetEmail() {
        var emailError = false;
        if (form.email === '') emailError = true;
        if (emailError) {
            setForm({ ...form, emailError: true });
            return;
        }
        forgotPassword(form.email);
        setIsDialogOpen(true);
    }

    function CancelForm() {
        navigate('/login');
    }

    return (
        <>
            <AuthCard hide={isDialogOpen}>
                <CardContent>
                    <Typography variant="body1" align="center">
                        Please enter your email address to request a password reset for your account.
                    </Typography>
                    <EmailTextField
                        variant="outlined"
                        placeholder='Email Address'
                        size="small"
                        type="email"
                        autoComplete="email"
                        required
                        value={form.email}
                        onChange={(e) => setForm({ ...form, email: e.currentTarget.value, emailError: false })}
                        error={form.emailError}
                        helperText={form.emailError ? 'Please enter a valid email address' : null} />
                    <br />
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 3 }}>
                        <Grid item xs={6}>
                            <CancelButton onClick={() => CancelForm()} variant="contained">Cancel</CancelButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SubmitButton onClick={() => SendResetEmail()} variant="contained">Submit</SubmitButton>
                        </Grid>
                    </Grid>
                </CardContent>
            </AuthCard>
            {
                isDialogOpen &&
                <EmailFoundConfirmation isOpen={isDialogOpen} close={() => { setIsDialogOpen(false); navigate('/login') }} />
            }
        </>
    )
}