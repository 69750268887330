import { Tooltip, List, ListItem, ListItemText, Box, Typography } from '@mui/material';
import { School } from '@mui/icons-material';
import styled from 'styled-components';

const SchoolsBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 6px;
    height: 100%;
`;

export default function SchoolsCell(row) {
    const schools = row.schools || [];
    const schoolsCount = schools.length;

    if (schoolsCount === 0) {
        return null;
    }

    if (schoolsCount === 1) {
        const school = schools[0];
        return (
            <Tooltip
                arrow
                title={
                    <List dense>
                        {school.schoolBuildings.map((building) => (
                            <ListItem key={building.id}>
                                <ListItemText primary={building.name} />
                            </ListItem>
                        ))}
                    </List>
                }
            >
                <Typography
                    variant='body2'
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    {school.name}
                </Typography>
            </Tooltip>
        );
    }

    return (
        <Tooltip
            arrow
            title={
                <List dense disablePadding sx={{ pt: 1 }}>
                    {schools.map((school) => (
                        <ListItem key={school.id}>
                            <ListItemText
                                primary={school.name}
                                secondary={
                                    <List dense component='div'>
                                        {school.schoolBuildings.map((building) => (
                                            <ListItem key={building.id}>
                                                <ListItemText
                                                    secondaryTypographyProps={{ sx: { color: 'white' } }}
                                                    secondary={building.name}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            }
        >
            <SchoolsBox>
                <School />
                <Box>
                    <Typography variant='body2'>
                        x {schoolsCount}
                    </Typography>
                </Box>
            </SchoolsBox>
        </Tooltip>
    );
}