import { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useGetSurveysQuery } from './surveysSlice';
import ContentWrapper from '../Common/ContentWrapper';
import SurveyList from './SurveyList';
import SurveyWidgets from './SurveyWidgets';

export default function SurveyDashboard() {
    const { data: surveys = [], isLoading } = useGetSurveysQuery();
    const [searchParams] = useSearchParams();

    const inboundSurveyId = useMemo(() => searchParams.get('surveyId'), [searchParams]);

    return (
        <ContentWrapper title="Survey Dashboard">
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                        <SurveyList surveys={surveys} inboundSurveyId={inboundSurveyId} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ marginTop: isLoading ? { xs: 6, md: 0 } : 0 }}>
                        <SurveyWidgets />
                    </Grid>
                </Grid>
            </Box>
        </ContentWrapper>
    )
}