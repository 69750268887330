import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
        <Box>
            <Stack spacing={1}>
                <Typography variant='h6'>Page Not Found</Typography>
                <Typography variant='body1'>The page you are looking for does not exist.</Typography>
                <Link to=''>Go to Home</Link>
            </Stack>
        </Box>
    );
}