import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { Footer } from '../Common';
import ExternalTopbar from '../Common/External/ExternalTopbar';
import { useGetSessionQuery } from '../../app/slices/sessionSlice';

export default function ExternalHome() {
    const { data: session = {} } = useGetSessionQuery();

    return (
        <Box sx={{
            display: 'flex'
        }}>
            <ExternalTopbar
                user={session}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    pt: 12,
                    px: 3,
                    pb: 9,
                    backgroundColor: '#ffffff',
                    width: '75%'
                }}
            >
                <Outlet />
            </Box>
            <Box
                component='div'
                sx={{
                    position: 'absolute',
                    left: '0',
                    bottom: '0',
                    width: '100%',
                    backgroundColor: '#000000',
                    color: '#ffffff',
                    zIndex: 1200
                }}
            >
                <Footer />
            </Box>
        </Box>
    );
}