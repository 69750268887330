import { CardContent, CardHeader, Typography, Skeleton } from "@mui/material";
import BorderCard from "../../Common/Containers/BorderCard";

const BasicWidgetSkeleton = (
    <Skeleton variant='text' width={64} height={48} />
);

export default function BasicWidget({ color, textColor = 'black', title, icon: Icon, value, isLoading }) {
    return (
        <BorderCard sx={{
            backgroundColor: color,
            height: '160px',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <CardHeader disableTypography title={
                <Typography variant='body2' align='center' sx={{ color: textColor }}>
                    {title}
                </Typography>
            } />
            <CardContent sx={{ px: .5, py: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', flexWrap: 'wrap-reverse' }}>
                {isLoading ? BasicWidgetSkeleton : (
                    <>
                        <Typography variant='body2' align='center' sx={{ fontSize: '38px', color: textColor }}>
                            {value}
                        </Typography>
                        <Icon sx={{ fontSize: '38px', color: textColor }} />
                    </>
                )}
            </CardContent>
        </BorderCard>
    );
}