import { CardContent, CardHeader, Typography, useTheme, Skeleton } from "@mui/material";
import { TrendingDown, TrendingUp } from "@mui/icons-material";
import BorderCard from "../../Common/Containers/BorderCard";
import { TrendEnum } from "../../../utility/enums";

const TrendWidgetSkeleton = (
    <Skeleton variant='text' width={105} height={48} />
);

export default function TrendWidget({ value, isLoading }) {
    const theme = useTheme();

    const trend = value > 0 ? TrendEnum.UP : TrendEnum.DOWN;

    const getTrendObj = () => {
        if (isLoading) {
            return { bgColor: 'grey.100', textColor: 'black' };
        }
        switch (trend) {
            case TrendEnum.UP:
                return { bgColor: theme.palette.success.lighter, textColor: theme.palette.success.dark };
            case TrendEnum.DOWN:
                return { bgColor: theme.palette.danger.lighter, textColor: theme.palette.danger.dark };
            default:
                return { bgColor: theme.palette.secondary.lighter, textColor: theme.palette.secondary.dark };
        }
    };

    const trendObj = getTrendObj();

    return (
        <BorderCard sx={{
            backgroundColor: trendObj.bgColor,
            height: '160px',
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <CardHeader disableTypography sx={{ pb: 0 }} title={
                <Typography variant='body2' align='center' color={trendObj.textColor}>
                    {isLoading ?
                        TrendWidgetSkeleton
                        :
                        trend === TrendEnum.UP ? "Up from Previous Campaign" : "Down from Previous Campaign"
                    }
                </Typography>
            } />
            <CardContent sx={{ px: .5, py: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px', flexWrap: 'wrap' }}>
                {isLoading ? TrendWidgetSkeleton : (
                    <>
                        <Typography align='center' variant='body2' color={trendObj.textColor} sx={{ fontSize: '38px' }}>
                            {Math.abs(value * 100)}%
                        </Typography>
                        {trend === TrendEnum.UP ?
                            <TrendingUp sx={{ fontSize: '38px', color: trendObj.textColor }} />
                            :
                            <TrendingDown sx={{ fontSize: '38px', color: trendObj.textColor }} />
                        }
                    </>
                )}
            </CardContent>
        </BorderCard>
    );
}