import { ScorecardTrendEnum } from "../../utility/enums";

export const decimalToPercentage = (value) => Math.abs(Math.round(value * 100));

/**
 * @param {*} base baseGrowthPercentage
 * @param {*} target targetGrowthPercentage
 * @param {*} score scorePercentage or growthPercentage
 * @returns Score percentage normalized to a percentage complete out of 100
 */
export const normalizePercentages = (base, target, score) => {
    const absoluteScore = Math.abs(score);

    if (score > target)
        return 100;

    return ((absoluteScore - base) / (target - base)) * 100;
};

export const returnTrendColorObject = (trend) => {
    switch (trend) {
        case ScorecardTrendEnum.NO_BASELINE:
            return {themeColor: 'primary', hexColor: '#ffffff', textColor: '#000000'};
        case ScorecardTrendEnum.IMPROVEMENT:
            return {themeColor: 'success', hexColor: '#c8e6c9', textColor: '#1b5e20'};
        case ScorecardTrendEnum.REGRESSION:
            return {themeColor: 'error', hexColor: '#ffcdd2', textColor: '#b71c1c'};
        default:
            return {themeColor: 'primary', hexColor: '#bac4d4', textColor: '#011e41'};
    }
};