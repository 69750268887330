import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@emotion/react";
import 'react-toastify/dist/ReactToastify.css';
import { RoleEnum } from './utility/enums';
import theme from "./theme";
import Home from "./components/Home";
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import Error from './Error';
import NotFound from './components/Common/NotFound';
import SelfRegistration from './components/SelfRegistration/SelfRegistration';
import SelfRegistrationConfirmationMessage from './components/SelfRegistration/SelfRegistrationConfirmationMessage';
import SurveyDashboard from './components/SurveyDashboard/SurveyDashboard';
import Scorecards from './components/Scorecards';
import SurveyManagementContainer from './components/SurveyManagement/SurveyManagementContainer';
import SystemSettings from './components/SystemSettings';
import Accessibility from './components/Accessibility';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import PasswordReset from './components/PasswordReset';
import UserManagementContainer from './components/SystemSettings/UserManagement/UserManagementContainer';
import SchoolManagementContainer from './components/SystemSettings/SchoolManagement/SchoolManagementContainer';
import CampaignManagementContainer from './components/CampaignManagement/CampaignManagementContainer';
import PillarManagementContainer from './components/SystemSettings/PillarManagement/PillarManagementContainer';
import IntegratorSettings from './components/IntegratorSettings';
import CampaignWizard from './components/CampaignManagement/CampaignWizard';
import ExternalSurveys from './components/ExternalSurveys';
import ExternalHome from './components/ExternalHome';
import { RequireAuth } from './components/Common';
import SurveyManagementWizard from './components/SurveyManagement/Wizard/SurveyManagementWizard';

const { DISTRICT_ADMIN, SCHOOL_ADMIN, FACULTY, INTEGRATOR } = RoleEnum;

const routes = [
    {
        path: 'login',
        element: <Login />,
        error: <Error />
    },
    {
        path: 'forgot-password',
        element: <ForgotPassword />,
        error: <Error />
    },
    {
        path: 'self-registration',
        element: <SelfRegistration />,
        error: <Error />
    },
    {
        path: 'self-registration-confirmation',
        element: <SelfRegistrationConfirmationMessage />,
        error: <Error />
    },
    {
        path: 'reset-password',
        element: <PasswordReset />,
        error: <Error />
    },
    {
        path: 'login/:isExternal',
        element: <Login />,
        error: <Error />
    },
    {
        path: 'ext',
        element: (
            <RequireAuth>
                <ExternalHome />
            </RequireAuth>
        ),
        errorElement: <Error />,
        children: [
            {
                path: 'pending-surveys',
                element: <ExternalSurveys />,
            },
        ]
    },
    {
        path: '',
        element: <Home />,
        errorElement: <Error />,
        children: [
            {
                path: '',
                element: (
                    <RequireAuth>
                        <SurveyDashboard />
                    </RequireAuth>
                ),
            },
            {
                element: <RequireAuth allowedRoles={[FACULTY, SCHOOL_ADMIN, DISTRICT_ADMIN, INTEGRATOR]} />,
                children: [
                    {
                        path: 'scorecards',
                        element: <Scorecards />,
                    },
                    {
                        path: 'survey-template-management',
                        element: <SurveyManagementContainer />,
                    },
                    {
                        path: 'campaign-management',
                        element: <CampaignManagementContainer />,
                    },
                ]
            },
            {
                element: <RequireAuth allowedRoles={[DISTRICT_ADMIN, INTEGRATOR]} />,
                children: [
                    {
                        path: 'system-settings',
                        element: <SystemSettings />,
                        children: [
                            {
                                path: 'user-management',
                                element: <UserManagementContainer />,
                            },
                            {
                                path: 'district-management',
                                element: <SchoolManagementContainer />,
                            },
                            {
                                path: 'pillar-benefit-management',
                                element: <PillarManagementContainer />,
                            },
                            {
                                path: 'subscription-management',
                                element: <UserManagementContainer />,
                            },
                        ]
                    },
                    {
                        path: 'campaign-management/wizard/:id',
                        element: <CampaignWizard />,
                    },
                    {
                        path: 'campaign-management/wizard/new/:copiedTemplateId',
                        element: <CampaignWizard />,
                    },
                    {
                        path: 'survey-template-management/wizard/:id',
                        element: <SurveyManagementWizard />,
                    },
                    {
                        path: 'survey-template-management/wizard/new/:copiedTemplateId',
                        element: <SurveyManagementWizard />,
                    }
                ]
            },
            {
                element: <RequireAuth allowedRoles={[INTEGRATOR]} />,
                children: [
                    {
                        path: 'integrator-settings',
                        element: <IntegratorSettings />,
                    },
                ]
            },
            {
                element: <RequireAuth />,
                children: [
                    {
                        path: 'accessibility',
                        element: <Accessibility />,
                    },
                    {
                        path: 'privacy-policy',
                        element: <PrivacyPolicy />,
                    },
                    {
                        path: 'terms-of-service',
                        element: <TermsOfService />,
                    },
                ]
            },
        ]
    },
    {
        path: '*',
        element: (
            <RequireAuth>
                <ExternalHome />
            </RequireAuth>
        ),
        errorElement: <Error />,
        children: [
            {
                path: '*',
                element: <NotFound />
            }
        ]
    },
];

const router = createBrowserRouter(routes);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
            <ToastContainer position="bottom-right" />
        </ThemeProvider>
    );
}

export default App;
