import { api } from '../../app/api';
const TAG = 'SurveyManagement';

export const surveyTemplateManagementApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getAllSurveyTemplates: builder.query({
            query: () => 'surveyTemplate',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        getSurveyTemplateDetails: builder.mutation({
            query: (id) => `surveyTemplate/${id}`,
            providesTags: [TAG]
        }),
        getSurveyTemplateExpandedDetails: builder.mutation({
            query: ({ id }) => `surveyTemplate/get-expanded-details/${id}`,
            providesTags: [TAG]
        }),
        addSurveyTemplate: builder.mutation({
            query: (request) => ({
                url: 'surveyTemplate',
                method: 'POST',
                body: JSON.stringify(request)
            }),
            invalidatesTags: [TAG]
        }),
        updateSurveyTemplate: builder.mutation({
            query: (request) => ({
                url: 'surveyTemplate',
                method: 'PUT',
                body: JSON.stringify(request)
            }),
            invalidatesTags: [TAG]
        }),
        cancelSurveyTemplate: builder.mutation({
            query: (id) => ({
                url: `surveyTemplate/cancel-survey-template/${id}`,
                method: 'PUT',
                body: JSON.stringify(id)
            }),
            invalidatesTags: [TAG]
        }),
        deleteSurveyTemplate: builder.mutation({
            query: (id) => ({
                url: `surveyTemplate/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [TAG]
        }),
    })
})

export const {
    useGetAllSurveyTemplatesQuery,
    useGetSurveyTemplateDetailsMutation,
    useGetSurveyTemplateExpandedDetailsMutation,
    useAddSurveyTemplateMutation,
    useUpdateSurveyTemplateMutation,
    useCancelSurveyTemplateMutation,
    useDeleteSurveyTemplateMutation,
} = surveyTemplateManagementApi;
