import { useState, useMemo, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';
import ContentWrapper from '../Common/ContentWrapper';
import SurveyCard from '../Common/Surveys/SurveyCard';
import ConfirmationModal from '../Common/ConfirmationModal';
import { useGetPendingSurveysQuery } from '../SurveyDashboard/surveysSlice';
import SurveyCompletionModal from '../Common/Surveys/SurveyCompletionModal';
import SurveyCardSkeleton from '../Common/Skeletons/SurveyCardSkeleton';
import { useGetSurveyStatusMutation } from '../SurveyDashboard/surveysSlice';
import { SystemStatusEnum } from '../../utility/enums';
import { toast } from 'react-toastify';

export default function SurveysExternal() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [isSurveyOpen, setIsSurveyOpen] = useState(false);

    const [searchParams] = useSearchParams();
    const inboundSurveyId = useMemo(() => searchParams.get('surveyId'), [searchParams]);

    const theme = useTheme();

    const { data: surveys = [], isLoading } = useGetPendingSurveysQuery();
    const [getSurveyStatus] = useGetSurveyStatusMutation();

    const openModal = (survey) => {
        setSelectedSurvey(survey);
        setIsModalOpen(true);
    };

    const launchSurvey = () => {
        setIsModalOpen(false);
        setIsSurveyOpen(true);
    };

    useEffect(() => {
        if (!inboundSurveyId || isLoading)
            return;

        getSurveyStatus({ id: inboundSurveyId })
            .then(response => {
                const d = response.data;

                if (d.systemStatusId !== SystemStatusEnum.ACTIVE) {
                    toast.error('The selected survey has been deleted and is no longer available.');
                    return;
                }

                if (d.isExpired) {
                    toast.error('The selected survey has expired.');
                    return;
                }

                const s = surveys.find(x => x.id === Number(inboundSurveyId));

                if (s) {
                    setIsModalOpen(true);
                    setSelectedSurvey(s);
                }
            })
            .catch(e => {
                console.log(e);
                toast.error('The selected survey has been deleted and is no longer available.');
            })
    }, [inboundSurveyId, isLoading]);

    return (
        <>
            <ContentWrapper title="Pending Surveys">
                <Stack spacing={2} sx={{ maxWidth: theme.breakpoints.values.md, pt: 2, margin: '0 auto' }}>
                    {!isLoading ? (
                        surveys.length > 0 ? (
                            surveys.map(survey => (
                                <ButtonBase key={survey.id} onClick={() => openModal(survey)}>
                                    <SurveyCard survey={survey} hasButton openModal={openModal} />
                                </ButtonBase>
                            ))
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt: 4 }}>
                                <Typography variant='body2'>No pending surveys</Typography>
                            </Box>
                        )
                    ) : (
                        Array.from({ length: 1 }).map((_, i) => (
                            <SurveyCardSkeleton key={i} />
                        ))
                    )}
                    {surveys.length > 5 && (
                        <Typography align='center' variant='body1'>
                            To access additional surveys, please log in to Pillar XP.
                        </Typography>
                    )}
                </Stack>
            </ContentWrapper>
            {selectedSurvey && (
                <ConfirmationModal
                    isOpen={isModalOpen}
                    close={() => setIsModalOpen(false)}
                    onConfirm={launchSurvey}
                    hideIcon
                    title={`Would you like to begin ${selectedSurvey.name}?`}
                    message={`This survey should take you approximately ${selectedSurvey.timeToComplete} minutes to complete. If you are unable to finish, your data will be lost.`}
                    saveBtnText="Confirm"
                    cancelBtnText="Cancel"
                />
            )}
            <SurveyCompletionModal open={isSurveyOpen} onClose={() => setIsSurveyOpen(false)} survey={selectedSurvey} />
        </>
    )
}