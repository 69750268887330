import { api } from '../../../app/api';
const TAG = 'SurveyTemplateWizard';

export const surveyTemplateWizardApi = api.injectEndpoints({
    endpoints: (builder) => ({
        isTitleInUse: builder.query({
            query: (title) => `surveyTemplate/title-in-use?title=${title}`
        }),
        isScheduleValid: builder.query({
            query: ({cronSchedule, startDate, endDate}) => `surveyTemplate/is-schedule-valid?cronSchedule=${encodeURIComponent(cronSchedule)}&startDate=${startDate}&endDate=${endDate}`
        }),
        hasRecipients: builder.mutation({
            query: (body) => ({
                url: 'surveyTemplate/has-recipients',
                method: 'POST',
                body: JSON.stringify(body)
            }),
            invalidatesTags: () => [{ type: TAG }]
        })
    })
})

export const {
    useLazyIsTitleInUseQuery,
    useLazyIsScheduleValidQuery,
    useHasRecipientsMutation
} = surveyTemplateWizardApi;