import React from 'react';
import { DialogContent, DialogContentText, Divider } from '@mui/material';
import AuthDialog from '../Common/Auth/AuthDialog';

export default function EmailFoundConfirmation({ isOpen, close }) {
  return (
    <AuthDialog
      isOpen={isOpen}
      close={close}
    >
      <DialogContent sx={{ paddingBottom: '10%' }}>
        <Divider sx={{ marginBottom: "5%" }} light />
        <DialogContentText id="alert-dialog-description" color='black' align='center'>
          Thank you. If a profile is found, a password reset link will be sent to the email submitted.
        </DialogContentText>
      </DialogContent>
    </AuthDialog>
  );
}