import { api, logout } from '../api';
import { clearSession } from "../../utility";

export const logoutMiddleware = ({ dispatch }) => next => action => {
    if (action.type === logout.type) {
        clearSession();
        dispatch(api.util.resetApiState());
    }

    return next(action);
};