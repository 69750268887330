import React, { useEffect, useState } from "react";
import { Box, Checkbox, Chip, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, Radio, RadioGroup, Skeleton, Stack, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { toast } from "react-toastify";
import { SurveyQuestionTypeEnum } from "../../../utility/enums";
import DialogActionButtons from "../Buttons/DialogActionButtons";
import { usePostSurveyCompletionMutation } from "../../SurveyDashboard/surveysSlice";
import { useGetSurveyTemplateQuestionsQuery } from "../../SurveyDashboard/surveyTemplatesSlice";

export default function SurveyCompletionModal({ open, onClose, survey, surveyTemplateId, surveyName, isPreview, previewQuestions }) {
    const [responses, setResponses] = useState({});
    const [surveyErrors, setSurveyErrors] = useState({});
    const [dataError, setDataError] = useState(null);
    const [surveyQuestions, setSurveyQuestions] = useState( previewQuestions) 
    const { data: surveyQuestionsData, isLoading, error } = useGetSurveyTemplateQuestionsQuery(survey?.surveyTemplateId || surveyTemplateId, {
        skip: !open || previewQuestions
    });
    
    const [postSurveyCompletion, { isLoading: isPosting }] = usePostSurveyCompletionMutation();

    useEffect(() => {
        if (error) {
            setDataError(error);
        }
    }, [error]);
    
    useEffect(() => {
        if(previewQuestions){
            setSurveyQuestions( previewQuestions)
        }
        else{
            setSurveyQuestions(surveyQuestionsData);
        }
    }, [surveyQuestionsData, previewQuestions])

    useEffect(() => {
        if (open && dataError) {
            onClose();
            toast.error("Error loading survey");
            setDataError(null);
        } else if (open && !isLoading && surveyQuestions && surveyQuestions?.length <= 0) {
            onClose();
            toast.error("No survey questions found");
        }
    }, [isLoading, surveyQuestions, dataError, onClose, open]);

    const clearErrorForQuestion = (questionId) => {
        if (surveyErrors[questionId]) {
            const newErrors = { ...surveyErrors };
            delete newErrors[questionId];
            setSurveyErrors(newErrors);
        }
    };

    const handleCheckboxChange = (questionId, value) => {
        setResponses(prev => {
            const currentValues = prev[questionId] ? prev[questionId].value : [];
            const newValue = currentValues.includes(value) ? currentValues.filter(v => v !== value) : [...currentValues, value];
            return { ...prev, [questionId]: { type: 'checkbox', value: newValue } };
        });
        clearErrorForQuestion(questionId);
    };

    const handleRadioChange = (questionId, value) => {
        setResponses(prev => ({ ...prev, [questionId]: { type: 'radio', value: value } }));
        clearErrorForQuestion(questionId);
    };

    const handleTextChange = (questionId, value) => {
        setResponses(prev => ({ ...prev, [questionId]: { type: 'text', value: value } }));
        clearErrorForQuestion(questionId);
    };

    const renderOptions = (questionId, surveyQuestionTypeId, options) => {
        switch (surveyQuestionTypeId) {
            case SurveyQuestionTypeEnum.YES_OR_NO:
            case SurveyQuestionTypeEnum.MULTIPLE_CHOICE:
                return (
                    <RadioGroup
                        onChange={(event) => handleRadioChange(questionId, event.target.value)}
                        sx={{ mt: 2 }}
                    >
                        {options.map((uo,index) => (
                            <Box key={`option-entry-${uo.id}`} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Radio color='primary' />}
                                    label={uo.title}
                                    value={uo.id || index}
                                />
                            </Box>
                        ))}
                    </RadioGroup>
                );
            case SurveyQuestionTypeEnum.CHECKBOX_CHOICES:
                return (
                    <FormGroup
                        sx={{ mt: 2 }}
                    >
                        {options.map(uo => (
                            <Box key={`option-entry-${uo.id}`} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={<Checkbox color='primary' />}
                                    label={uo.title}
                                    value={uo.id}
                                    onChange={() => handleCheckboxChange(questionId, uo.id)}
                                />
                            </Box>
                        ))}
                    </FormGroup>
                );
            case SurveyQuestionTypeEnum.SHORT_ANSWER:
                return (
                    <TextField
                        id='short-answer-input'
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={3}
                        sx={{ mt: 3, mb: 1 }}
                        onChange={(event) => handleTextChange(questionId, event.target.value)}
                        error={surveyErrors[questionId] !== undefined}
                    />
                );
            default:
                return null;
        }
    };

    const resetResponses = () => {
        setResponses({});
        setSurveyErrors({});
    };

    const mapResponsesToDtoProperty = () => {
        return Object.keys(responses).flatMap(qId => {
            const response = responses[qId];
            switch (response.type) {
                case 'checkbox':
                    return response.value.map(responseOptionId => ({
                        surveyQuestionId: parseInt(qId),
                        surveyQuestionOptionId: responseOptionId
                    }));
                case 'radio':
                    return [{
                        surveyQuestionId: parseInt(qId),
                        surveyQuestionOptionId: parseInt(response.value)
                    }];
                case 'text':
                    return [{
                        surveyQuestionId: parseInt(qId),
                        openEndedValue: response.value
                    }];
                default:
                    return [];
            }
        });
    };

    const validateResponses = () => {
        const requiredQuestions = surveyQuestions.filter(q => q.isRequired);
        let errors = {};

        requiredQuestions.forEach(q => {
            const response = responses[q.id];
            if (!response) {
                errors[q.id] = "Response is required";
            } else {
                if (response.type === 'text' && !response.value.trim()) {
                    errors[q.id] = "Response is required";
                }
            }
        });

        if (Object.keys(errors).length > 0) {
            return {
                isValid: false,
                errors
            };
        }

        return {
            isValid: true
        };
    };

    const handleDone = () => {
        const validation = validateResponses();
        if (!validation.isValid) {
            Object.keys(validation.errors).forEach(() => {
                setSurveyErrors(validation.errors);
            });
            return;
        }

        const dto = {
            surveyId: survey?.id,
            surveySessionId: survey?.surveySessionId,
            surveyQuestionResponseRequests: mapResponsesToDtoProperty()
        };

        postSurveyCompletion(dto)
            .unwrap()
            .then(res => {
                if (res?.message) {
                    console.error(res.message);
                    toast.error('Error submitting survey');
                    return;
                }
                toast.success('Survey submitted successfully');
                onClose();
                resetResponses();
            })
            .catch(err => {
                console.error(err);
                toast.error('Error submitting survey');
            });
    };

    const handleCancel = () => {
        onClose();
        resetResponses();
    };

    const SurveyQuestionSkeleton = () => (
        <>
            <Skeleton variant='text' width={400} height={35} sx={{ mb: 2 }} />
            <Stack spacing={1}>
                {[180, 260, 180, 260].map((width, i) => (
                    <Skeleton key={i} variant='text' width={width} height={29} />
                ))}
            </Stack>
        </>
    );

    const LoadingSurveyQuestionsSkeleton = () => (
        <>
            {Array.from({ length: 5 }, (_, i) => (
                <React.Fragment key={`survey-questions-skeleton-${i}`}>
                    <SurveyQuestionSkeleton />
                    {i < 4 && <Divider sx={{ my: 2 }} />}
                </React.Fragment>
            ))}
        </>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='md'
            scroll='paper'
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 5 }}>
                    <Box>
                        {survey?.name || surveyName}
                    </Box>
                    {isPreview &&
                        <Chip
                            label={"Preview"}
                            color='secondary'
                            sx={{
                                alignSelf: 'flex-end',
                                color: 'secondary.dark',
                                backgroundColor: 'secondary.lighter',
                            }}
                        />
                    }

                </Box>
                <IconButton
                    aria-label='close'
                    onClick={handleCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                {isLoading || dataError ?
                    <LoadingSurveyQuestionsSkeleton /> :
                    surveyQuestions?.map((q, i) => (
                        <Box key={q.id}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel
                                    sx={{ color: (theme) => theme.palette.secondary.main, fontSize: '1.1rem' }}
                                >
                                    {`${q.ordinal}. ${q.prompt} ${q.isRequired ? "*" : ""}`}
                                </FormLabel>
                                {!isPreview &&
                                <Chip
                                    label={q.scoringTypeName}
                                    color='secondary'
                                    sx={{
                                        alignSelf: 'flex-end',
                                        color: 'secondary.dark',
                                        backgroundColor: 'secondary.lighter',
                                    }}
                                />}
                            </Box>
                            {renderOptions(q.id, q.surveyQuestionTypeId, q.surveyQuestionOptions)}
                            {surveyErrors[q.id] && (
                                <FormHelperText error>{surveyErrors[q.id]}</FormHelperText>
                            )}
                            {surveyQuestions.length - 1 !== i ?
                                <Divider sx={{ my: 3 }} />
                                :
                                <Box sx={{ mb: 2 }} />
                            }
                        </Box>
                    ))}
            </DialogContent>
            <Divider />
            <DialogActionButtons
                submitText="Done"
                closeText={isPreview ? "Close" : "Cancel"}
                onSubmit={handleDone}
                onClose={handleCancel}
                isSubmitDisabled={isLoading}
                isSubmitHidden={isPreview}
                isLoading={isPosting}
                disableLoadingText
            />
        </Dialog >
    )
}