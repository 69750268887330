import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { List, Typography, IconButton, ListItem, ListItemButton, ListItemText, Skeleton, Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Article, Menu, Dashboard, Settings, SwitchAccount, AccessTimeFilled } from '@mui/icons-material';
import { OtherAdmissionIcon } from '../Icons';
import PillarXpLogo from '../../images/PillarXP-Logo-Alt.svg';
import { RoleEnum } from '../../utility/enums';

const drawerWidth = 310;
const appBarHeight = 65;

const { DISTRICT_ADMIN, SCHOOL_ADMIN, FACULTY, PARENT, STUDENT, INTEGRATOR } = RoleEnum;

const menuOptions = [
    {
        name: 'Survey Dashboard',
        roles: [DISTRICT_ADMIN, SCHOOL_ADMIN, FACULTY, PARENT, STUDENT, INTEGRATOR],
        icon: <Article />,
        path: ''
    },
    {
        name: 'Scorecards',
        roles: [DISTRICT_ADMIN, SCHOOL_ADMIN, FACULTY, INTEGRATOR],
        icon: <Dashboard />,
        path: 'scorecards'
    },
    {
        name: 'Campaign Management',
        roles: [DISTRICT_ADMIN, SCHOOL_ADMIN, INTEGRATOR],
        icon: <AccessTimeFilled />,
        path: 'campaign-management'
    },
    {
        name: 'Survey Template Management',
        roles: [DISTRICT_ADMIN, INTEGRATOR],
        icon: <OtherAdmissionIcon />,
        path: 'survey-template-management'
    },
    {
        name: 'System Settings',
        roles: [DISTRICT_ADMIN, INTEGRATOR],
        icon: <Settings />,
        path: 'system-settings/user-management'
    },
    {
        name: 'Integrator Settings',
        roles: [INTEGRATOR],
        icon: <SwitchAccount />,
        path: 'integrator-settings'
    }
];

const openedMixin = () => ({
    width: drawerWidth,
});

const closedMixin = (theme) => ({
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000',
    color: '#ffffff',
    height: appBarHeight,
    padding: '0 20px',
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    display: 'flex',
    flexDirection: 'colum',
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(5),
    backgroundColor: '#e6e6e6',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, selected, open }) => ({
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    color: selected ? '#ffffff' : 'inherit',
    backgroundColor: selected ? '#0180FF' : 'inherit',
    '&:hover': {
        backgroundColor: '#0180FF',
        color: '#ffffff',
    },
    '&.Mui-selected': {
        backgroundColor: '#0180FF',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#0180FF',
            color: '#ffffff',
        },
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#0180FF',
        color: '#ffffff',
    },
}));


export default function Sidebar({ open, isFetching, toggleOpen, schoolDistrict, userRoles }) {

    const [selected, setSelected] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const basePath = location.pathname.split('/')[1];
        const index = menuOptions.findIndex(x => x.path.split('/')[1] === basePath);
        setSelected(index);
    }, [location.pathname]);

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                {open && (
                    <Typography variant="h6" component="div" sx={{ mr: "auto" }}>
                        {schoolDistrict}
                    </Typography>
                )}
                <IconButton
                    onClick={() => toggleOpen()}
                    sx={{
                        color: "#ffffff",
                        p: 0,
                    }}
                >
                    <Menu />
                </IconButton>
            </DrawerHeader>
            <List sx={{ py: 0 }}>
                {isFetching
                    ? Array.from(new Array(5)).map((_, index) => (
                        <ListItem
                            key={index}
                            disablePadding
                            sx={{
                                display: "block",
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: "center",
                                    px: 2.5,
                                }}
                            >
                                <Skeleton variant="circular" width={25} height={25} />
                            </ListItemButton>
                        </ListItem>
                    ))
                    : menuOptions.map((obj, index) => {
                        if (!obj.roles.some((x) => userRoles?.some((y) => y.roleId === x)))
                            return null;
                        let isSelected = selected === index;

                        return (
                            <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                    display: "block",
                                }}
                            >
                                <StyledListItemButton
                                    selected={isSelected}
                                    open={open}
                                    onClick={() => {
                                        navigate(obj.path);
                                    }}
                                >
                                    {open && <ListItemText primary={obj.name} />}
                                    <Tooltip title={obj.name} placement="right">
                                        {obj.icon}
                                    </Tooltip>
                                </StyledListItemButton>
                            </ListItem>
                        );
                    })}
            </List>
            {open && (
                <img
                    src={PillarXpLogo}
                    alt="logo"
                    style={{
                        margin: "auto 1.5em 4.5em",
                    }}
                />
            )}
        </Drawer>
    );
}