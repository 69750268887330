import { Box, Dialog, DialogTitle } from "@mui/material";
import PillarXpLogo from '../../../images/PillarXP-Logo-Alt.svg';


export default function AuthDialog({ isOpen, close, children }) {
    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-describedby="alert-dialog-description"
            slotProps={{ backdrop: { invisible: true } }}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    boxShadow: 'none',
                    maxWidth: '450px',
                },
            }}
        >
            <DialogTitle sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
                mb: 2,
                mx: 2,
            }}
            >
                <Box component="img" src={PillarXpLogo} alt='logo' />
            </DialogTitle>
            {children}
        </Dialog>
    );
}