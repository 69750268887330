import { api } from "../api";

const TAG = 'Rotations';

export const rotationsApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: builder => ({
        getRotations: builder.query({
            query: () => '/lookup/rotations',
            providesTags: [TAG]
        })
    })
})

export const {
    useGetRotationsQuery
} = rotationsApi