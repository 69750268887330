import { useState } from "react";
import { Card, FormHelperText, Typography } from "@mui/material";
import { useGetDistrictPillarsWithBenefitsQuery } from '../../../../app/slices/pillarsWithBenefitsSlice';
import WizardButtonGroup from '../../../Common/Buttons/WizardButtonGroup';
import PillarsWithBenefitsAccordion from "../../../Common/FormElements/PillarsWithBenefitsAccordion";

export default function CampaignWizardStepTwo({ pillars, setPillars, benefits, setBenefits, setPillarBenefits, isEdit, gotoPrevStep, handleStepComplete }) {
    const [error, setError] = useState(null);

    const { data: pillarBenefitsData, isLoading } = useGetDistrictPillarsWithBenefitsQuery();

    const handleSetPillars = (selectedPillars) => setPillars(selectedPillars);

    const handleSetBenefits = (selectedBenefits) => {
        setError(null);
        setBenefits(selectedBenefits); 
    };

    const toNextStep = () => {
        if (benefits.length === 0) {
            setError("Please select at least one benefit");
            return;
        }
        const selectedPillarBenefits = pillarBenefitsData
            .filter(pillar => pillars.includes(pillar.id))
            .map(pillar => ({
                id: pillar.id,
                name: pillar.name,
                benefits: pillar.benefits.filter(benefit => benefits.includes(benefit.id))
            }));

        setPillarBenefits(selectedPillarBenefits);
        handleStepComplete();
    };

    return (
        <Card
            sx={{
                flexBasis: '75%',
                maxWidth: '550px',
                padding: '25px',
                mb: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <Typography sx={{ pb: 1 }}>
                {isEdit ?
                    <span>
                        <span style={{ fontWeight: 'bold' }}>Edit Benefits. </span>
                        The pillar and benefits selected will be measured through the duration of this campaign.
                    </span>
                    :
                    <span>
                        <span style={{ fontWeight: 'bold' }}>Select Benefits. </span>
                        The pillar and benefits selected will be measured through the duration of this campaign.
                    </span>}
            </Typography>
            <PillarsWithBenefitsAccordion
                pillarBenefits={pillarBenefitsData}
                isLoading={isLoading}
                selectedBenefits={benefits}
                setSelectedBenefits={handleSetBenefits}
                selectedPillars={pillars}
                setSelectedPillars={handleSetPillars} />
            <FormHelperText error>{error}</FormHelperText>
            <WizardButtonGroup fullWidth backClick={gotoPrevStep} forwardClick={toNextStep} />
        </Card>
    );
} 