import { ButtonGroup, Button } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import ContentWrapper from '../Common/ContentWrapper';

export default function SystemSettings() {
    const navigate = useNavigate();
    const location = useLocation();

    const isUserManagementActive = location?.pathname.includes('/system-settings/user-management');
    const isDistrictManagementActive = location?.pathname.includes('/system-settings/district-management');
    const isPillarBenefitManagementActive = location?.pathname.includes('/system-settings/pillar-benefit-management');

    // Removed subscription management tab until it is implemented
    //const isSubscriptionManagementActive = location?.pathname.includes('/system-settings/subscription-management');

    return (
        <ContentWrapper title="System Settings">
            <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ boxShadow: 'none', borderRadius: 'none', justifyContent: 'center' }}>
                <Button
                    variant={isUserManagementActive ? "contained" : "outlined"}
                    sx={{ borderColor: '#0180ff !important' }}
                    color="secondary"
                    onClick={() => navigate('/system-settings/user-management')}
                >
                    User Management
                </Button>
                <Button
                    variant={isDistrictManagementActive ? "contained" : "outlined"}
                    sx={{ borderColor: '#0180ff !important' }}
                    color="secondary"
                    onClick={() => navigate('/system-settings/district-management')}
                >
                    School Management
                </Button>
                <Button
                    variant={isPillarBenefitManagementActive ? "contained" : "outlined"}
                    sx={{ borderColor: '#0180ff !important' }}
                    color="secondary"
                    onClick={() => navigate('/system-settings/pillar-benefit-management')}
                >
                    Pillar/Benefit Management
                </Button>
                {/* <Button
                        variant={isSubscriptionManagementActive ? "contained" : "outlined"}
                        sx={{ borderColor: '#0180ff !important' }}
                        color="secondary"
                        onClick={() => navigate('/system-settings/subscription-management')}
                    >
                        Manage Subscription
                    </Button> */}
            </ButtonGroup>
            <Outlet />
        </ContentWrapper>
    )
}