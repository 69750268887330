import { CardContent, Typography, Chip, Box, Grid, IconButton, useTheme } from '@mui/material';
import { ArrowForward, AvTimer, CheckCircleOutline } from '@mui/icons-material';
import BorderCard from '../Containers/BorderCard';
import { SurveyStatusEnum } from '../../../utility/enums';
import dayjs from 'dayjs';

export default function SurveyCard({ survey, hasButton, openModal }) {
    const theme = useTheme();

    const statusMap = {
        [SurveyStatusEnum.PENDING]: {
            icon: <AvTimer />,
            label: "Pending",
            color: 'secondary'
        },
        [SurveyStatusEnum.COMPLETE]: {
            icon: <CheckCircleOutline />,
            label: "Completed",
            color: 'success'
        }
    };

    const status = statusMap[survey.surveyStatusId];
    const expirationDateString = dayjs(survey.expirationDate).format('MM/DD/YYYY');
    const receivedDateString = dayjs(survey.receivedDate).format('MM/DD/YYYY');
    const completedOnDateString = survey.completedDate ? dayjs(survey.completedDate).format('MM/DD/YYYY') : null;
    const daysUntilExpiry = dayjs(survey.expirationDate).diff(dayjs(), 'day');

    const expiresInString = daysUntilExpiry > 0 
        ? `Expires in ${daysUntilExpiry} days (${expirationDateString})` 
        : daysUntilExpiry === 0 
            ? `Expires today (${expirationDateString})` 
            : "Expired";
    const completedOnString = `Completed on ${completedOnDateString}`;

    const StatusChip = () => {
        return (
            <Chip
                icon={status.icon}
                label={status.label}
                color={status.color}
                sx={{
                    alignSelf: 'flex-end',
                    color: theme.palette[status.color].dark,
                    backgroundColor: theme.palette[status.color].lighter
                }}
            />
        );
    };

    return (
        <BorderCard>
            <CardContent>
                <Grid container direction='column' spacing={1}>
                    <Grid item>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', mr: 2 }}>
                                <Typography variant='h6' sx={{ fontWeight: 'bold', mr: 2 }}>
                                    {survey.name}
                                </Typography>
                                <Typography align='left' variant='body1' color='text.secondary' sx={{ mb: 1 }}>
                                    {survey.completedDate ? completedOnString : expiresInString}
                                </Typography>
                            </Box>
                            <StatusChip />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography align='left' variant='body1' color='text.secondary'>
                            Received: {receivedDateString}
                        </Typography>
                        <Typography align='left' variant='body1' color='text.secondary' sx={{ pr: 6 }}>
                            Time to Complete: {survey.minutesToComplete} minutes
                        </Typography>
                    </Grid>
                </Grid>
                {hasButton && survey.surveyStatusId === SurveyStatusEnum.PENDING && (
                    <IconButton
                        onClick={() => openModal(survey)}
                        aria-label="start-survey"
                        size='large'
                        sx={{ position: 'absolute', bottom: '10px', right: '15px' }}
                    >
                        <ArrowForward fontSize='inherit' />
                    </IconButton>
                )}
            </CardContent>
        </BorderCard>
    );
}