import { Card } from "@mui/material";
import styled from "styled-components";

const StyledCard = styled(Card)({
    position: 'relative',
    width: '100%',
    border: '1px solid #9F9F9F',
    borderRadius: '5px',
});

export default function BorderCard({ children, ...props }) {
    return (
        <StyledCard elevation={0} {...props}>
            {children}
        </StyledCard>
    );
};