import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';

export default function ContentWrapper({ title, children }) {
    const theme = useTheme();

    return (
        <Stack
            spacing={2}
            sx={{
                maxWidth: theme.breakpoints.values.xl,
                mx: 'auto',
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <Typography
                    fontWeight={500}
                    color='secondary'
                    variant='body1'
                    component='h1'
                >
                    {title}
                </Typography>
            </Box>
            {children}
        </Stack>
    );
}