import { api } from "../api";

export const schoolsApi = api.injectEndpoints({
    tagTypes: ['Schools'],
    endpoints: builder => ({
        getSchools: builder.query({
            query: () => '/lookup/schools',
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'School', id })), { type: 'DistrictSpecific' }]
                    : ['School', { type: 'DistrictSpecific' }],
        }),
        getSchoolsWithBuildings: builder.query({
            query: () => '/lookup/schools-with-buildings',
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'School', id })), { type: 'DistrictSpecific' }]
                    : ['School', { type: 'DistrictSpecific' }],
        })
    })
});

export const {
    useGetSchoolsQuery,
    useLazyGetSchoolsQuery,
    useGetSchoolsWithBuildingsQuery,
    useLazyGetSchoolsWithBuildingsQuery
} = schoolsApi