import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, ListItem, ListItemText, List, ListItemIcon, Box, IconButton, Divider, Typography, ListItemButton, Tooltip, Switch, FormControlLabel, Chip, Skeleton } from '@mui/material';
import { Close, MarkAsUnread, MarkEmailRead, Settings } from '@mui/icons-material';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { ApprovalStatusEnum, NotificationTypeEnum } from '../../../utility/enums';
import ApprovalRequestDialog from '../../SystemSettings/UserManagement/ApprovalRequestDialog';
import { useGetNotificationsQuery, usePutToggleReadMutation } from '../../../app/slices/notificationSlice';
import NotificationSettingsDialog from './NotificationSettingsDialog';

export default function NotificationsModal({ open, onClose, notifications }) { 
    const navigate = useNavigate();

    const [showAll, setShowAll] = useState(false);
    const [approvalRequestUser, setApprovalRequestUser] = useState(null);
    const [viewSettings, setViewSettings] = useState(false);

    const { data: allNotifications = [], isLoading } = useGetNotificationsQuery(null, { skip: !showAll });
    const [updateToggleRead] = usePutToggleReadMutation();

    dayjs.extend(relativeTime);
    dayjs.extend(utc);

    const convertUTCtoLocalRelative = (utcDate) => {
        const dateLocal = dayjs.utc(utcDate).local();
        return dayjs(dateLocal).fromNow();
    };

    const filteredNotifications = useMemo(() => {
        if (showAll) {
            return allNotifications;
        } else {
            return notifications.filter(x => x.isRead === false);
        }
    }, [showAll, notifications, allNotifications]);

    const handleItemClick = (notification) => {

        switch (notification.notificationTypeId) {
            case NotificationTypeEnum.UNDEFINED:
                console.log('Basic notification clicked - handling not yet implemented');
                break;

            case NotificationTypeEnum.APPROVAL_REQUEST:
                if (notification.approvalRequestUser.approvalRequests.some(x => x.statusId === ApprovalStatusEnum.PENDING)) {
                    setApprovalRequestUser(notification.approvalRequestUser);
                }
                break;

            case NotificationTypeEnum.SURVEY:
                navigate(`/?surveyId=${notification.surveyId}`);
                handleClose();
                break;

            case NotificationTypeEnum.SURVEY_TEMPLATE:
                navigate(`/survey-template-management?surveyTemplateId=${notification.surveyTemplateId}`);
                handleClose();
                break;

            case NotificationTypeEnum.CAMPAIGN:
                navigate(`/campaign-management?campaignId=${notification.campaignId}`);
                handleClose();
                break;

            default:
                break;
        }
        if (!notification.isRead) updateToggleRead(notification.id);
    };

    const reset = () => {
        setShowAll(false);
    };

    const handleClose = () => {
        onClose();
        reset();
    };

    // We would ideally get the number of items to display from the API, but to avoid making an extra call, we can calculate it based on the viewport height
    const calcSkeletonItemCount = () => {
        const viewportHeight = window.innerHeight;
        const x1 = 600, y1 = 3, x2 = 1400, y2 = 14;
        return Math.round(y1 + (viewportHeight - x1) * ((y2 - y1) / (x2 - x1)));
    };

    const SkeletonItem = ({ index }) => (
        <>
            <ListItem disablePadding>
                <ListItemButton>
                    <ListItemIcon sx={{ mr: -2, pl: 1 }}></ListItemIcon>
                    <ListItemText disableTypography
                        primary={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px', pr: 1, pb: '5px' }}>
                                <Skeleton variant='text' width='45%' />
                                <Skeleton variant='text' width='15%' />
                            </Box>
                        }
                        secondary={
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                <Skeleton variant='text' width='80%' />
                            </Box>
                        }
                    />
                </ListItemButton>
                <ListItemIcon sx={{ ml: 2 }}>
                    <Skeleton variant='circle' width={25} height={25} />
                </ListItemIcon>
            </ListItem>
            {index !== 12 && <Divider />}
        </>
    );

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth='md'
                PaperProps={{
                    style: {
                        alignSelf: 'flex-start',
                        marginTop: '120px'
                    }
                }}
            >
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            Notifications

                        </Box>
                        <FormControlLabel
                            sx={{ mr: 12 }}
                            control={
                                <Switch
                                    color='secondary'
                                    onChange={(_e, checked) => setShowAll(checked)}
                                />
                            }
                            label={<Typography variant='body2'>Show All</Typography>}
                        />
                    </Box>
                    <IconButton
                        aria-label='close'
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Tooltip title="Notification Settings">
                        <IconButton
                            aria-label='notification settings'
                            onClick={() => setViewSettings(true)}
                            sx={{
                                position: 'absolute',
                                right: 50,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Settings />
                        </IconButton>
                    </Tooltip>
                </DialogTitle >
                <DialogContent>
                    <List
                        sx={{
                            overflow: 'auto',
                            maxHeight: 'calc(100vh - 325px)',
                        }}
                    >
                        {isLoading ? (
                            Array.from({ length: calcSkeletonItemCount() }, (_, i) => <SkeletonItem key={i} index={i} />)
                        ) :
                            filteredNotifications.length > 0 ? (
                                filteredNotifications.map((notification, i) => (
                                    <React.Fragment key={i}>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                onClick={() => handleItemClick(notification)}
                                            >
                                                <ListItemIcon sx={{ mr: -2, pl: 1, visibility: notification.isRead ? 'hidden' : 'visible' }}>
                                                    <div style={{ height: '10px', width: '10px', borderRadius: '50%', backgroundColor: 'gold' }} />
                                                </ListItemIcon>
                                                <ListItemText disableTypography
                                                    primary={
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px', pr: 1, pb: '4px' }}>
                                                            <Typography variant='body1'>
                                                                {notification.title}
                                                            </Typography>
                                                            <Typography variant='body2' color='text.secondary'>
                                                                {convertUTCtoLocalRelative(notification.createdDate)}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                                                            <Typography variant='body2' color='text.secondary'>
                                                                {notification.message}
                                                            </Typography>
                                                            {
                                                                notification.notificationTypeId === NotificationTypeEnum.APPROVAL_REQUEST &&
                                                                !notification.approvalRequestUser.approvalRequests.some(x => x.statusId === ApprovalStatusEnum.PENDING) &&
                                                                <Chip size='small' color='secondary' label='Completed' />
                                                            }
                                                        </Box>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemIcon sx={{ pl: 2 }}>
                                                {notification.isRead ?
                                                    <Tooltip title="Mark as Unread">
                                                        <IconButton
                                                            aria-label='mark as unread'
                                                            onClick={() => updateToggleRead(notification.id)}
                                                        >
                                                            <MarkAsUnread />
                                                        </IconButton>
                                                    </Tooltip> :
                                                    <Tooltip title="Mark as Read">
                                                        <IconButton
                                                            aria-label='mark as read'
                                                            onClick={() => updateToggleRead(notification.id)}
                                                        >
                                                            <MarkEmailRead />
                                                        </IconButton>
                                                    </Tooltip>}
                                            </ListItemIcon>
                                        </ListItem>
                                        {i < filteredNotifications.length - 1 && <Divider />}
                                    </React.Fragment>
                                ))
                            ) : (
                                <ListItem sx={{ py: 3 }}>
                                    <ListItemText sx={{ textAlign: 'center' }}
                                        primary={
                                            <Typography variant='body1'>
                                                No unread notifications
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                            )
                        }
                    </List>
                </DialogContent>
            </Dialog >
            <ApprovalRequestDialog
                isOpen={!!approvalRequestUser}
                user={approvalRequestUser}
                close={() => setApprovalRequestUser(null)}
            />
            <NotificationSettingsDialog
                open={viewSettings}
                onClose={() => setViewSettings(false)}
            />
        </>
    )
}