import { useEffect, useMemo, useState } from 'react';
import { Box, Card, TableContainer, Table, TableBody, TableRow, TableCell, Typography, InputAdornment, TextField, Grid, FormHelperText } from '@mui/material';
import { Search, Check, Error } from '@mui/icons-material';
import { useGetCampaignsForDistrictUserQuery } from '../../CampaignManagement/campaignManagementSlice';
import { useGetCampaignPillarsWithBenefitsQuery } from '../../../app/slices/pillarsWithBenefitsSlice';
import WizardButtonGroup from '../../Common/Buttons/WizardButtonGroup';
import PillarsWithBenefitsAccordion from '../../Common/FormElements/PillarsWithBenefitsAccordion';
import SurveyTitleHeader from './Common/SurveyTitleHeader';

export default function StepTwo({ title,
  selectedCampaign, setSelectedCampaign,
  selectedPillars, setSelectedPillars,
  selectedBenefits, setSelectedBenefits,
  setCampaignStartDate, setCampaignEndDate,
  prevStep, nextStep }) {

  const [campaignSearch, setCampaignSearch] = useState("");
  const [error, setError] = useState(null);

  const { data: districtCampaigns = [] } = useGetCampaignsForDistrictUserQuery();
  const { data: pillarBenefits, refetch, isLoading } = useGetCampaignPillarsWithBenefitsQuery(selectedCampaign, { skip: !selectedCampaign });

  useEffect(() => {
    if (selectedCampaign) {
      refetch()
        .catch((error) => {
          console.error("Error fetching pillars and benefits:", error);
          setSelectedPillars([]);
          setSelectedBenefits([]);
          setSelectedCampaign(null);
        });
    }
  }, [selectedCampaign, refetch, setSelectedCampaign, setSelectedPillars, setSelectedBenefits]);

  // Ensure selected pillars and benefits exist in the new campaign
  useEffect(() => {
    if (pillarBenefits) {
      const validPillarIds = pillarBenefits.map(pillar => pillar.id);
      const validBenefitIds = pillarBenefits.flatMap(pillar => pillar.benefits.map(benefit => benefit.id));

      setSelectedPillars(selectedPillars.filter(pillarId => validPillarIds.includes(pillarId)));
      setSelectedBenefits(selectedBenefits.filter(benefitId => validBenefitIds.includes(benefitId)));
    }
  }, [pillarBenefits, setSelectedPillars, setSelectedBenefits]);

  const filteredCampaigns = useMemo(() => districtCampaigns.filter(campaign =>
    campaign.name.toLowerCase().includes(campaignSearch.toLowerCase())
  ), [districtCampaigns, campaignSearch]);

  const handleCampaignClick = (campaign) => {
    if (selectedCampaign === campaign.id)
      return;

    setSelectedCampaign(campaign.id);
    setCampaignStartDate(campaign.startDate);
    setCampaignEndDate(campaign.endDate);
  };

  const toNextStep = () => {
    if (selectedPillars.length === 0 || selectedBenefits.length === 0) {
      setError("A benefit must be selected before continuing.");
      return;
    }
    nextStep();
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '0% 3% 0% 3%',
        maxWidth: '1100px',
      }}
    >
      <SurveyTitleHeader title={title} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid spacing={'25px'} container>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                flexBasis: '75%',
                padding: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
              }}
            >
              <Typography sx={{ marginBottom: '5%' }}>
                <span style={{ fontWeight: 'bold' }}>Select Campaign. </span>
                Surveys distributed from this template will be tracked as part of the selected campaign.
              </Typography>
              <Box>
                <TextField
                  label="Search"
                  variant='outlined'
                  size='small'
                  fullWidth
                  value={campaignSearch}
                  onChange={(e) => setCampaignSearch(e.target.value)}
                  sx={{ backgroundColor: '#ffffff', mb: 2 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Search color='gray' />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box
                  style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    border: '1px solid #9F9F9F',
                    borderRadius: '5px',
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {
                          filteredCampaigns.length > 0 ? (
                            filteredCampaigns.map(campaign => (
                              <TableRow
                                key={campaign.id}
                                onClick={() => handleCampaignClick(campaign)}
                                sx={{
                                  cursor: 'pointer',
                                  backgroundColor:
                                    selectedCampaign === campaign.id
                                      ? '#0180ff'
                                      : 'white',
                                }}
                              >
                                <TableCell
                                  sx={{
                                    color:
                                      selectedCampaign === campaign.id
                                        ? 'white'
                                        : 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <Typography>
                                    {campaign.name}
                                  </Typography>
                                  {selectedCampaign === campaign.id && (
                                    <Check sx={{ color: 'white' }} />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow sx={{ backgroundColor: 'white' }}>
                              <TableCell sx={{ display: 'flex', gap: '10px' }}>
                                <Error color='warning' sx={{ ml: 1 }} />
                                <Typography>
                                  No campaigns found
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                flexBasis: '100%',
                padding: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
              }}
            >
              <Typography sx={{ mb: 1 }}>
                <span style={{ fontWeight: 'bold' }}>Select Benefits. </span>
                The pillar and benefits selected will be measured by the results of the surveys distributed from this template.
              </Typography>
              <PillarsWithBenefitsAccordion
                pillarBenefits={pillarBenefits}
                isLoading={isLoading}
                selectedBenefits={selectedBenefits}
                setSelectedBenefits={setSelectedBenefits}
                selectedPillars={selectedPillars}
                setSelectedPillars={setSelectedPillars}
                showSelectCampaignMessage={!selectedCampaign}
                limitSelectionToSinglePillar
              />
              <FormHelperText error>{error}</FormHelperText>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <WizardButtonGroup
        backClick={prevStep}
        forwardClick={toNextStep}
      />
    </Box>
  );
}