import { createTheme } from "@mui/material";

const font = 'Open Sans, Roboto, -apple-system, BlinkMacSystemFont,"Helvetica Neue", "Segoe UI", Arial, sans-serif';

export default createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#011E41'
        },
        secondary: {
            main: '#0180ff',
            lighter: '#E3F2FD',
            light: '#BBDEFB',
            dark: '#1565C0',
            contrastText: '#E3F2FD'
        },
        tertiary: {
            main: '#4d4d4d'
        },
        danger: {
            main: "#7B0707",
            light: "#ff0000",
            lighter: "#ffcdd2",
            dark: "#b71c1c",
            contrastText: "#FFFFFF"
        },
        warning: {
            main: "#ffa726",
            light: "#ffb74d",
            contrastText: "#000000"
        },
        success: {
            main: "#4caf50",
            lighter: "#c8e6c9",
            light: "#81c784",
            dark: "#1b5e20",
            contrastText: "#000000"
        },
    },
    typography: {
        fontFamily: font,
        button: {
            textTransform: 'none'
        }
    },
    components: {
        MuiCheckbox: {
            root: {
                colorPrimary: {
                    color: '#007483',
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: font
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#007483',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#909090',
                        }
                    },
                }
            }
        }
    },
})