import { useEffect, useMemo, useState } from 'react';
import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import ScorecardProgressBar from './ScorecardProgressBar';
import { decimalToPercentage, returnTrendColorObject } from './scorecardUtils';
import { ScorecardTrendEnum } from '../../utility/enums';
import { FormatBold, TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';

const determineTrend = (benefit) => {
    if (!benefit.hasBaseline) {
        return ScorecardTrendEnum.NO_BASELINE;
    } else if (benefit.growthPercentage > 0) {
        return ScorecardTrendEnum.IMPROVEMENT;
    } else if (benefit.growthPercentage < 0) {
        return ScorecardTrendEnum.REGRESSION;
    }
    else {
        return ScorecardTrendEnum.FLAT;
    }
};

const returnTrendIcon = (trend) => {
    switch (trend) {
        case ScorecardTrendEnum.NO_BASELINE:
            return <FormatBold />;
        case ScorecardTrendEnum.IMPROVEMENT:
            return <TrendingUp color={returnTrendColorObject(trend).themeColor} />;
        case ScorecardTrendEnum.REGRESSION:
            return <TrendingDown color={returnTrendColorObject(trend).themeColor} />;
        case ScorecardTrendEnum.FLAT:
            return <TrendingFlat color={returnTrendColorObject(trend).themeColor} />;
        default:
            return <TrendingFlat color={returnTrendColorObject(trend).themeColor} />;
    }
};

const returnTooltips = (trend, benefit, buildingName) => {
    const growthPercentage = decimalToPercentage(benefit.growthPercentage);
    const scorePercentage = decimalToPercentage(benefit.scorePercentage);
    const targetGrowthPercentage = decimalToPercentage(benefit.targetGrowthPercentage);

    if (trend === ScorecardTrendEnum.IMPROVEMENT || trend === ScorecardTrendEnum.REGRESSION) {
        const orNot = trend === ScorecardTrendEnum.REGRESSION ? 'not' : '';
        return ({
            growth: `${buildingName} has ${growthPercentage}% scoring growth ${trend.name.toLowerCase()} for the ${benefit.name} benefit from their previous Growth Campaign.`,
            score: `${buildingName} has a score of ${scorePercentage}% for their current Growth Campaign.`,
            progress: `${buildingName} has ${growthPercentage}% scoring ${trend.name.toLowerCase()} and is ${orNot} on track to reach its campaign goal of ${targetGrowthPercentage}% for this Benefit.`
        });
    } else if (trend === ScorecardTrendEnum.NO_BASELINE) {
        const orNot = benefit.scorePercentage < 0 ? 'not' : '';
        return ({
            growth: `${buildingName} has ${growthPercentage}% baseline scoring for the ${benefit.name} benefit from their previous Growth Campaign.`,
            score: `${buildingName} has a score of ${scorePercentage} benefit from their previous Growth Campaign.`,
            progress: `${buildingName} has ${growthPercentage}% scoring and is ${orNot} on track to reach its campaign goal of ${targetGrowthPercentage}% for this Benefit.`
        });
    } else {
        return ({
            growth: `${buildingName} has no growth for the for the ${benefit.name} benefit from their previous Growth Campaign.`,
            score: `${buildingName} has a score of ${scorePercentage}% for their current Growth Campaign.`,
            progress: `${buildingName} has ${growthPercentage}% scoring and is not on track to reach its campaign goal of ${targetGrowthPercentage}% for this Benefit.`
        });
    }
};

const TableCellWithTooltip = ({ title, color, value, icon, trend }) => {
    const trendColorBox = {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '.5rem',
        borderRadius: '5px',
        maxWidth: '51px',
        backgroundColor: trend !== ScorecardTrendEnum.NO_BASELINE && returnTrendColorObject(trend).hexColor,
    };

    return (
        < TableCell >
            <Tooltip arrow title={title}>
                <Box sx={trendColorBox}>
                    <Typography variant='body2' component='p' color={color}>
                        {value}%
                    </Typography>
                    {icon}
                </Box>
            </Tooltip>
        </TableCell >
    )
};

export default function ScorecardTableBenefitRow({ benefit, buildingName, stripe }) {

    const [trend, setTrend] = useState(ScorecardTrendEnum.FLAT);

    useEffect(() => {
        setTrend(determineTrend(benefit));
    }, [benefit]);

    const benefitTooltips = useMemo(() => returnTooltips(trend, benefit, buildingName), [trend, benefit, buildingName]);
    const trendIcon = useMemo(() => returnTrendIcon(trend), [trend]);

    return (
        <TableRow sx={{ backgroundColor: stripe && '#f4f4f4' }}>
            <TableCell>
                <Typography variant='caption' component='p'>
                    Benefit
                </Typography>
            </TableCell>
            <TableCell>
                {benefit.name}
            </TableCell>
            <TableCellWithTooltip
                title={benefitTooltips.growth}
                color={returnTrendColorObject(trend).textColor}
                value={decimalToPercentage(benefit.growthPercentage)}
                icon={trendIcon}
                trend={trend}
            />
            <TableCellWithTooltip
                title={benefitTooltips.score}
                color={returnTrendColorObject(trend).textColor}
                value={decimalToPercentage(benefit.scorePercentage)}
                icon={trendIcon}
                trend={trend}
            />
            <Tooltip
                title={benefitTooltips.progress}
                arrow
                slotProps={{
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -30],
                                },
                            },
                        ],
                    },
                }}>
                <TableCell>
                    <Typography variant='caption' component='p' color={'secondary'}>
                        {benefit.campaignName}
                    </Typography>
                    <ScorecardProgressBar
                        target={benefit.targetGrowthPercentage}
                        base={benefit.baseGrowthPercentage}
                        score={benefit.scorePercentage}
                        growth={benefit.growthPercentage}
                        trend={trend}
                    />
                </TableCell>
            </Tooltip>
        </TableRow >
    );
}