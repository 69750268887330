import { useState, useEffect, useCallback } from 'react';
import { Button, Box, Chip, Stack, Dialog, DialogContent, DialogTitle, IconButton, TextField, Autocomplete, Skeleton, Tooltip, Typography } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { useAddPillarMutation, useUpdatePillarMutation } from '../pillarManagementSlice';
import { useGetSystemStatusesQuery } from '../../../../app/slices/systemStatusSlice';
import { useGetSessionQuery } from '../../../../app/slices/sessionSlice';
import DialogActionButtons from '../../../Common/Buttons/DialogActionButtons';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ConfirmationModal from '../../../Common/ConfirmationModal';
import BenefitFormModal from './BenefitFormModal';

const GrayBorderBox = styled(Box)({
    padding: '15px',
    border: '1px solid #9F9F9F',
    borderRadius: '5px',
    backgroundColor: '#FAFAFA',
});

const initialBenefitFormState = { id: null, name: '', description: '' };

const PillarFormModal = ({ isOpen, pillar, close }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [benefits, setBenefits] = useState([]);
    const [systemStatus, setSystemStatus] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const [benefitForm, setBenefitForm] = useState(initialBenefitFormState);
    const [isBenefitFormVisible, setIsBenefitFormVisible] = useState(false);
    const [isEditingBenefit, setIsEditingBenefit] = useState(false);
    const [hasBenefitEdit, setHasBenefitEdit] = useState(false);

    const [showDeleteBenefitModal, setShowDeleteBenefitModal] = useState(false);
    const [benefitToDelete, setBenefitToDelete] = useState(null);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

    const [addPillar, { isLoading: isAdding }] = useAddPillarMutation();
    const [updatePillar, { isLoading: isUpdating }] = useUpdatePillarMutation();
    const { data: systemStatuses = [] } = useGetSystemStatusesQuery();
    const { data: session } = useGetSessionQuery();

    const resetForm = useCallback(() => {
        setName('');
        setDescription('');
        setSystemStatus('');
        setBenefits([]);
        setFormErrors({});
        clearBenefitForm();
    }, []);

    useEffect(() => {
        if (pillar && pillar.id) {
            setName(pillar.name || '');
            setDescription(pillar.description || '');
            setSystemStatus(pillar.systemStatusName || '');
            setBenefits(pillar.benefits || []);
        } else {
            resetForm();
        }
    }, [pillar, resetForm]);

    const isFormValid = () => {
        const errors = {};
        if (!name.trim()) errors.name = 'Name is required';
        if (pillar?.id && !systemStatus.trim()) errors.systemStatus = 'Status is required';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleBenefitFormChange = (field, value) => {
        setBenefitForm((prev) => ({ ...prev, [field]: value }));
        setHasBenefitEdit(true);
    };

    const handleBenefitUpdate = () => {
        const { id, name, description } = benefitForm;
        if (!name.trim()) {
            setFormErrors((prev) => ({ ...prev, benefitName: 'Name is required' }));
            return;
        }
        if (id !== null) {
            // Update existing benefit
            setBenefits((prev) =>
                prev.map((benefit) => (benefit.id === id ? { ...benefit, name, description } : benefit))
            );
        } else {
            // Add new benefit and give it a temporary id
            const tempId = benefits.length > 0 ? Math.max(...benefits.map((b) => b.id)) + 1 : 1;
            setBenefits((prev) => [...prev, { id: tempId, name, description }]);
        }
        clearBenefitForm();
    };

    const clearBenefitForm = () => {
        setBenefitForm(initialBenefitFormState);
        setIsBenefitFormVisible(false);
        setIsEditingBenefit(false);
        setHasBenefitEdit(false);
        setFormErrors((prev) => ({ ...prev, benefitName: null }));
    };

    const handleBenefitClick = (benefit) => {
        setBenefitForm({ ...benefit });
        setIsBenefitFormVisible(true);
        setIsEditingBenefit(true);
    };

    const handleCloseBenefitForm = () => {
        setIsBenefitFormVisible(false);
    };

    const removeBenefit = (id) => {
        setBenefits((prev) => prev.filter((benefit) => benefit.id !== id));
        if (benefitForm.id === id) {
            clearBenefitForm();
        }
        setHasBenefitEdit(true);
    };

    const submit = () => {
        if (!isFormValid()) return;

        const dto = {
            name,
            description,
            schoolDistrictId: session.schoolDistrictId,
            benefits,
        };

        if (pillar?.id) {
            // Update existing pillar
            dto.id = pillar.id;
            dto.schoolDistrictId = pillar.schoolDistrictId;
            dto.systemStatusId = systemStatuses.find((x) => x.value === systemStatus)?.id;

            updatePillar(dto)
                .unwrap()
                .then(() => {
                    toast.success('Successfully updated pillar');
                    resetForm();
                    close();
                })
                .catch(() => {
                    toast.error('An error occurred when updating pillar');
                });
        } else {
            addPillar(dto)
                .unwrap()
                .then(() => {
                    toast.success('Successfully added pillar');
                    resetForm();
                    close();
                })
                .catch(() => {
                    toast.error('An error occurred when adding pillar');
                });
        }
    };

    const handleClose = () => {
        if (hasBenefitEdit) {
            setShowUnsavedChangesModal(true);
        } else {
            resetForm();
            close();
        }
    };

    const handleConfirmClose = () => {
        resetForm();
        close();
        setShowUnsavedChangesModal(false);
    };

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {pillar === -1 ? 'Add Pillar' : pillar?.id ? 'Edit Pillar' : <Skeleton variant="text" width={100} />}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={3} sx={{ pt: 1 }}>
                        <TextField
                            id='pillar-name'
                            label="Name"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            value={name}
                            error={!!formErrors.name}
                            helperText={formErrors.name}
                            onChange={(e) => setName(e.target.value.slice(0, 100))}
                        />
                        <TextField
                            id='pillar-description'
                            label="Description"
                            variant="outlined"
                            size="small"
                            multiline
                            rows={4}
                            fullWidth
                            value={description}
                            error={!!formErrors.description}
                            helperText={formErrors.description}
                            onChange={(e) => setDescription(e.target.value.slice(0, 200))}
                        />
                        {pillar?.id && (
                            <Autocomplete
                                options={systemStatuses.map((status) => status.value)}
                                value={systemStatus || null}
                                onChange={(_e, v) => setSystemStatus(v)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Status"
                                        required
                                        size='small'
                                        error={!!formErrors.systemStatus}
                                        helperText={formErrors.systemStatus}
                                    />
                                )}
                            />
                        )}
                        <GrayBorderBox>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1">Benefits:</Typography>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    startIcon={<Add />}
                                    onClick={() => {
                                        clearBenefitForm();
                                        setIsBenefitFormVisible(true);
                                    }}
                                >
                                    Create New
                                </Button>
                            </Box>
                            {benefits.length > 0 ? (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                                    {benefits.map((benefit) => (
                                        <Tooltip key={benefit.id} title={benefit.description || ''}>
                                            <Chip
                                                label={benefit.name}
                                                onDelete={() => {
                                                    setBenefitToDelete(benefit);
                                                    setShowDeleteBenefitModal(true);
                                                }}
                                                onClick={() => handleBenefitClick(benefit)}
                                            />
                                        </Tooltip>
                                    ))}
                                </Box>
                            ) : (
                                <Chip label="No benefits added" />
                            )}
                        </GrayBorderBox>
                    </Stack>
                </DialogContent>
                <DialogActionButtons
                    fullWidth
                    closeText="Cancel"
                    onClose={handleClose}
                    submitText="Save"
                    onSubmit={submit}
                    isLoading={isUpdating || isAdding}
                />
            </Dialog>
            <BenefitFormModal
                isOpen={isBenefitFormVisible}
                onClose={handleCloseBenefitForm}
                benefitForm={benefitForm}
                handleBenefitFormChange={handleBenefitFormChange}
                handleBenefitUpdate={handleBenefitUpdate}
                isEditingBenefit={isEditingBenefit}
                formErrors={formErrors}
            />
            <ConfirmationModal
                isOpen={showDeleteBenefitModal}
                close={() => setShowDeleteBenefitModal(false)}
                onConfirm={() => {
                    removeBenefit(benefitToDelete.id);
                    setShowDeleteBenefitModal(false);
                }}
                title={`Delete ${benefitToDelete?.name}?`}
                message="Please confirm you'd like to delete the selected benefit."
            />
            <ConfirmationModal
                isOpen={showUnsavedChangesModal}
                close={() => setShowUnsavedChangesModal(false)}
                onConfirm={handleConfirmClose}
                title="Unsaved Changes"
                message="You have unsaved changes. Are you sure you want to discard them?"
            />
        </>
    );
};

export default PillarFormModal;