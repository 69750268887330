import React, { useEffect, useState, useRef } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import DialogActionButtons from '../Common/Buttons/DialogActionButtons';
import UserAgreementDoc from './UserAgreementDoc.js'
export default function UserAgreement({ accept, cancel, isOpen }) {
    const dialogRef = useRef();
    const [isAcceptDisabled, setIsAcceptDisabled] = useState(true)


    // check the dialog scroll after loading in the text
    useEffect(() => {
        const { current } = dialogRef;
        if (current) {
            const { scrollTop, scrollHeight, clientHeight } = current;
            testScrollForAcceptedButton(scrollTop, scrollHeight, clientHeight);
        }

    }, [])

    //handle window resizing
    useEffect(() => {
        const handleResize = () => {
            const { current } = dialogRef;
            if (current) {
                const { scrollTop, scrollHeight, clientHeight } = current;
                testScrollForAcceptedButton(scrollTop, scrollHeight, clientHeight);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    //handle dialog scroll
    const onScroll = (e) => {
        const docWindow = e.target;
        console.log("in onscroll");
        if (docWindow) {
            const { scrollTop, scrollHeight, clientHeight } = docWindow;
            testScrollForAcceptedButton(scrollTop, scrollHeight, clientHeight);
        }
    };

    //check if bottom of text is visible
    const testScrollForAcceptedButton = (scrollTop, scrollHeight, clientHeight) => {
        const isNearBottom = scrollTop + clientHeight >= scrollHeight - 3;
        if (isNearBottom) {
            setIsAcceptDisabled(false);
        }
    }

    return (

        <Dialog
            scroll={'paper'}
            open={isOpen}
            onClose={cancel}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>
                <Box>
                    User Agreement
                </Box>
                <IconButton
                    aria-label='close'
                    onClick={cancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: '.3em !important' }} dividers={true} onScroll={onScroll} ref={dialogRef}  >
                <UserAgreementDoc />
            </DialogContent>

            <DialogActionButtons
                isSubmitDisabled={isAcceptDisabled && true}
                fullWidth
                closeText='Cancel'
                onClose={cancel}
                submitText='Accept'
                onSubmit={accept}
            />
        </Dialog>
    )
}