import { api } from "../api";
const TAG = 'PillarsWithBenefits';

export const pillarsWithBenefitsApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: builder => ({
        getDistrictPillarsWithBenefits: builder.query({
            query: () => 'pillar',
            providesTags: [TAG]
        }),
        getCampaignPillarsWithBenefits: builder.query({
            query: (campaignId) => `pillar/campaign/${campaignId}`,
            providesTags: [TAG]
        }),
    })
})

export const {
    useGetDistrictPillarsWithBenefitsQuery,
    useGetCampaignPillarsWithBenefitsQuery
} = pillarsWithBenefitsApi;