import { Box, TextField, RadioGroup, FormControlLabel, Radio, Checkbox } from '@mui/material';
import { SurveyQuestionTypeEnum } from '../../../../utility/enums';

export default function QuestionTypePreview({ questionTypeId, ordinal, surveyQuestionOptions }) {
    switch (questionTypeId) {
        case SurveyQuestionTypeEnum.SHORT_ANSWER:
            return (
                <Box sx={{ pr: '93px' }}>
                    <TextField
                        id={`${ordinal}-disabled-text-field`}
                        label="Short text answer"
                        variant="outlined"
                        type="text"
                        size="small"
                        fullWidth
                        disabled
                        value={''}
                    />
                </Box>
            );
        case SurveyQuestionTypeEnum.YES_OR_NO:
            return (
                <RadioGroup
                    name="radio-buttons-group"
                    row
                    sx={{ marginTop: '15px' }}
                >
                    <FormControlLabel disabled value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel disabled value="0" control={<Radio />} label="No" />
                </RadioGroup>
            );
        case SurveyQuestionTypeEnum.CHECKBOX_CHOICES:
        case SurveyQuestionTypeEnum.MULTIPLE_CHOICE:
            return (
                <RadioGroup
                    name="radio-buttons-group"
                >
                    {surveyQuestionOptions.map((uo, i) => (
                        <FormControlLabel
                            key={`${ordinal}-option-entry-${uo.title}-${i}-${uo?.id ?? 'new'}`}
                            disabled
                            value={i}
                            control={questionTypeId === SurveyQuestionTypeEnum.MULTIPLE_CHOICE ? <Radio /> : <Checkbox />}
                            label={uo.title}
                        />
                    ))}
                </RadioGroup>
            );
        default:
            return null;
    }
}