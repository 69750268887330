import { ContentCopy, Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

export default function QuestionActionButtons({ ordinal, onCopy, onDelete }) {
    return (
        <Box sx={{ maxHeight: '40px', display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title='Copy Question'>
                <IconButton color='secondary' onClick={() => onCopy(ordinal)}>
                    <ContentCopy />
                </IconButton>
            </Tooltip>
            <Tooltip title='Delete Question'>
                <IconButton color='error' onClick={() => onDelete(ordinal)}>
                    <Delete />
                </IconButton>
            </Tooltip>
        </Box>
    );
}