import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

const WizardButtonGroup = ({ forwardText = "Save & Continue", backText = "Go Back", forwardClick, backClick, fullWidth, isLoading = false, primaryDisabled = false }) => {
    return (
        <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '20px',
                marginTop: '25px',
            }}
        >
            <Button
                variant='contained'
                color='primary'
                disabled={isLoading}
                sx={{
                    flexBasis: fullWidth ? '100%' : '25%',
                }}
                onClick={backClick}
            >
                {backText}
            </Button>
            <LoadingButton
                variant='contained'
                color='secondary'
                disabled={primaryDisabled}
                loading={isLoading}
                onClick={forwardClick}
                sx={{
                    flexBasis: fullWidth ? '100%' : '25%',
                }}
            >
                {forwardText}
            </LoadingButton>
        </Box>
    );
};

export default WizardButtonGroup;