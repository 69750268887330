import { Box, Card, Divider } from "@mui/material";
import PillarXpLogo from '../../../images/PillarXP-Logo-Alt.svg';

export default function AuthCard({ children, hide }) {
    return (
        <Box
            sx={{
                display: 'flex',
                minHeight: '100vh',
                backgroundColor: '#e7e7e7',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Card
                variant='outlined'
                sx={{
                    padding: '1em',
                    paddingTop: '2em',
                    borderRadius: '1em',
                    border: '1px solid #cccccc',
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: '450px',
                    boxSizing: 'border-box',
                    display: hide ? 'none' : 'block',
                    mx: { xs: 2, sm: 0 }
                }}
            >
                <Box component="img" src={PillarXpLogo} alt='logo' />
                <Divider sx={{ marginTop: "5%", marginBottom: "3%" }} light />
                {children}
            </Card>
        </Box>
    );
}