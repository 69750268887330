import { Grid, InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import VisibilityToggleButton from '../Common/FormElements/VisibilityToggleButton';
import Label from '../Common/FormElements/Label';

export default function SurveyFilters({ filters, setFilters }) {
    const onFilterChange = (property, value) => {
        setFilters({ ...filters, [property]: value });
    };

    return (
        <Grid container>
            <Grid container justifyContent='space-between' spacing={1}>
                <Grid item xs={6} sm={6} md={5}>
                    <TextField
                        label={<Label text="Search" />}
                        variant='outlined'
                        size='small'
                        fullWidth
                        type='text'
                        onChange={e => onFilterChange('searchTerm', e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Search />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                    <VisibilityToggleButton
                        text={"Completed"}
                        filters={filters}
                        onFilterChange={onFilterChange}
                        property='hideCompleted'
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}