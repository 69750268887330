import { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card } from '@mui/material';
import ScorecardTable from './ScorecardTable';
import GrowthCampaignCard from './GrowthCampaignCard';
import styled from 'styled-components';
import { useGetScorecardQuery } from './scorecardSlice';
import ContentWrapper from '../Common/ContentWrapper';

const CampaignsBox = styled(Box)`
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default function Scorecards() {

    const { data: scorecard, isLoading } = useGetScorecardQuery();
    const [district, setDistrict] = useState({});
    const [pillars, setPillars] = useState([]);
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        if (scorecard) {
            setDistrict(scorecard.schoolDistrict);
            setPillars(scorecard.pillars);
            setCampaigns(scorecard.campaigns);
        }
    }, [scorecard]);

    return (
        <ContentWrapper title="Scorecards">
            <Grid container spacing={2}>
                <Grid item xs={12} md={campaigns.length > 0 ? 8 : 12}>
                    {pillars.map(pillar => (
                        <ScorecardTable key={pillar.id} pillar={pillar} district={district} />
                    ))}
                    {!pillars.length && !isLoading ?
                        <Card
                            sx={{ 
                                padding: '10px',
                                width: '200px'
                             }}
                        >
                            There is not enough data to render a scorecard
                        </Card> 
                    : null}
                </Grid>
                {campaigns.length > 0 &&
                    <Grid item xs={12} md={4}>
                        <CampaignsBox>
                            <Typography variant='subtitle1' component='h4' sx={{ fontWeight: 'bold' }}>
                                Growth Campaigns
                            </Typography>
                            {campaigns.map(campaign => (
                                <GrowthCampaignCard key={campaign.id} campaign={campaign} />
                            ))}
                        </CampaignsBox>
                    </Grid>
                }
            </Grid>
        </ContentWrapper>
    );
}