import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CardContent, TextField, Stack, InputAdornment, IconButton, Link, Typography, FormControl, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useGetSchoolsByDistrictQuery, useSelfRegisterMutation } from './selfRegistrationSlice';
import AuthCard from '../Common/Auth/AuthCard';
import GroupedSchoolBuildingAutocomplete from '../Common/FormElements/GroupedSchoolBuildingAutocomplete';

const CreateAccountButton = styled(LoadingButton)`
&:hover {
    background-color: #026AD3 !important;
}`;

const accountTypes = [
    { value: 1, label: 'District Admin' },
    { value: 2, label: 'School Admin' },
    { value: 3, label: 'Faculty/Staff' }
];

export default function SelfRegistration() {

    const [showConfirmationEmail, setShowConfirmationEmail] = useState(true);
    const [districtCode, setDistrictCode] = useState(null);
    const [form, setForm] = useState({
        schoolDistrict: '',
        schoolDistrictError: false,
        schoolBuildingIds: [],
        firstName: '',
        firstNameError: false,
        lastName: '',
        lastNameError: false,
        email: '',
        emailError: false,
        confirmationEmail: '',
        confirmationEmailError: false,
        accountType: '',
        accountTypeError: false
    });

    const navigate = useNavigate();

    const [selfRegister, { isLoading }] = useSelfRegisterMutation();
    const { data: districtSchools, refetch: refetchDistrictSchools, isLoading: areSchoolsLoading } = useGetSchoolsByDistrictQuery(districtCode, { skip: !districtCode });

    useEffect(() => {
        if (districtCode) {
            refetchDistrictSchools();
        }
    }, [districtCode, refetchDistrictSchools]);

    function handleSchoolDistrictChange(e) {
        // When a 4 number district code is entered, trigger fetch in useEffect for the schools for that district
        if (/^\d{4}$/.test(e.currentTarget.value)) {
            setDistrictCode(e.currentTarget.value);
        }
        else {
            setDistrictCode(null);
        }
    };

    function validateAndCreateAccount() {
        const { schoolDistrict, firstName, lastName, email, confirmationEmail, accountType } = form;
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

        const errors = {
            schoolDistrictError: schoolDistrict === '',
            firstNameError: firstName === '',
            lastNameError: lastName === '',
            emailError: !emailRegex.test(email),
            confirmationEmailError: email.toLowerCase() !== confirmationEmail.toLowerCase() || confirmationEmail === '',
            accountTypeError: accountType === ''
        };

        if (Object.values(errors).some(error => error)) {
            setForm({ ...form, ...errors });
            return;
        }

        const dto = {
            schoolDistrictPublicIdentifier: schoolDistrict,
            schoolBuildingIds: form.schoolBuildingIds,
            firstName,
            lastName,
            roleId: accountType,
            emailAddress: email
        };

        selfRegister(dto)
            .then((response) => {
                if (response.error?.status === 400) {
                    toast.error(response.error.data.detail || 'A problem occurred while self registering. Please contact a system administrator.');
                }
                else if (response.error?.status === 500) {
                    toast.error('A problem occurred while self registering. Please contact a system administrator.');
                }
                else if (response.error) {
                    toast.error('A problem occurred while self registering. Please contact a system administrator.');
                }
                else navigate('/self-registration-confirmation');
            })
            .catch((e) => console.log(e));
    };

    return (
        <AuthCard>
            <CardContent>
                <Stack spacing={2}>
                    <Typography paragraph>
                        Please enter the school district supplied in your invite and the best email for communication.
                    </Typography>
                    <TextField
                        label='School District'
                        required
                        variant='outlined'
                        size='small'
                        value={form.schoolDistrict}
                        onChange={(e) => {
                            handleSchoolDistrictChange(e);
                            setForm({ ...form, schoolDistrict: e.currentTarget.value });
                        }}
                        error={form.schoolDistrictError}
                        helperText={form.schoolDistrictError ? 'Please enter a valid 4 digit district code' : null}
                        disabled={isLoading}
                    />
                    <GroupedSchoolBuildingAutocomplete
                        id='schools-autocomplete'
                        schools={districtSchools}
                        fullWidth
                        tagLimit={2}
                        schoolBuildingIds={form.schoolBuildingIds}
                        setSchoolBuildingIds={(ids) => setForm({ ...form, schoolBuildingIds: ids })}
                        loading={areSchoolsLoading}
                        noOptionsText={districtSchools?.length === 0 ? 'No schools found' : 'Enter a valid district code to load schools'}
                        allowSchoolGroupSelect={true}
                    />
                    <Box sx={{ display: 'inline-flex', gap: '10px' }}>
                        <TextField
                            label='First Name'
                            required
                            variant='outlined'
                            size='small'
                            autoComplete='given-name'
                            value={form.firstName}
                            onChange={(e) => setForm({ ...form, firstName: e.currentTarget.value, firstNameError: false })}
                            error={form.firstNameError}
                            helperText={form.firstNameError ? 'Please enter a first name' : null}
                            disabled={isLoading}
                        />
                        <TextField
                            label='Last Name'
                            required
                            variant='outlined'
                            size='small'
                            autoComplete='family-name'
                            value={form.lastName}
                            onChange={(e) => setForm({ ...form, lastName: e.currentTarget.value, lastNameError: false })}
                            error={form.lastNameError}
                            helperText={form.lastNameError ? 'Please enter a last name' : null}
                            disabled={isLoading}
                        />
                    </Box>
                    <TextField
                        label='Email Address'
                        required
                        variant='outlined'
                        size='small'
                        type='email'
                        autoComplete='email'
                        value={form.email}
                        onChange={(e) => setForm({ ...form, email: e.currentTarget.value, emailError: false })}
                        error={form.emailError}
                        helperText={form.emailError ? 'Please enter a valid email address' : null}
                        disabled={isLoading}
                    />
                    <TextField
                        label='Confirm Email Address'
                        required
                        variant='outlined'
                        size='small'
                        value={form.confirmationEmail}
                        onChange={(e) => setForm({ ...form, confirmationEmail: e.currentTarget.value, confirmationEmailError: false })}
                        error={form.confirmationEmailError}
                        helperText={form.confirmationEmailError ? 'Please make sure this email address matches the one above' : null}
                        type={showConfirmationEmail ? 'email' : 'password'}
                        autoComplete='email'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton
                                        onClick={() => setShowConfirmationEmail(!showConfirmationEmail)}
                                        edge='end'
                                        title={showConfirmationEmail ? 'Hide Confirmation Email' : 'Show Confirmation Email'}
                                        disabled={isLoading}
                                    >
                                        {showConfirmationEmail ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        disabled={isLoading}
                    />
                    <FormControl error={form.accountTypeError}>
                        <Autocomplete
                            id='account-type-autocomplete'
                            size='small'
                            value={form.accountType ? accountTypes.find(x => x.value === form.accountType) : null}
                            options={accountTypes}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label='Account Type'
                                    required
                                    variant='outlined'
                                    error={form.accountTypeError}
                                    helperText={form.accountTypeError ? 'Please select an account type' : null}
                                />
                            )}
                            onChange={(e, newValue) => {
                                setForm({ ...form, accountType: newValue ? newValue.value : '', accountTypeError: false });
                            }}
                            disabled={isLoading}
                        />
                    </FormControl>
                    <CreateAccountButton
                        variant='contained'
                        onClick={() => validateAndCreateAccount()}
                        loading={isLoading}
                    >
                        <span>Create Account</span>
                    </CreateAccountButton>
                    <Link
                        href={isLoading ? '#' : '/login'}
                        color={isLoading ? 'text.disabled' : 'secondary'}
                        underline={isLoading ? 'none' : 'always'}
                    >
                        Return to Login
                    </Link>
                </Stack>
            </CardContent>
        </AuthCard >
    );
}