import React from 'react';
import {Stack, Typography} from '@mui/material';

const PasswordRequirements = ({ password }) => {
  const passwordRequirements = [
    { test: password.length >= 12, message: '• Be a minimum of 12 characters' },
    { test: /[a-zA-Z]/.test(password), message: '• Include at least one letter (a-z)' },
    { test: /\d/.test(password), message: '• Include at least one number (0-9)' },
  ];

  return (
    <Stack spacing={2}>
      {passwordRequirements.map((requirement, index) => (
        <div key={index}>
          <Typography variant="body2" color={requirement.test ? 'green' : 'grey'}>
            {requirement.message}
          </Typography>
        </div>
      ))}
    </Stack>
  );
};

export default PasswordRequirements;