import { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, TextField, Typography, Slider, Autocomplete, Chip, Button, FormHelperText, Skeleton, Stack } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { toast } from "react-toastify";
import { useAddSchoolMutation, useUpdateSchoolMutation } from './schoolManagementSlice';
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import DialogActionButtons from '../../Common/Buttons/DialogActionButtons';
import { useGetSystemStatusesQuery } from '../../../app/slices/systemStatusSlice';

function SchoolFormModal({ isOpen, school, close, gradeLevels }) {
    const [schoolName, setSchoolName] = useState('');
    const [code, setCode] = useState('');
    const [status, setStatus] = useState(null);
    const [gradeSliderStart, setGradeSliderStart] = useState(0)
    const [gradeSliderEnd, setGradeSliderEnd] = useState(gradeLevels.length - 1)
    const [buildingNames, setBuildingNames] = useState([]);
    const [buildingName, setBuildingName] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const [addSchool, { isLoading: isAdding }] = useAddSchoolMutation();
    const [updateSchool, { isLoading: isUpdating }] = useUpdateSchoolMutation();
    const { data: session = {} } = useGetSessionQuery();
    const { data: statuses = [] } = useGetSystemStatusesQuery();

    useEffect(() => {
        if (!school || !school.id) return;

        setGradeSliderStart(gradeLevels.findIndex(x => x.id === school?.startGradeId) ?? 0);
        setGradeSliderEnd(gradeLevels.findIndex(x => x.id === school?.endGradeId) ?? gradeLevels.length - 1);
        setSchoolName(school.name);
        setCode(school.code);
        setStatus(school.systemStatusName);
        setBuildingNames(school.schoolBuildings.map(x => x.name));
    }, [school, gradeLevels]);

    function resetBuildings() {
        setBuildingNames(school.schoolBuildings.map(x => x.name));
    }

    function clear() {
        setSchoolName('');
        setCode('');
        setStatus(null);
        setGradeSliderStart(0);
        setGradeSliderEnd(gradeLevels.length - 1);

        setBuildingNames([]);
        setFormErrors({});
    }

    function addBuilding() {
        if (!buildingName) {
            return;
        }
        else if (buildingNames.includes(buildingName)) {
            setFormErrors({ ...formErrors, 'building-name': 'Building name already entered' });
            return;
        }
        setBuildingNames([...buildingNames, buildingName]);
        setBuildingName('');
    }

    function removeBuilding(value) {
        const filtered = buildingNames.filter(x => x !== value);

        setBuildingNames(filtered);
    }

    function isFormValid() {
        const errors = {};

        errors['school-name'] = !schoolName ? 'Field is required' : null;
        errors['code'] = !code?.trim() ? 'Field is required' : null;
        if (school?.id)
            errors['status'] = !status?.trim() ? 'Field is required' : null;
        if (!errors['code'])
            errors['code'] = code.length > 6 ? 'Must be less than 6 characters' : null;

        if (!errors['code'])
            errors['code'] = code.length < 4 ? 'Must be more than 4 characters' : null;

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    }

    function validate(e) {
        setFormErrors(prev => ({
            ...prev,
            [e.target.id]: !e.target.value.trim() ? 'Field is required' : null
        }));
    }

    function handleClose() {
        clear();
        close();
    }
    const handleGradeLevelChange = (e, newValue) => {
        setGradeSliderStart(newValue[0]);
        setGradeSliderEnd(newValue[1]);
    }

    function submit() {
        if (!isFormValid())
            return;

        const dto = {
            schoolDistrictId: session.schoolDistrictId,
            name: schoolName,
            code,
            startGradeId: gradeLevels[gradeSliderStart].id,
            endGradeId: gradeLevels[gradeSliderEnd].id,
            systemStatusId: statuses.find(x => x.value === status)?.id,
            buildingNames
        }

        if (school?.id) {
            dto.id = school.id;
            dto.schoolDistrictId = school.schoolDistrictId;

            updateSchool(dto)
                .unwrap()
                .then((response) => {
                    if (response?.error)
                        throw new Error("An error occurred when updating school");

                    if (response?.codeError || response?.nameError) {
                        let errors = {
                            ...formErrors,
                            'code': response.codeError,
                            'school-name': response.nameError,
                        };
                        setFormErrors(errors);
                        return;
                    }
                    else if (response?.buildingError) {
                        let errors = {
                            ...formErrors,
                            'remove-building-name': response.buildingError,
                        };
                        setFormErrors(errors);
                        resetBuildings()
                        return;
                    }
                    toast.success('Successfully updated school');
                    clear();
                    close();
                })
                .catch(error => {
                    toast.error(error);
                    return;
                });
        } else {
            addSchool(dto)
                .unwrap()
                .then((response) => {
                    if (response?.error)
                        throw new Error("An error occurred when adding school");

                    if (response?.codeError || response?.nameError) {
                        let errors = {
                            ...formErrors,
                            'code': response.codeError,
                            'school-name': response.nameError
                        };
                        setFormErrors(errors);
                        return;
                    }
                    toast.success('Successfully added school');
                    clear();
                    close();
                })
                .catch(error => {
                    toast.error(error);
                    return;
                });
        }
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
                <Box>
                    {school === -1 ? "Add School" : school?.id ? "Edit School" : <Skeleton variant='text' width={100} sx={{ visibility: 'hidden' }} />}
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={() => { close(); clear(); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ paddingTop: '.3em !important', paddingBottom: 1 }}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        id="school-name"
                        size='small'
                        label="School Name"
                        variant="outlined"
                        type="text"
                        required
                        value={schoolName}
                        onBlur={validate}
                        error={!!formErrors['school-name']}
                        helperText={formErrors['school-name']}
                        onChange={e => setSchoolName(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <TextField
                            fullWidth
                            id="code"
                            size='small'
                            label="Code"
                            variant="outlined"
                            type="text"
                            required
                            value={code}
                            onBlur={validate}
                            error={!!formErrors['code']}
                            helperText={formErrors['code']}
                            onChange={e => setCode(e.target.value)}
                        />
                        <Autocomplete
                            fullWidth
                            id="status"
                            size='small'
                            options={statuses.map(x => x.value)}
                            value={status}
                            onChange={(_, v) => setStatus(v)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Status"
                                    required
                                    onBlur={validate}
                                    error={!!formErrors['status']}
                                    helperText={formErrors['status']}
                                />}
                        />
                    </Box>
                    <Box sx={{ px: 1 }}>
                        <Typography variant='caption'>
                            Grade Levels: &nbsp;&nbsp; {gradeLevels[gradeSliderStart ?? 0]?.value} - {gradeLevels[gradeSliderEnd ?? gradeLevels.length - 1]?.value}
                        </Typography>
                        <Slider
                            value={[gradeSliderStart, gradeSliderEnd]}
                            valueLabelDisplay="off"
                            marks={gradeLevels.map((x, i) => ({ value: i, label: x.value }))}
                            min={0}
                            step={1}
                            max={gradeLevels?.length - 1 || 0}
                            onChange={handleGradeLevelChange}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white'
                                },
                                '.MuiSlider-markLabel': {
                                    fontSize: '0.75rem',
                                }
                            }}
                            color='secondary'
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: '20px' }}>
                        <TextField
                            id='building-name'
                            label='Add Building'
                            variant='outlined'
                            size='small'
                            type='text'
                            value={buildingName}
                            error={!!formErrors['building-name']}
                            helperText={formErrors['building-name']}
                            onBlur={formErrors['building-name'] ? () => setFormErrors({ ...formErrors, 'building-name': null }) : null}
                            onChange={e => setBuildingName(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {addBuilding()}
                            }}
                        />
                        <Button
                            sx={{ paddingTop: '10px', marginLeft: '10px' }}
                            variant='text'
                            type='button'
                            startIcon={<Add />}
                            onClick={addBuilding}
                        >
                            Add Building
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px' }}>
                        <Box>Buildings:</Box>
                        {buildingNames.map(x => (
                            <Chip
                                label={x}
                                key={`building-${x}`}
                                type='chip'
                                onDelete={() => removeBuilding(x)}
                            />
                        ))}
                        <FormHelperText error>{formErrors['remove-building-name']}</FormHelperText>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActionButtons
                fullWidth
                closeText='Cancel'
                onClose={handleClose}
                submitText='Save'
                onSubmit={submit}
                isLoading={isUpdating || isAdding}
            />
        </Dialog >
    )
}

export default SchoolFormModal;