import { api } from "../api";

export const surveyQuestionTypesApi = api.injectEndpoints({
    tagTypes: ['SurveyQuestionTypes'],
    endpoints: builder => ({
        getSurveyQuestionTypes: builder.query({
            query: () => '/lookup/survey-question-types',
            providesTags: (result) =>
                result
                    ? result.map(({ id }) => ({ type: 'SurveyQuestionType', id }))
                    : ['SurveyQuestionType'],
        })
    })
})

export const {
    useGetSurveyQuestionTypesQuery
} = surveyQuestionTypesApi