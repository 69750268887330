import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Unauthorized() {
    return (
        <Box>
            <Stack spacing={1}>
                <Typography variant='h6'>Unauthorized</Typography>
                <Typography variant='body1'>You are not authorized to access this page.</Typography>
                <Link to='/'>Go to Home</Link>
            </Stack>
        </Box>
    );
}