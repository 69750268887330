import { Box, Tooltip, Typography, Autocomplete, TextField } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useGetSurveyQuestionScoringTypesQuery } from "../../../../app/slices/surveyQuestionScoringTypesSlice";

export default function QuestionScoringType({ scoringTypeId, handleScoringTypeChange }) {
    const { data: scoringTypes = [], isLoading: loadingScoringTypes } = useGetSurveyQuestionScoringTypesQuery();

    const selectedScoringType = scoringTypes.find((type) => type.id === scoringTypeId) || null;

    return (
        <Box sx={{ display: 'flex', gap: '10px' }}>
            <Autocomplete
                disableClearable
                options={scoringTypes}
                loading={loadingScoringTypes}
                value={selectedScoringType}
                onChange={(_e, newValue) => {
                    handleScoringTypeChange(newValue);
                }}
                getOptionLabel={option => (option ? option.value : '')}
                renderInput={params => (
                    <TextField
                        {...params}
                        label="Scoring Type"
                        size='small'
                        required
                    />
                )}
                sx={{ minWidth: 175 }}
            />
            <Tooltip
                title={
                    <Typography variant='caption'>
                        <p><strong>In-order </strong> scoring is applied to questions where higher scoring is preferred.</p>
                        <p><strong>Inverted </strong> scoring is applied when lower scoring is preferred (e.g., "Are you witnessing bullying?").</p>
                        <p><strong>Not-scored</strong> is applied to questions that will not be counted towards the aggregate survey score.</p>
                    </Typography>
                }
            >
                <Info sx={{ fontSize: '20px', alignSelf: 'center' }} />
            </Tooltip>
        </Box>
    );
}