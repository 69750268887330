import { configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import { unauthenticatedMiddleware } from "./middleware/unauthenticatedMiddleware";
import { logoutMiddleware } from "./middleware/logoutMiddleware";


export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([api.middleware, unauthenticatedMiddleware, logoutMiddleware]),
});